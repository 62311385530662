import { Box, TextField } from "@mui/material";
import "../Styles/loginStyle.css";
import demoImg from '../Assets/img.jpg'
import logoImg from '../Assets/mainlogo.png'
import 'primereact/resources/themes/lara-light-cyan/theme.css';
import { Button } from 'primereact/button';
import { Link } from "react-router-dom";



const Login = () => {
    
    return (
        <>
            <Box className="mainloginbox">
                <div className="imgdiv-box">
                    <img src={demoImg} alt="img" />
                </div>
                <div className="boxform">
                    <div className="logodiv">
                        <img src={logoImg} alt="img" />
                    </div>

                    <div className="ptag">
                        <p>Login</p>
                    </div>
                    <div className="inputuserdiv">
                        <TextField fullWidth placeholder="User ID" />
                    </div>

                    <div className="passinput">
                        <TextField fullWidth type="password" placeholder="Password" />
                    </div>
                    <div className="Forgotdiv">
                        <Link>Forgot Password?</Link>
                    </div>
                    <div className="loginbtn">
                        <Button label="Login" severity="info" />
                    </div>
                </div>
            </Box>
        </>
    );
}

export default Login;
