import { DataGrid } from '@mui/x-data-grid';
import Header from '../Common/Header/Header';
import { Box, Button, TextField } from '@mui/material';
import { MdAccountBalance, MdOutlineKeyboardArrowRight, MdClose } from "react-icons/md";
import '../Styles/masterDataEmployeesStyle.css'
import filterImg from '../Assets/filter.png'
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { useState } from 'react';
import { Tab, TabPanel, Tabs, TabsList } from "@mui/base";
import { MultiSelect } from 'primereact/multiselect';
import { Link } from 'react-router-dom';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import RecognitionEmployeeImportDataModel from '../Components/RecognitionEmployeeImportDataModel';
import MasterEmployeeDataModel from '../Components/MasterEmployeeDataModel';


const columns = [
    {
        field: 'id',
        headerName: 'Emp ID',
        width: 100
    },
    {
        field: 'name',
        headerName: 'Name',
        width: 100
    },
    {
        field: 'designation',
        headerName: 'Designation',
        width: 100,
    },

    {
        field: 'department',
        headerName: 'Department',
        width: 100,
    },
    {
        field: 'companyName',
        headerName: 'Company Name',
        width: 100
    },

    {
        field: 'status',
        headerName: 'Status',
        width: 100,
    },
    {
        field: 'image',
        headerName: 'Image',
        width: 100,
    },
    {
        field: 'mobileAppUser',
        headerName: 'Mobile App User',
        width: 100,
    },
    {
        field: 'actions',
        headerName: 'Actions',
        width: 100,
    }
];

const rows = [
    { id: 1, name: 'Snow ', updateOn: 'Jon' },
    { id: 2, name: 'Lannister', updateOn: 'Cersei' },
    { id: 3, name: 'Lannister', updateOn: 'Jaime' },
    { id: 4, name: 'Stark', updateOn: 'Arya' },
    { id: 5, name: 'Targaryen', updateOn: 'Daenerys' },
    { id: 6, name: 'Melisandre', updateOn: null },
    { id: 7, name: 'Clifford', updateOn: 'Ferrara' },
    { id: 8, name: 'Frances', updateOn: 'Rossini' },
    { id: 9, name: 'Roxie', updateOn: 'Harvey' },
];

const MasterDataEmployees = () => {
    const [openFilter, setOpenFilter] = useState(false);
    const [value, setValue] = useState(1);
    const [selectedCities, setSelectedCities] = useState(null);
    const [openOptions, setOpenOptions] = useState(false);
    const [open, setOpen] = useState(false);
    const [openData, setOpenData] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    }
    const DatahandleOpen = () => {
        setOpenData(true);
    }
    const DatahandleClose = () => {
        setOpenData(false);
    }
 
    const onOptionShow = () => {
        setOpenOptions(!openOptions)
    }

    const cities = [
        { name: 'New York', code: 'NY' },
        { name: 'Rome', code: 'RM' },
        { name: 'London', code: 'LDN' },
        { name: 'Istanbul', code: 'IST' },
        { name: 'Paris', code: 'PRS' }
    ];


    const onFilterClick = () => {
        setOpenFilter(!openFilter)
    }

    const onFilterHandleclose = () => {
        setOpenFilter(false)

    };
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleExportExcel = () => {
        // Convert table data to Excel format
        const worksheet = XLSX.utils.json_to_sheet(rows);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        // Generate buffer and create Blob
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Trigger download
        saveAs(blob, 'table_data.xlsx');
    };
    return (
        <>
            <Box className='Shifthome-mainbox'>
                <div className="Shift-rightbox">
                    <Header />
                    <Box className="Shift-second-head">
                        <div className='hamburg'>
                            <div><MdAccountBalance className=" fs-5" /></div>
                            <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                            <div>Master Data</div>
                            <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                            <div>Employees</div>
                        </div>
                        <div className='btnbox'>
                            <Link to={'/master-data-employees/addEmployee'}><Button>Add New</Button></Link>

                            <Button onClick={onOptionShow}>Options</Button>
                            <Box className={openOptions ? "visible-master" : "hidden-master"}>
                                <div onClick={handleOpen}>Import Data</div>
                                <div onClick={handleExportExcel}>Export Data</div>
                                <div onClick={DatahandleOpen}>Import salary Templates Data</div>
                                <div onClick={DatahandleOpen}>Import Account Details Data</div>
                                <div onClick={handleExportExcel}>Export Account Details Data</div>
                            </Box>
                            <RecognitionEmployeeImportDataModel open={open} handleClose={handleClose} />
                            <MasterEmployeeDataModel openData={openData} DatahandleClose={DatahandleClose} />
                        </div>
                    </Box>
                    <Box className='filterDiv'>
                        <div className='fielddate'>
                        </div>
                        <div className='searchbox'>
                            <div className='search-inputdiv'>
                                <Paper
                                    component="form"
                                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400, borderRadius: '20px' }}
                                >
                                    <InputBase
                                        size='small'
                                        sx={{ ml: 1, flex: 1 }}
                                        placeholder="Search by Emp ID / Name / Email / Phone No"
                                        inputProps={{ 'aria-label': 'Search by Emp ID / Name / Email / Phone No' }}
                                    />
                                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                        <SearchIcon />
                                    </IconButton>
                                </Paper>
                            </div>
                            <div className='img-div'>
                                <img onClick={onFilterClick} src={filterImg} alt="img" />

                                <Box className={openFilter ? "openfilterdiv" : "hiddenfilterdiv"}>
                                    <b onClick={onFilterHandleclose}><MdClose className=' fs-4' /></b>
                                    <p>Filters</p>
                                    <Box>
                                        <Tabs className='tabs-box' value={value} onChange={handleChange}>
                                            <div>
                                                <TabsList className="tabslistdiv">
                                                    <Tab className="mangetab" style={value === 0 ? { backgroundColor: '#629cdf', color: 'white', borderRadius: '0 30px 30px 0' } : {}} value={0}>Company </Tab>
                                                    <Tab className="mangetab" style={value === 1 ? { backgroundColor: '#629cdf', color: 'white', borderRadius: '0 30px 30px 0' } : {}} value={1}>Department </Tab>
                                                    <Tab className="mangetab" style={value === 2 ? { backgroundColor: '#629cdf', color: 'white', borderRadius: '0 30px 30px 0' } : {}} value={2}>Designation </Tab>
                                                    <Tab className="mangetab" style={value === 3 ? { backgroundColor: '#629cdf', color: 'white', borderRadius: '0 30px 30px 0' } : {}} value={3}>Status </Tab>
                                                    <Tab className="mangetab" style={value === 4 ? { backgroundColor: '#629cdf', color: 'white', borderRadius: '0 30px 30px 0' } : {}} value={4}>Images </Tab>
                                                    <Tab className="mangetab" style={value === 5 ? { backgroundColor: '#629cdf', color: 'white', borderRadius: '0 30px 30px 0' } : {}} value={5}>Tags </Tab>
                                                    <Tab className="mangetab" style={value === 6 ? { backgroundColor: '#629cdf', color: 'white', borderRadius: '0 30px 30px 0' } : {}} value={6}>Locations </Tab>
                                                    <Tab className="mangetab" style={value === 7 ? { backgroundColor: '#629cdf', color: 'white', borderRadius: '0 30px 30px 0' } : {}} value={7}>Mobile App User </Tab>
                                                    <Tab className="mangetab" style={value === 8 ? { backgroundColor: '#629cdf', color: 'white', borderRadius: '0 30px 30px 0' } : {}} value={8}>Mobile Policies </Tab>
                                                    <Tab className="mangetab" style={value === 9 ? { backgroundColor: '#629cdf', color: 'white', borderRadius: '0 30px 30px 0' } : {}} value={9}>Supervisor </Tab>
                                                    <Tab className="mangetab" style={value === 10 ? { backgroundColor: '#629cdf', color: 'white', borderRadius: '0 30px 30px 0' } : {}} value={10}>Shift Plans </Tab>
                                                </TabsList>
                                            </div>
                                            <div className='pnalbox'>
                                                <TabPanel value={0}>
                                                    <div className="tabpanel-filter">
                                                        <MultiSelect
                                                            value={selectedCities}
                                                            onChange={(e) => setSelectedCities(e.value)}
                                                            options={cities}
                                                            optionLabel="name"
                                                            filter placeholder="Company"
                                                            maxSelectedLabels={3}
                                                            className="cccccc" />
                                                    </div>

                                                </TabPanel>
                                                <TabPanel value={1}>
                                                    <div className="tabpanel-filter">
                                                        <MultiSelect
                                                            value={selectedCities}
                                                            onChange={(e) => setSelectedCities(e.value)}
                                                            options={cities}
                                                            optionLabel="name"
                                                            filter placeholder="Department"
                                                            maxSelectedLabels={3}
                                                            className="" />
                                                    </div>

                                                </TabPanel>
                                                <TabPanel value={2}>
                                                    <div className="tabpanel-filter">
                                                        <MultiSelect
                                                            value={selectedCities}
                                                            onChange={(e) => setSelectedCities(e.value)}
                                                            options={cities}
                                                            optionLabel="name"
                                                            filter placeholder="Designation"
                                                            maxSelectedLabels={3}
                                                            className="" />
                                                    </div>

                                                </TabPanel>
                                                <TabPanel value={3}>
                                                    <div className="tabpanel-filter">
                                                        <MultiSelect
                                                            value={selectedCities}
                                                            onChange={(e) => setSelectedCities(e.value)}
                                                            options={cities}
                                                            optionLabel="name"
                                                            filter placeholder="Status"
                                                            maxSelectedLabels={3}
                                                            className="" />
                                                    </div>

                                                </TabPanel>
                                                <TabPanel value={4}>
                                                    <div className="tabpanel-filter">
                                                        <MultiSelect
                                                            value={selectedCities}
                                                            onChange={(e) => setSelectedCities(e.value)}
                                                            options={cities}
                                                            optionLabel="name"
                                                            filter placeholder="Images"
                                                            maxSelectedLabels={3}
                                                            className="" />
                                                    </div>

                                                </TabPanel>
                                                <TabPanel value={5}>
                                                    <div className="tabpanel-filter">
                                                        <MultiSelect
                                                            value={selectedCities}
                                                            onChange={(e) => setSelectedCities(e.value)}
                                                            options={cities}
                                                            optionLabel="name"
                                                            filter placeholder="Tags"
                                                            maxSelectedLabels={3}
                                                            className="" />
                                                    </div>

                                                </TabPanel>
                                                <TabPanel value={6}>
                                                    <div className="tabpanel-filter">
                                                        <MultiSelect
                                                            value={selectedCities}
                                                            onChange={(e) => setSelectedCities(e.value)}
                                                            options={cities}
                                                            optionLabel="name"
                                                            filter placeholder="Locations"
                                                            maxSelectedLabels={3}
                                                            className="" />
                                                    </div>

                                                </TabPanel>
                                                <TabPanel value={7}>
                                                    <div className="tabpanel-filter">
                                                        <MultiSelect
                                                            value={selectedCities}
                                                            onChange={(e) => setSelectedCities(e.value)}
                                                            options={cities}
                                                            optionLabel="name"
                                                            filter placeholder="Mobile App User"
                                                            maxSelectedLabels={3}
                                                            className="" />
                                                    </div>

                                                </TabPanel>
                                                <TabPanel value={8}>
                                                    <div className="tabpanel-filter">
                                                        <MultiSelect
                                                            value={selectedCities}
                                                            onChange={(e) => setSelectedCities(e.value)}
                                                            options={cities}
                                                            optionLabel="name"
                                                            filter placeholder="Mobile Policies"
                                                            maxSelectedLabels={3}
                                                            className="" />
                                                    </div>

                                                </TabPanel>
                                                <TabPanel value={9}>
                                                    <div className="tabpanel-filter">
                                                        <MultiSelect
                                                            value={selectedCities}
                                                            onChange={(e) => setSelectedCities(e.value)}
                                                            options={cities}
                                                            optionLabel="name"
                                                            filter placeholder="Supervisor"
                                                            maxSelectedLabels={3}
                                                            className="" />
                                                    </div>

                                                </TabPanel>
                                                <TabPanel value={10}>
                                                    <div className="tabpanel-filter">
                                                        <MultiSelect
                                                            value={selectedCities}
                                                            onChange={(e) => setSelectedCities(e.value)}
                                                            options={cities}
                                                            optionLabel="name"
                                                            filter placeholder="Shift Plans"
                                                            maxSelectedLabels={3}
                                                            className="" />
                                                    </div>

                                                </TabPanel>
                                            </div>
                                        </Tabs>
                                    </Box>
                                    <div className='buttones'>
                                        <Button variant='outlined'>Clear All</Button>
                                        <Button variant='contained'>Apply</Button>
                                    </div>
                                </Box>
                            </div>
                        </div>
                    </Box>
                    <Box className="Shift-box">
                        <div style={{ width: '100%' }}>
                            <DataGrid
                                rows={rows}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: { page: 0, pageSize: 5 },
                                    },
                                }}
                                pageSizeOptions={[5, 10]}
                                checkboxSelection
                            />
                        </div>
                    </Box>
                </div>
            </Box >

        </>
    );
}

export default MasterDataEmployees;