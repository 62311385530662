import { Box } from "@mui/material"
import Header from "../Common/Header/Header";
import { MdAccountBalance, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { Button } from 'primereact/button';
import { Link } from "react-router-dom";
import '../Styles/settingAccountStyle.css'
import { useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { FaUpload } from "react-icons/fa";
import { InputText } from "primereact/inputtext";

const SettingAccount = () => {
    const [selectedCity, setSelectedCity] = useState(null);
    const [dateFormat, setDateFormat] = useState(null);
    const [timeFormat, setTimeFormat] = useState(null);
    const cities = [
        { name: 'New York', code: 'NY' },
        { name: 'Rome', code: 'RM' },
        { name: 'London', code: 'LDN' },
        { name: 'Istanbul', code: 'IST' },
        { name: 'Paris', code: 'PRS' }
    ];
    const typeDate = [
        { name: 'MM/DD/YYYY' },
        { name: 'DD/MM/YYYY' },
    ];
    const typeTime = [
        { name: '12 Hours' },
        { name: '24 Hours' },
    ];
    const openFileInput = () => {
        const fileInput = document.getElementById('fileInput');
        fileInput.click();
    };

    return (
        <>
            <Box className='home-mainbox'>
                <div className="rightbox">
                    <Header />
                    <Box className="createform-head">
                        <div className="createform">
                            <div><MdAccountBalance className=" fs-5" /></div>
                            <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                            <div>Settings </div>
                            <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                            <div>Account Settings </div>
                        </div>
                       
                    </Box>

                    <Box className="full-box">
                        <p className="heading-p">Account Settings</p>

                        <div className="selectionBox py-3 mb-2" style={{ borderBottom: '1px solid lightgrey' }}>

                            <div className="AccountInfo">
                                <p className="m-0">Account ID</p>
                                <p className="m-0 text-black fw-bold">2209100344565</p>
                            </div>
                            <div className="AccountInfo">
                                <p className="m-0">Account Plan</p>
                                <p className="m-0 text-black fw-bold">Advanced</p>
                            </div>
                            <div className="AccountInfo">
                                <p className="m-0">Name</p>
                                <p className="m-0 text-black fw-bold">Mohan Sharma</p>
                            </div>
                            <div className="AccountInfo">
                                <p className="m-0">Phone</p>
                                <p className="m-0 text-black fw-bold">+91 1234534890</p>
                            </div>
                            <div className="AccountInfo">
                                <p className="m-0">Email</p>
                                <p className="m-0 text-black fw-bold">mohansharma@kent.co.in</p>
                            </div>
                        </div>

                        <div className="selectionBox">

                            <div className="accountBox">
                                <p className="mb-4">Company Logo</p>
                                <div className="img-logoDiv" onClick={openFileInput} >
                                    <input
                                        type="file"
                                        id="fileInput"
                                        style={{ display: 'none' }}
                                    />
                                    <p className=" bg-primary-subtle p-2 rounded-0"><FaUpload className=" fs-4 text-black-50" /></p>
                                    <div>
                                        <p>Image dimension 80X80</p>
                                        <p>File size 2 MB</p>
                                    </div>
                                </div>
                            </div>

                            <div className="accountBox">
                                <p className="mb-4">Display Name</p>
                                <div className="addconfig-selectbox">
                                    <InputText /> 
                                </div>
                            </div>
                            <div className="accountBox">
                                <p className="mb-4">Default Location</p>
                                <div className="addconfig-selectbox">
                                    <Dropdown
                                        value={selectedCity}
                                        onChange={(e) => setSelectedCity(e.value)}
                                        options={cities}
                                        optionLabel="name"
                                        placeholder=""
                                        className="" />
                                </div>
                            </div>
                            <div className="accountBoxwidth">
                                <p className="mb-4">Date & Time Format</p>
                                <div className=" d-flex justify-content-around">
                                    <Dropdown
                                        value={dateFormat}
                                        onChange={(e) => setDateFormat(e.value)}
                                        options={typeDate}
                                        optionLabel="name"
                                        placeholder=""
                                        className="" />
                                         <Dropdown
                                        value={timeFormat}
                                        onChange={(e) => setTimeFormat(e.value)}
                                        options={typeTime}
                                        optionLabel="name"
                                        placeholder=""
                                        className="" />
                                </div>
                            </div>
                        </div>

                        <div className="buttons">
                            <Button className="button2" size="small" label="Cancel" outlined />
                            <Button className="button1" size="small" label="Save " severity="info" />
                        </div>

                    </Box>
                </div>
            </Box >

        </>
    )
}

export default SettingAccount

