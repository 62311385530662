import { Box } from "@mui/material"
import '../Styles/homeStyle.css'
import Header from "../Common/Header/Header";
import { CgFileDocument } from "react-icons/cg";
import { MdAccountBalance, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { PiPersonArmsSpreadFill } from "react-icons/pi";
import { FiCheckSquare } from "react-icons/fi";
import { BsFillFileBarGraphFill } from "react-icons/bs";
import { Link } from "react-router-dom";



const HomePage = () => {
    return (
        <>
            <Box className='home-mainbox'>
                <div className="rightbox">
                    <Header />
                    <Box className="second-head">
                        <div><MdAccountBalance className=" fs-5" /></div>
                        <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                        <div>Reports</div>
                    </Box>
                    <Box className="full-box">
                        <p className="heading-p">Reports</p>

                        <div className="reports">
                            <div>
                                <h6><PiPersonArmsSpreadFill className="iconcolor" /> Time Loss</h6>
                                <Link to={'/report-time-loss-summary'}> <p><CgFileDocument /> Time Loss Summary Report</p></Link>
                                <Link to={'/report-time-loss'}><p><CgFileDocument /> Time Loss Report</p></Link>

                                <h6><PiPersonArmsSpreadFill className="iconcolor" /> Recognitions</h6>
                                <Link to={'/report-recognition-logs'}><p><CgFileDocument /> Recognition Logs</p></Link>
                                <Link to={'/report-current-presence'}><p><CgFileDocument /> Current Presence Report</p></Link>

                                <h6><BsFillFileBarGraphFill className="iconcolor" /> Attendance</h6>
                                <Link to={'/report-muster-roll'}><p><CgFileDocument /> Muster Roll</p></Link>
                                <Link to={'/report-monthly-performance-register'}><p><CgFileDocument /> Monthly Performance Register</p></Link>
                                <Link to={'/report-attendance-details-bydate'}><p><CgFileDocument /> Attendance Details By Date</p></Link>
                                <Link to={'/report-attendance-details-byemployee'}><p><CgFileDocument /> Attendance Details By Employee</p></Link>
                                <Link to={'/report-presence-bydetection'}><p><CgFileDocument /> Presence By Detection</p></Link>
                                <Link to={'/report-attendance-bydesignation'}> <p><CgFileDocument /> Attendance By Designation</p></Link>
                                <Link to={'/report-attendance-bydepartment'}><p><CgFileDocument /> Attendance By Department</p></Link>
                                <Link to={'/report-multi-punches'}><p><CgFileDocument /> Multi Punches Report</p></Link>
                                <Link to={'/report-employee-wisedetail'}><p><CgFileDocument /> Employee Wise Detail Report</p></Link>
                                <Link to={'/report-spot'}><p><CgFileDocument /> SPOT Report</p></Link>
                                <Link to={'/report-shift'}><p><CgFileDocument /> Shift Report</p></Link>
                                <Link to={'/report-shorthours'}><p><CgFileDocument /> Short Hours Report</p></Link>
                                <Link to={'/report-monthly-attendance'}><p><CgFileDocument /> Monthly Attendance Report</p></Link>
                                <Link to={'/report-visitor-inout'}><p><CgFileDocument /> Visitor In/Out Report</p></Link>
                                <Link to={'/report-daily-performance'}><p><CgFileDocument /> Daily Performance Report</p></Link>
                                <Link to={'/report-monthly-performance-Wwithloss-hoursdetails'}><p><CgFileDocument /> Monthly Performance With Loss Hours Details</p></Link>

                            </div>
                            <div>

                                <h6><PiPersonArmsSpreadFill className="iconcolor" /> Statutory</h6>
                                <Link to={'/report-form-no26'}><p><CgFileDocument /> Form No.26</p></Link>
                                <Link to={'/report-form-no12'}><p><CgFileDocument /> Form No.12</p></Link>
                                <Link to={'/report-yearly-attendance-details'}><p><CgFileDocument /> Yearly Attendance Details Report</p></Link>
                                <Link to={'/report-form-no25'}><p><CgFileDocument /> Form No. 25</p></Link>
                                <Link to={'/report-yearly'}><p><CgFileDocument /> Yearly Report</p></Link>

                                <h6><FiCheckSquare className="iconcolor" /> Data Validation & Audit</h6>
                                <Link to={'/report-employee-missing-images'}><p><CgFileDocument /> Employee Missing Images</p></Link>
                                <Link to={'/report-unlinked-employees'}><p><CgFileDocument /> Unlinked Employees</p></Link>
                                <Link to={'/report-system-usage-logs'}><p><CgFileDocument /> System Usage Logs</p></Link>

                                <h6><PiPersonArmsSpreadFill className="iconcolor" /> Exceptions</h6>
                                <Link to={'/report-all-exceptions'}><p><CgFileDocument /> All Exceptions Report</p></Link>
                                <Link to={'/report-open-exceptions'}><p><CgFileDocument /> Open Exceptions Report</p></Link>
                                <Link to={'/report-open-regularization-requests'}><p><CgFileDocument /> Open Regularization Requests Report</p></Link>
                                <Link to={'/report-open-leave-requests'}><p><CgFileDocument /> Open Leave Requests Report</p></Link>
                                <Link to={'/report-muster-absentees'}><p><CgFileDocument /> Muster Absentees Report</p></Link>
                                <Link to={'/report-absentees'}><p><CgFileDocument /> Absentees Report</p></Link>
                                <Link to={'/report-early-departure'}><p><CgFileDocument /> Early Departure Report</p></Link>
                                <Link to={'/report-late-register'}><p><CgFileDocument /> Late Register Report</p></Link>
                                <Link to={'/report-continuous-early-departure'}><p><CgFileDocument /> Continuous Early Departure Report</p></Link>
                                <Link to={'/report-continuous-late-arrival'}><p><CgFileDocument /> Continuous Late Arrival Report</p></Link>
                                <Link to={'/report-continuous-absenteeism'}><p><CgFileDocument /> Continuous Absenteeism Report</p></Link>


                            </div>
                            <div>
                                <h6><PiPersonArmsSpreadFill className="iconcolor" /> Leaves</h6>
                                <Link to={'/report-leave-balance'}><p><CgFileDocument /> Leave Balance Report</p></Link>
                                <Link to={'/report-leave-register'}><p><CgFileDocument /> Leave Register Report</p></Link>
                                <Link to={'/report-leave-taken'}><p><CgFileDocument /> Leave Taken Report</p></Link>

                                <h6><PiPersonArmsSpreadFill className="iconcolor" /> Overtime</h6>
                                <Link to={'/report-overtime'}><p><CgFileDocument /> Overtime Report</p></Link>
                                <Link to={'/report-attendance-otstatus'}><p><CgFileDocument /> Attendance Report OT-Status</p></Link>

                                <h6><FiCheckSquare className="iconcolor" /> Payroll Reports</h6>
                                <Link to={'/report-salary-register'}><p><CgFileDocument /> Salary Register</p></Link>
                                <Link to={'/report-ctc'}><p><CgFileDocument /> CTC Report</p></Link>
                                <Link to={'/report-salary-history'}><p><CgFileDocument /> Salary History</p></Link>
                                <Link to={'/report-department-summary'}><p><CgFileDocument /> Department Summary</p></Link>
                                <Link to={'/report-deductions'}><p><CgFileDocument /> Deductions Report</p></Link>
                                <Link to={'/report-loan'}><p><CgFileDocument /> Loan Report</p></Link>
                                <Link to={'/report-pf-statement'}><p><CgFileDocument /> PF Statement</p></Link>
                                <Link to={'/report-pf-challan'}><p><CgFileDocument /> PF Challan</p></Link>
                                <Link to={'/report-pf-uploadformat'}><p><CgFileDocument /> PF Upload Format</p></Link>
                                <Link to={'/report-esi-statement'}><p><CgFileDocument /> ESI Statement</p></Link>
                                <Link to={'/report-esi-challan'}><p><CgFileDocument /> ESI Challan</p></Link>
                                <Link to={'/report-esi-uploadformat'}><p><CgFileDocument /> ESI Upload Format</p></Link>
                                <Link to={'/report-salary-transfer-statement'}><p><CgFileDocument /> Salary Transfer Statement</p></Link>
                                <Link to={'/report-new-joinees'}><p><CgFileDocument /> New Joinees Report</p></Link>
                                <Link to={'/report-resignees'}><p><CgFileDocument /> Resignees report</p></Link>

                            </div>
                        </div>

                    </Box>
                </div>
            </Box >

        </>
    )
}

export default HomePage

