import { Box } from "@mui/material"
import Header from "../Common/Header/Header";
import { MdOutlineKeyboardArrowRight, MdAccountBalance } from "react-icons/md";
import '../Styles/addNewRuleOvertimeRulesStyle.css'
import { InputText } from "primereact/inputtext";
import { Dropdown } from 'primereact/dropdown';
import { useState } from "react";
import { Button } from "primereact/button";
import { MultiSelect } from 'primereact/multiselect';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';


const AddNewRuleOvertimeRules = () => {
    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedCities, setSelectedCities] = useState(null);
    const [ingredient, setIngredient] = useState('');


    const cities = [
        { name: 'Employee ID', code: 'NY' },
        { name: 'Employee Name', code: 'RM' },
    ];
    const Allcities = [
        { name: 'Monday' },
        { name: 'Tuesday' },
        { name: 'Wednesday' },
        { name: 'Thrusday' },
        { name: 'Friday' },
    ];

    return (
        <>
            <Box className='home-mainbox'>
                <div className="rightbox">
                    <Header />
                    <Box className="second-head">
                        <div><MdAccountBalance className=" fs-5" /></div>
                        <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                        <div>Overtime </div>
                        <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                        <div>Rules</div>
                        <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                        <div>Add Rule</div>

                    </Box>

                    <Box className="full-box">
                        <p className="heading-p">Add New Rule</p>

                        <div className="selectionBox">

                            <div className="card ">
                                <InputText placeholder="Display Id" />
                            </div>
                            <div className="card ">
                                <InputText placeholder="Name" />
                            </div>
                            <div className="card">
                                <Dropdown
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    editable placeholder="Rule Type"
                                    className="" />
                            </div>
                            <div className="card">
                                <MultiSelect
                                    value={selectedCities}
                                    onChange={(e) => setSelectedCities(e.value)}
                                    options={Allcities}
                                    optionLabel="name"
                                    filter placeholder="On Days"
                                    maxSelectedLabels={3}
                                    className="" />
                            </div>

                            <div className="card">
                                <div className="CalculationTime">
                                    <label htmlFor="buttondisplay" className="font-bold block">
                                        OT Calculation Starts After
                                    </label>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer
                                            components={[
                                                'MobileTimePicker',
                                            ]}
                                        >
                                            <DemoItem>
                                                <MobileTimePicker defaultValue={dayjs('2022-04-17T15:30')} />
                                            </DemoItem>
                                        </DemoContainer>
                                    </LocalizationProvider>
                                    <label htmlFor="buttondisplay" className="font-bold block">
                                        Hours/Day
                                    </label>
                                </div>
                            </div>

                            <div className="card">
                                <Dropdown
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    editable placeholder="Overtime Calculation For"
                                    className="" />
                            </div>
                            <div className="card ">
                                <InputText placeholder="Shift Start Grace For OT" />
                            </div>
                            <div className="card ">
                                <InputText placeholder="Shift End Grace For OT" />
                            </div>
                            <div className="card">
                                <Dropdown
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    editable placeholder="Pay Rate Base"
                                    className="" />
                            </div>

                            <div className="card"></div>

                            <p className="heading-p">OT Round Off</p>

                            <div className="card"></div>



                            <div className="card ">
                                <InputText placeholder="Minimun OT" />
                            </div>
                            <div className="card">
                                <FormGroup>
                                    <FormControlLabel control={<Switch />} label="Min OT to be Included" />
                                </FormGroup>
                            </div>
                            <div className="card ">
                                <InputText placeholder="Min OT Round Off" />
                            </div>
                            <div className="card ">
                                <InputText placeholder="Max OT Round Off" />
                            </div>
                            <div className="card ">
                                <InputText placeholder="Maximum OT" />
                            </div>
                            <div className="card"></div>

                            <p className="heading-p">Eligibility</p>

                            <div className="card"></div>

                            <div className="card">
                                <Dropdown
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    placeholder="Access Level"
                                    className="" />
                            </div>
                            <div className="card">
                                <MultiSelect
                                    value={selectedCities}
                                    onChange={(e) => setSelectedCities(e.value)}
                                    options={Allcities}
                                    optionLabel="name"
                                    filter placeholder="Company"
                                    maxSelectedLabels={3}
                                    className="" />
                            </div>
                        </div>

                        <div className="buttons">
                            <Button className="button1" size="small" label="Create Rule" severity="info" />
                            <Button className="button2" size="small" label="Cancel" severity="info" outlined />
                        </div>

                    </Box>
                </div>
            </Box >

        </>
    )
}

export default AddNewRuleOvertimeRules

