import { Box } from "@mui/material"
import DashboardSidebar from "../../Components/Sidebar";
import '../../Styles/reportMusterRollStyle.css'
import Header from "../../Common/Header/Header";
import { MdAccountBalance, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { useState } from "react";
import 'primereact/resources/themes/lara-light-cyan/theme.css';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Calendar } from "primereact/calendar";



const ReportPFUploadFormat = () => {
    const [selectedCities, setSelectedCities] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [date, setDate] = useState(null);

    const cities = [
        { name: 'New York', code: 'NY' },
        { name: 'Rome', code: 'RM' },
        { name: 'London', code: 'LDN' },
        { name: 'Istanbul', code: 'IST' },
        { name: 'Paris', code: 'PRS' }
    ];

    const Allcities = [
        { name: 'Employee ID', code: 'NY' },
        { name: 'Employee Name', code: 'RM' },
    ];


    return (
        <>
            <Box className='home-mainbox'>
                {/* <div className='sidebarbox'>
                    <DashboardSidebar />
                </div> */}

                <div className="rightbox">
                    <Header />
                    <Box className="second-head">
                        <div><MdAccountBalance className=" fs-5" /></div>
                        <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                        <div>Reports</div>
                        <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                        <div>PF Upload Format</div>
                    </Box>

                    <Box className="full-box">
                        <p className="heading-p">PF Upload Format</p>

                        <div className="selectionBox">
                            <div className="card">
                                <Dropdown
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    editable placeholder="Company"
                                    className="" />
                            </div>
                            <div className="calenderbox">
                                <div className="flex-auto">
                                    <Calendar
                                        value={date}
                                        onChange={(e) => setDate(e.value)}
                                        view="month"
                                        editable placeholder="Month"
                                        dateFormat="mm"
                                    />
                                </div>
                                <div className="flex-auto">
                                    <Calendar
                                        value={date}
                                        onChange={(e) => setDate(e.value)}
                                        view="year"
                                        editable placeholder="year"
                                        dateFormat="yy" />
                                </div>

                            </div>
                        </div>

                        <div className="buttons">
                            <Button className="button1" size="small" label="Download Report" severity="info" />
                            <Button className="button2" size="small" label="Cancel" severity="info" outlined />
                        </div>

                    </Box>
                </div>
            </Box >

        </>
    )
}

export default ReportPFUploadFormat

