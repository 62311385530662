
import { Box, Button } from "@mui/material"
import Header from "../Common/Header/Header";
import { MdOutlineKeyboardArrowRight, MdAccountBalance } from "react-icons/md";
import '../Styles/settingAddUserStyle.css'
import { Dropdown } from 'primereact/dropdown';
import { useState } from "react";
import DeviceConfigurationsCopySettingModal from "../Components/DeviceConfigurationsCopySettingModal";


const DeviceConfigurations = () => {
    const [selectedCity, setSelectedCity] = useState(null);
    const [open, setOpen] = useState(false);
    

    const cities = [
        { name: 'Employee ID', code: 'NY' },
        { name: 'Employee Name', code: 'RM' },
    ];

    const handleOpen = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    }

    return (
        <>
            <Box className='home-mainbox'>
                <div className="rightbox">
                    <Header />
                    <Box className="second-head justify-content-between">
                        <div className=" d-flex align-items-center">
                            <div><MdAccountBalance className=" fs-5" /></div>
                            <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                            <div>Devices </div>
                            <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                            <div>Device Configurations</div>
                        </div>

                        <div className='btnbox'>
                            <Button onClick={handleOpen}>Copy Settings</Button>
                            <DeviceConfigurationsCopySettingModal open={open} handleClose={handleClose} />
                        </div>
                    </Box>

                    <Box className="full-box">
                        <div className="selectionBox">

                            <div className="card">
                                <Dropdown
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    editable placeholder="Location"
                                    className="" />
                            </div>
                            <div className="card">
                                <Dropdown
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    editable placeholder="Device"
                                    className="" />
                            </div>

                        </div>

                    </Box>
                </div>
            </Box >

        </>
    )
}

export default DeviceConfigurations

