import { Box } from "@mui/material"
import Header from "../Common/Header/Header";
import { MdOutlineKeyboardArrowRight, MdAccountBalance } from "react-icons/md";
import '../Styles/masterDataAddShiftStyle.css'
import { InputText } from "primereact/inputtext";
import { Dropdown } from 'primereact/dropdown';
import { useState } from "react";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { SelectButton } from 'primereact/selectbutton';
import { MultiSelect } from 'primereact/multiselect';
import { Calendar } from 'primereact/calendar';




const MasterDataAddShiftPlan = () => {
    const [selectedCity, setSelectedCity] = useState(null);
    const [ingredient, setIngredient] = useState('');
    const options = ['Continuous', 'In/Out'];
    const [switchBtn, setSwitchBtn] = useState(options[0]);
    const [date, setDate] = useState(null);


    const cities = [
        { name: 'Employee ID', code: 'NY' },
        { name: 'Employee Name', code: 'RM' },
    ];

    return (
        <>
            <Box className='home-mainbox'>
                <div className="rightbox">
                    <Header />
                    <Box className="second-head">
                        <div><MdAccountBalance className=" fs-5" /></div>
                        <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                        <div>Master Data </div>
                        <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                        <div>Shift Plans</div>
                        <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                        <div>Add Shift Plan</div>
                    </Box>

                    <Box className="full-box">
                        <p className="heading-p">Add Shift Plan</p>

                        <div className="selectionBox">

                            <div className="card">
                                <Dropdown
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    placeholder="Access Level"
                                    className="" />
                            </div>
                            <div className="card">
                                <Dropdown
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    placeholder="Access Type"
                                    className="" />
                            </div>
                            <div className="card ">
                                <InputText placeholder="Shift Plan ID" />
                            </div>
                            <div className="card ">
                                <InputText placeholder="Shift Plan Name" />
                            </div>
                            <div className="card">
                                <MultiSelect
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    filter placeholder="Select Shifts"
                                    maxSelectedLabels={3}
                                    className="" />
                            </div>
                            <div className="card">
                                <div className="DateDiv">
                                    <Calendar
                                        id="buttondisplay"
                                        value={date}
                                        onChange={(e) => setDate(e.value)}
                                        filter placeholder="Effective Date"
                                        showIcon />
                                </div>
                            </div>

                            <div className="card">
                                <Dropdown
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    placeholder="Repeat Every"
                                    className="" />
                            </div>
                            <div className="card">
                                <Dropdown
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    placeholder="Frequency"
                                    className="" />
                            </div>
                          
                            <div className="m-3">
                                <div className="d-flex flex-wrap gap-3 align-items-center">
                                    <label>Allow employees to choose one shift from multiple shifts in a day ? </label>
                                    <div className="d-flex align-items-center">
                                        <RadioButton
                                            inputId="ingredient1"
                                            name="pizza" value="Yes"
                                            onChange={(e) => setIngredient(e.value)}
                                            checked={ingredient === 'Yes'}
                                        />
                                        <label htmlFor="ingredient1" className="ms-2">Yes</label>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <RadioButton
                                            inputId="ingredient2"
                                            name="pizza" value="No"
                                            onChange={(e) => setIngredient(e.value)}
                                            checked={ingredient === 'No'}
                                        />
                                        <label htmlFor="ingredient2" className="ms-2">No</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="buttons">
                            <Button className="button1" size="small" label="Submit" severity="info" />
                            <Button className="button2" size="small" label="Cancel" severity="info" outlined />
                        </div>

                    </Box>
                </div>
            </Box >
        </>
    )
}

export default MasterDataAddShiftPlan

