
import { Box } from "@mui/material"
import Header from "../Common/Header/Header";
import { MdOutlineKeyboardArrowRight, MdAccountBalance } from "react-icons/md";
import '../Styles/masterDataAddVisitorsStyle.css'
import { InputText } from "primereact/inputtext";
import { Dropdown } from 'primereact/dropdown';
import { useState } from "react";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import imgLogo from '../Assets/profile.png'
import { IoAddCircle } from "react-icons/io5";
import { GoPersonFill } from "react-icons/go";
import { FaAddressCard } from "react-icons/fa";
import { InputTextarea } from "primereact/inputtextarea";
import { Calendar } from "primereact/calendar";
import { MultiSelect } from "primereact/multiselect";


const MasterDataAddVisitors = () => {
    const [selectedCity, setSelectedCity] = useState(null);
    const [ingredient, setIngredient] = useState('');
    const [value, setValue] = useState('');
    const [date, setDate] = useState(null);



    const cities = [
        { name: 'Employee ID', code: 'NY' },
        { name: 'Employee Name', code: 'RM' },
    ];

    const openFileInput = () => {
        const fileInput = document.getElementById('fileInput');
        fileInput.click();
    };
    return (
        <>
            <Box className='home-mainbox'>
                <div className="rightbox">
                    <Header />
                    <Box className="second-head">
                        <div><MdAccountBalance className=" fs-5" /></div>
                        <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                        <div>Master Data</div>
                        <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                        <div>Visitors</div>
                        <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                        <div>Add Visitor</div>

                    </Box>

                    <Box className="full-box">
                        <p className="heading-p">Add Visitor Details</p>

                        <div className="selectionBox">

                            <div className='card'>
                                <input
                                    type="file"
                                    id="fileInput"
                                    style={{ display: 'none' }}
                                />
                                <div className="VisitorInfo">
                                    <div className="Visitorphotoid" onClick={openFileInput} >
                                        <p className=""><GoPersonFill className=" fs-2" /></p>
                                        <p className="Visitor-p">Visitor Photo</p>
                                        <p className=""><IoAddCircle className=" fs-4" /></p>
                                    </div>
                                    <div className="Visitorphotoid" onClick={openFileInput} >
                                        <p className=""><FaAddressCard className=" fs-2" /></p>
                                        <p className="Visitor-p">Visitor ID</p>
                                        <p className=""><IoAddCircle className=" fs-4" /></p>
                                    </div>
                                </div>
                            </div>
                            <div className='card'></div>

                            <div className="card ">
                                <InputText placeholder="Name" />
                            </div>
                            <div className="card ">
                                <InputText placeholder="Email" />
                            </div>
                            <div className="card ">
                                <InputText placeholder="Phone Number" />
                            </div>
                            <div className="card ">
                                <InputText placeholder="Company" />
                            </div>
                            <div className="card ">
                                <InputText placeholder="Meeting Purpose" />
                            </div>

                            <div className="card">
                                <Dropdown
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    editable placeholder="Meeting With"
                                    className="" />
                            </div>
                            <div className="card">
                                <MultiSelect
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    filter placeholder="Devices"
                                    maxSelectedLabels={3}
                                    className="" />
                            </div>
                            <div className="card">
                                <div className="DateDiv">
                                    <Calendar
                                        id="buttondisplay"
                                        value={date}
                                        onChange={(e) => setDate(e.value)}
                                        filter placeholder="valid Till"
                                        showIcon />
                                </div>
                            </div>

                            <div className="card">
                                <InputTextarea
                                    // value={value}
                                    onChange={(e) => setValue(e.target.value)}
                                    filter placeholder="Remarks"
                                    rows={2} cols={30}
                                />
                            </div>

                        </div>

                        <div className="buttons">
                            <Button className="button1" size="small" label="Submit" severity="info" />
                            <Button className="button2" size="small" label="Cancel" severity="info" outlined />
                        </div>

                    </Box>
                </div>
            </Box >

        </>
    )
}

export default MasterDataAddVisitors

