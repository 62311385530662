import { Box } from "@mui/material"
import '../Styles/recognitionEmployeeAddManualStyle.css'
import Header from "../Common/Header/Header";
import { MdAccountBalance, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { useState } from "react";
import 'primereact/resources/themes/lara-light-cyan/theme.css';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { InputTextarea } from "primereact/inputtextarea";


const RecognitionsEmployeesAddManual = () => {
    const [selectedCities, setSelectedCities] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [date, setDate] = useState(null);
    const [value, setValue] = useState(new Date('2018-01-01T00:00:00.000Z'));

    const cities = [
        { name: 'New York', code: 'NY' },
        { name: 'Rome', code: 'RM' },
        { name: 'London', code: 'LDN' },
        { name: 'Istanbul', code: 'IST' },
        { name: 'Paris', code: 'PRS' }
    ];

    const Allcities = [
        { name: 'Employee ID', code: 'NY' },
        { name: 'Employee Name', code: 'RM' },
    ];


    return (
        <>
            <Box className='home-mainbox'>
                <div className="rightbox">
                    <Header />
                    <Box className="second-head">
                        <div><MdAccountBalance className=" fs-5" /></div>
                        <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                        <div>Recognition</div>
                        <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                        <div>Employees</div>
                        <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                        <div>Add Manual</div>
                    </Box>

                    <Box className="full-box">
                        <p className="heading-p">Add Manual Recognition</p>

                        <div className="selectionBox">
                            <div className="card">
                                <Dropdown
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    editable placeholder="Company"
                                    className="" />
                            </div>
                            <div className="card">
                                <Dropdown
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    editable placeholder="Employee ID"
                                    className="" />
                            </div>
                            <div className="card">
                                <Dropdown
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    editable placeholder="Device"
                                    className="" />
                            </div>


                            <div className="card">
                                <div className="cardflexauto">
                                    <Calendar
                                        id="buttondisplay"
                                        onChange={(e) => setDate(e.value)}
                                        filter placeholder="Recognition Date"
                                        showIcon />
                                </div>

                            </div>


                            <div className="card">
                                <div className="cardflexauto">
                                    <label htmlFor="buttondisplay" className="font-bold block">
                                        Recognition Time
                                    </label>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer
                                            components={[
                                                'MobileTimePicker',
                                            ]}
                                            
                                        >
                                            <DemoItem>
                                                <MobileTimePicker defaultValue={dayjs('2022-04-17T15:30')}/>
                                            </DemoItem>
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </div>
                            </div>
                            <div className="card"></div>
                            <div className="card">
                                <InputTextarea
                                    // value={value}
                                    onChange={(e) => setValue(e.target.value)}
                                    filter placeholder="Remarks"
                                    rows={5} cols={30}
                                />
                            </div>

                        </div>

                        <div className="buttons">
                            <Button className="button1" size="small" label="Download Report" severity="info" />
                            <Button className="button2" size="small" label="Cancel" severity="info" outlined />
                        </div>

                    </Box>
                </div>
            </Box >

        </>
    )
}

export default RecognitionsEmployeesAddManual

