
import { Box } from "@mui/material"
import '../Styles/reportMonthlyPerformanceRegister.css'
import Header from "../Common/Header/Header";
import { MdAccountBalance, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { useState } from "react";
import { MultiSelect } from 'primereact/multiselect';
import 'primereact/resources/themes/lara-light-cyan/theme.css';
import { Button } from 'primereact/button';
import { InputText } from "primereact/inputtext";



const MasterDataAddDepartment = () => {
    const [selectedCities, setSelectedCities] = useState(null);

    const Allcities = [
        { name: 'Employee ID', code: 'NY' },
        { name: 'Employee Name', code: 'RM' },
    ];


    return (
        <>
            <Box className='home-mainbox'>
                <div className="rightbox">
                    <Header />
                    <Box className="second-head">
                        <div><MdAccountBalance className=" fs-5" /></div>
                        <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                        <div>Master Data </div>
                        <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                        <div>Departments</div>
                        <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                        <div>Add Department</div>
                    </Box>

                    <Box className="full-box">
                        <p className="heading-p">Add Department</p>

                        <div className="selectionBox">
                            <div className="card">
                                <MultiSelect
                                    value={selectedCities}
                                    onChange={(e) => setSelectedCities(e.value)}
                                    options={Allcities}
                                    optionLabel="name"
                                    filter placeholder="Company"
                                    maxSelectedLabels={3}
                                    className="" />
                            </div>
                            <div className="card ">
                                <InputText placeholder="Department ID" />
                            </div>
                            <div className="card ">
                                <InputText placeholder="Department Name" />
                            </div>


                        </div>

                        <div className="buttons">
                            <Button className="button1" size="small" label="Submit" severity="info" />
                            <Button className="button2" size="small" label="Cancel" severity="info" outlined />
                        </div>

                    </Box>
                </div>
            </Box >

        </>
    )
}

export default MasterDataAddDepartment

