
import { Box } from "@mui/material"
import Header from "../Common/Header/Header";
import { MdOutlineKeyboardArrowRight, MdAccountBalance } from "react-icons/md";
import '../Styles/masterDataAddShiftStyle.css'
import { InputText } from "primereact/inputtext";
import { Dropdown } from 'primereact/dropdown';
import { useState } from "react";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { SelectButton } from 'primereact/selectbutton';


const MasterDataAddShift = () => {
    const [selectedCity, setSelectedCity] = useState(null);
    const [ingredient, setIngredient] = useState('');
    const options = ['Continuous', 'In/Out'];
    const [switchBtn, setSwitchBtn] = useState(options[0]);

    const cities = [
        { name: 'Employee ID', code: 'NY' },
        { name: 'Employee Name', code: 'RM' },
    ];

    return (
        <>
            <Box className='home-mainbox'>
                <div className="rightbox">
                    <Header />
                    <Box className="second-head">
                        <div><MdAccountBalance className=" fs-5" /></div>
                        <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                        <div>Master Data </div>
                        <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                        <div>Shifts</div>
                        <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                        <div>Add Shift</div>
                    </Box>

                    <Box className="full-box">
                        <p className="heading-p">Add Shift</p>

                        <div className="selectionBox">

                            <div className="card">
                                <Dropdown
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    placeholder="Access Level"
                                    className="" />
                            </div>
                            <div className="card "></div>
                            <div className="card ">
                                <InputText placeholder="Shift ID" />
                            </div>
                            <div className="card ">
                                <InputText placeholder="Shift Name" />
                            </div>
                          

                            <div className="card">
                                <div className="d-flex flex-wrap gap-3 align-items-center">
                                    <label>Shift Type : </label>
                                    <div className="d-flex align-items-center">
                                        <RadioButton
                                            inputId="ingredient1"
                                            name="pizza" value="Fixed"
                                            onChange={(e) => setIngredient(e.value)}
                                            checked={ingredient === 'Fixed'}
                                        />
                                        <label htmlFor="ingredient1" className="ms-2">Fixed</label>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <RadioButton
                                            inputId="ingredient2"
                                            name="pizza" value="Flexible"
                                            onChange={(e) => setIngredient(e.value)}
                                            checked={ingredient === 'Flexible'}
                                        />
                                        <label htmlFor="ingredient2" className="ms-2">Flexible</label>
                                    </div>
                                </div>
                            </div>
                            <div className="card"></div>
                            <div className="card">
                                <div className="cardflexauto">
                                    <label htmlFor="buttondisplay" className="font-bold block">
                                        Start Time
                                    </label>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer
                                            components={[
                                                'MobileTimePicker',
                                            ]}

                                        >
                                            <DemoItem>
                                                <MobileTimePicker defaultValue={dayjs('2022-04-17T15:30')} />
                                            </DemoItem>
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </div>
                            </div>
                            <div className="card">
                                <div className="cardflexauto">
                                    <label htmlFor="buttondisplay" className="font-bold block">
                                        End Time
                                    </label>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer
                                            components={[
                                                'MobileTimePicker',
                                            ]}

                                        >
                                            <DemoItem>
                                                <MobileTimePicker defaultValue={dayjs('2022-04-17T15:30')} />
                                            </DemoItem>
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </div>
                            </div>
                            <div className="card ">
                                <InputText placeholder="Duration" />
                            </div>
                            <div className="card "></div>

                            <div className="card">
                                <div className="d-flex flex-wrap gap-3 align-items-center">
                                    <label>Grace Period : </label>
                                    <div className="d-flex align-items-center">
                                        <RadioButton
                                            inputId="ingredient1"
                                            name="pizza" value="Yes"
                                            onChange={(e) => setIngredient(e.value)}
                                            checked={ingredient === 'Yes'}
                                        />
                                        <label htmlFor="ingredient1" className="ms-2">Yes</label>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <RadioButton
                                            inputId="ingredient2"
                                            name="pizza" value="No"
                                            onChange={(e) => setIngredient(e.value)}
                                            checked={ingredient === 'No'}
                                        />
                                        <label htmlFor="ingredient2" className="ms-2">No</label>
                                    </div>
                                </div>
                            </div>
                            <div className="card "></div>


                            <div className="card">
                                <div className="cardflexauto">
                                    <label htmlFor="buttondisplay" className="font-bold block">
                                        Minimum Hour For Full Day
                                    </label>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer
                                            components={[
                                                'MobileTimePicker',
                                            ]}

                                        >
                                            <DemoItem>
                                                <MobileTimePicker defaultValue={dayjs('2022-04-17T15:30')} />
                                            </DemoItem>
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </div>
                            </div>
                            <div className="card">
                                <div className="cardflexauto">
                                    <label htmlFor="buttondisplay" className="font-bold block">
                                        Minimum Hour For Half Day
                                    </label>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer
                                            components={[
                                                'MobileTimePicker',
                                            ]}

                                        >
                                            <DemoItem>
                                                <MobileTimePicker defaultValue={dayjs('2022-04-17T15:30')} />
                                            </DemoItem>
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </div>
                            </div>
                            <div className="switchcard">
                                <p className="mx-3">Continuous or Alternate In/Out :</p>
                                <SelectButton
                                    value={switchBtn}
                                    onChange={(e) => setSwitchBtn(e.value)}
                                    options={options}
                                />
                            </div>

                        </div>

                        <div className="buttons">
                            <Button className="button1" size="small" label="Submit" severity="info" />
                            <Button className="button2" size="small" label="Cancel" severity="info" outlined />
                        </div>

                    </Box>
                </div>
            </Box >
        </>
    )
}

export default MasterDataAddShift

