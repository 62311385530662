import { Box } from "@mui/material"
import Header from "../Common/Header/Header";
import { MdOutlineKeyboardArrowRight, MdAccountBalance } from "react-icons/md";
import '../Styles/masterDataAddLeaveTypeStyle.css'
import { InputText } from "primereact/inputtext";
import { Dropdown } from 'primereact/dropdown';
import { useState } from "react";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { SelectButton } from 'primereact/selectbutton';
import { Calendar } from "primereact/calendar";
import { MultiSelect } from "primereact/multiselect";


const MasterDataAddLeaveType = () => {
    const [selectedCity, setSelectedCity] = useState(null);
    const [ingredient, setIngredient] = useState('');
    const options = ['Continuous', 'In/Out'];
    const [switchBtn, setSwitchBtn] = useState(options[0]);
    const [date, setDate] = useState(null);


    const cities = [
        { name: 'Employee ID', code: 'NY' },
        { name: 'Employee Name', code: 'RM' },
    ];

    return (
        <>
            <Box className='home-mainbox'>
                <div className="rightbox">
                    <Header />
                    <Box className="second-head">
                        <div><MdAccountBalance className=" fs-5" /></div>
                        <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                        <div>Leaves </div>
                        <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                        <div>Leave Types</div>
                        <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                        <div>Add Leave Type</div>
                    </Box>

                    <Box className="full-box">
                        <p className="heading-p">Add Leave Type</p>

                        <div className="selectionBox">
                            <div className="card ">
                                <InputText placeholder="Abbreviation" />
                            </div>
                            <div className="card ">
                                <InputText placeholder="Leave Type Name" />
                            </div>

                            <div className="card">
                                <Dropdown
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    placeholder="Round Type"
                                    className="" />
                            </div>
                            <div className="card">
                                <Dropdown
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    placeholder="Minimum Unit For Availment"
                                    className="" />
                            </div>
                            <div className="card">
                                <div className="DateDiv">
                                    <Calendar
                                        id="buttondisplay"
                                        value={date}
                                        onChange={(e) => setDate(e.value)}
                                        filter placeholder="Effective Form"
                                        showIcon />
                                </div>
                            </div>
                            <div className="card">
                                <Dropdown
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    placeholder="Minimum Unit For Credit"
                                    className="" />
                            </div>
                            <div className="card">
                                <MultiSelect
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    filter placeholder="Unclub Leave Type"
                                    maxSelectedLabels={3}
                                    className="" />
                            </div>
                            <div className="card"></div>

                            <div className="cardleavebox">
                                <div className="d-flex flex-wrap gap-3 align-items-center">
                                    <label>Restrict Availment During Notice Period ? </label>
                                    <div className="d-flex align-items-center">
                                        <RadioButton
                                            inputId="ingredient1"
                                            name="pizza" value="Yes"
                                            onChange={(e) => setIngredient(e.value)}
                                            checked={ingredient === 'Yes'}
                                        />
                                        <label htmlFor="ingredient1" className="ms-2">Yes</label>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <RadioButton
                                            inputId="ingredient2"
                                            name="pizza" value="No"
                                            onChange={(e) => setIngredient(e.value)}
                                            checked={ingredient === 'No'}
                                        />
                                        <label htmlFor="ingredient2" className="ms-2">No</label>
                                    </div>
                                </div>
                            </div>
                            <div className="cardleavebox">
                                <div className="d-flex flex-wrap gap-3 align-items-center">
                                    <label>Leave Type Consider as Present(Paid) Days ? </label>
                                    <div className="d-flex align-items-center">
                                        <RadioButton
                                            inputId="ingredient1"
                                            name="pizza" value="Yes"
                                            onChange={(e) => setIngredient(e.value)}
                                            checked={ingredient === 'Yes'}
                                        />
                                        <label htmlFor="ingredient1" className="ms-2">Yes</label>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <RadioButton
                                            inputId="ingredient2"
                                            name="pizza" value="No"
                                            onChange={(e) => setIngredient(e.value)}
                                            checked={ingredient === 'No'}
                                        />
                                        <label htmlFor="ingredient2" className="ms-2">No</label>
                                    </div>
                                </div>
                            </div>
                            <p className="heading-p">Leave Policy Rule</p>
                            <div className="card"></div>
                            <div className="card ">
                                <InputText placeholder="Policy ID" />
                            </div>
                            <div className="card"></div>
                            <p className="heading-p">Eligibility</p>
                            <div className="card"></div>
                            <div className="card">
                                <MultiSelect
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    filter placeholder="Location"
                                    maxSelectedLabels={3}
                                    className="" />
                            </div>
                            <div className="card">
                                <MultiSelect
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    filter placeholder="Company"
                                    maxSelectedLabels={3}
                                    className="" />
                            </div>
                            <div className="card">
                                <MultiSelect
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    filter placeholder="Department"
                                    maxSelectedLabels={3}
                                    className="" />
                            </div>
                            <div className="card">
                                <MultiSelect
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    filter placeholder="Designation"
                                    maxSelectedLabels={3}
                                    className="" />
                            </div>
                            <div className="card">
                                <MultiSelect
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    filter placeholder="Gender"
                                    maxSelectedLabels={3}
                                    className="" />
                            </div>
                            <div className="card">
                                <MultiSelect
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    filter placeholder="Tags"
                                    maxSelectedLabels={3}
                                    className="" />
                            </div>

                            <p className="heading-p">Leave Lifecycle</p>
                            <div className="card"></div>



                            <div className=" d-flex align-items-center m-3">
                                <p className="me-3">Entitled After : </p>
                                <InputText placeholder="Entitled After" />
                                <Dropdown
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    placeholder="Period"
                                    className="" />
                                <div className="card">
                                    <Dropdown
                                        value={selectedCity}
                                        onChange={(e) => setSelectedCity(e.value)}
                                        options={cities}
                                        optionLabel="name"
                                        placeholder="From"
                                        className="" />
                                </div>
                            </div>

                            <div className=" d-flex align-items-center m-3">
                                <div className=" d-flex align-items-center">
                                    <p className="me-3">Accrues On : </p>
                                    <Dropdown
                                        value={selectedCity}
                                        onChange={(e) => setSelectedCity(e.value)}
                                        options={cities}
                                        optionLabel="name"
                                        placeholder="Period"
                                        className="" />
                                </div>
                                <div className=" d-flex align-items-center">
                                    <p className="mx-3"> On </p>
                                    <Dropdown
                                        value={selectedCity}
                                        onChange={(e) => setSelectedCity(e.value)}
                                        options={cities}
                                        optionLabel="name"
                                        placeholder="Month"
                                        className="" />
                                    <Dropdown
                                        value={selectedCity}
                                        onChange={(e) => setSelectedCity(e.value)}
                                        options={cities}
                                        optionLabel="name"
                                        placeholder="Day"
                                        className="" />
                                </div>
                                <div className=" d-flex align-items-center">
                                    <p className="mx-3"> In </p>
                                    <Dropdown
                                        value={selectedCity}
                                        onChange={(e) => setSelectedCity(e.value)}
                                        options={cities}
                                        optionLabel="name"
                                        placeholder="Leave Credit Basis"
                                        className="" />
                                    <Dropdown
                                        value={selectedCity}
                                        onChange={(e) => setSelectedCity(e.value)}
                                        options={cities}
                                        optionLabel="name"
                                        placeholder="Accrual Period"
                                        className="" />
                                </div>
                            </div>



                            <div className="cardleavebox">
                                <div className="d-flex flex-wrap gap-3 align-items-center">
                                    <label>Prorata Accrual : </label>
                                    <div className="d-flex align-items-center">
                                        <RadioButton
                                            inputId="ingredient1"
                                            name="pizza" value="Yes"
                                            onChange={(e) => setIngredient(e.value)}
                                            checked={ingredient === 'Yes'}
                                        />
                                        <label htmlFor="ingredient1" className="ms-2">Yes</label>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <RadioButton
                                            inputId="ingredient2"
                                            name="pizza" value="No"
                                            onChange={(e) => setIngredient(e.value)}
                                            checked={ingredient === 'No'}
                                        />
                                        <label htmlFor="ingredient2" className="ms-2">No</label>
                                    </div>
                                </div>
                            </div>


                            <div className="cardleavebox">
                                <div className="d-flex flex-wrap gap-3 align-items-center">
                                    <label>Expiry : </label>
                                    <div className="d-flex align-items-center">
                                        <RadioButton
                                            inputId="ingredient1"
                                            name="pizza" value="Yes"
                                            onChange={(e) => setIngredient(e.value)}
                                            checked={ingredient === 'Yes'}
                                        />
                                        <label htmlFor="ingredient1" className="ms-2">Yes</label>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <RadioButton
                                            inputId="ingredient2"
                                            name="pizza" value="No"
                                            onChange={(e) => setIngredient(e.value)}
                                            checked={ingredient === 'No'}
                                        />
                                        <label htmlFor="ingredient2" className="ms-2">No</label>
                                    </div>
                                </div>
                            </div>                        

                            <p className="heading-p">Leave Availment</p>
                            <div className="card"></div>

                            <div className="cardleavebox">
                                <p className="me-3">Availment Condition : </p>
                                <Dropdown
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    placeholder=""
                                    className="" />
                            </div>

                            <div className="cardleavebox">
                                <div className="d-flex flex-wrap gap-3 align-items-center">
                                    <label>Allow Negative Balance : </label>
                                    <div className="d-flex align-items-center">
                                        <RadioButton
                                            inputId="ingredient1"
                                            name="pizza" value="Yes"
                                            onChange={(e) => setIngredient(e.value)}
                                            checked={ingredient === 'Yes'}
                                        />
                                        <label htmlFor="ingredient1" className="ms-2">Yes</label>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <RadioButton
                                            inputId="ingredient2"
                                            name="pizza" value="No"
                                            onChange={(e) => setIngredient(e.value)}
                                            checked={ingredient === 'No'}
                                        />
                                        <label htmlFor="ingredient2" className="ms-2">No</label>
                                    </div>
                                </div>
                            </div>
                            <div className="cardleavebox">
                                <p className="me-3">Max Consecutive Leaves </p>
                                <p className="mx-3">Max Leaves in a period </p>
                                <Dropdown
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    placeholder=""
                                    className="" />
                            </div>
                            <div className="cardleavebox">
                                <div className="d-flex flex-wrap gap-3 align-items-center">
                                    <label>Can apply leaves for any future dates ? : </label>
                                    <div className="d-flex align-items-center">
                                        <RadioButton
                                            inputId="ingredient1"
                                            name="pizza" value="Yes"
                                            onChange={(e) => setIngredient(e.value)}
                                            checked={ingredient === 'Yes'}
                                        />
                                        <label htmlFor="ingredient1" className="ms-2">Yes</label>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <RadioButton
                                            inputId="ingredient2"
                                            name="pizza" value="No"
                                            onChange={(e) => setIngredient(e.value)}
                                            checked={ingredient === 'No'}
                                        />
                                        <label htmlFor="ingredient2" className="ms-2">No</label>
                                    </div>
                                </div>
                            </div>
                            <div className="cardleavebox">
                                <div className="d-flex flex-wrap gap-3 align-items-center">
                                    <label>Apply leaves in advance is mandatory ? </label>
                                    <div className="d-flex align-items-center">
                                        <RadioButton
                                            inputId="ingredient1"
                                            name="pizza" value="Yes"
                                            onChange={(e) => setIngredient(e.value)}
                                            checked={ingredient === 'Yes'}
                                        />
                                        <label htmlFor="ingredient1" className="ms-2">Yes</label>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <RadioButton
                                            inputId="ingredient2"
                                            name="pizza" value="No"
                                            onChange={(e) => setIngredient(e.value)}
                                            checked={ingredient === 'No'}
                                        />
                                        <label htmlFor="ingredient2" className="ms-2">No</label>
                                    </div>
                                </div>
                            </div>
                            <div className="cardleavebox">
                                <div className="d-flex flex-wrap gap-3 align-items-center">
                                    <label>Can apply leaves for backdates ? </label>
                                    <div className="d-flex align-items-center">
                                        <RadioButton
                                            inputId="ingredient1"
                                            name="pizza" value="Yes"
                                            onChange={(e) => setIngredient(e.value)}
                                            checked={ingredient === 'Yes'}
                                        />
                                        <label htmlFor="ingredient1" className="ms-2">Yes</label>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <RadioButton
                                            inputId="ingredient2"
                                            name="pizza" value="No"
                                            onChange={(e) => setIngredient(e.value)}
                                            checked={ingredient === 'No'}
                                        />
                                        <label htmlFor="ingredient2" className="ms-2">No</label>
                                    </div>
                                </div>
                            </div>
                            <p className="heading-p">Encashment Rule</p>
                            <div className="card"></div>
                            <div className="cardleavebox">
                                <div className="d-flex flex-wrap gap-3 align-items-center">
                                    <label>Allow encashment </label>
                                    <div className="d-flex align-items-center">
                                        <RadioButton
                                            inputId="ingredient1"
                                            name="pizza" value="Yes"
                                            onChange={(e) => setIngredient(e.value)}
                                            checked={ingredient === 'Yes'}
                                        />
                                        <label htmlFor="ingredient1" className="ms-2">Yes</label>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <RadioButton
                                            inputId="ingredient2"
                                            name="pizza" value="No"
                                            onChange={(e) => setIngredient(e.value)}
                                            checked={ingredient === 'No'}
                                        />
                                        <label htmlFor="ingredient2" className="ms-2">No</label>
                                    </div>
                                </div>
                            </div>


                        </div>

                        <div className="buttons">
                            <Button className="button1" size="small" label="Submit" severity="info" />
                            <Button className="button1" size="small" label="Save & Add New Policy" severity="info" />
                            <Button className="button2" size="small" label="Cancel" severity="info" outlined />
                        </div>

                    </Box>
                </div>
            </Box >
        </>
    )
}

export default MasterDataAddLeaveType

