
import { DataGrid } from '@mui/x-data-grid';
import Header from '../Common/Header/Header';
import { Box, Button } from '@mui/material';
import { MdAccountBalance, MdOutlineKeyboardArrowRight } from "react-icons/md";
import '../Styles/journeyPlansStyle.css'
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { Link } from 'react-router-dom';

const columns = [
    {
        field: 'Name',
        headerName: 'Name',
        width: 150
    },
    {
        field: 'mappedEmployees',
        headerName: 'Mapped Employees',
        width: 150,
    },
    {
        field: 'createdOn',
        headerName: 'Created On',
        width: 150
    },
    {
        field: 'updatedOn',
        headerName: 'Updated On',
        width: 150
    },
    {
        field: 'status',
        headerName: 'Status',
        width: 150,
    }, {
        field: 'action',
        headerName: 'Action',
        width: 150,
    }
];

const rows = [
    { id: 1, name: 'Snow ', updateOn: 'Jon' },
    { id: 2, name: 'Lannister', updateOn: 'Cersei' },
    { id: 3, name: 'Lannister', updateOn: 'Jaime' },
    { id: 4, name: 'Stark', updateOn: 'Arya' },
    { id: 5, name: 'Targaryen', updateOn: 'Daenerys' },
    { id: 6, name: 'Melisandre', updateOn: null },
    { id: 7, name: 'Clifford', updateOn: 'Ferrara' },
    { id: 8, name: 'Frances', updateOn: 'Rossini' },
    { id: 9, name: 'Roxie', updateOn: 'Harvey' },
];

const PayrollSalaryTemplates = () => {
    return (
        <>
            <Box className='Shifthome-mainbox'>
                <div className="Shift-rightbox">
                    <Header />
                    <Box className="Shift-second-head">
                        <div className='hamburg'>
                            <div><MdAccountBalance className=" fs-5" /></div>
                            <div><MdOutlineKeyboardArrowRight className=" fs-5"/></div>
                            <div>Payroll</div>
                            <div><MdOutlineKeyboardArrowRight className=" fs-5"/></div>
                            <div>Salary Templates</div>
                        </div>
                        <div className='btnbox'>
                        <Link to={'/payroll-salary-templates/addnew'}><Button className='btn'>Add New </Button></Link>
                        </div>
                    </Box>
                    <Box className='filterDiv'>
                        <div className='fielddate'>
                        </div>
                        <div className='searchbox'>
                            <div className='search-inputdiv'>
                                <Paper
                                    component="form"
                                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400, borderRadius: '20px' }}
                                >
                                    <InputBase
                                        size='small'
                                        sx={{ ml: 1, flex: 1 }}
                                        placeholder="Search by Template Name"
                                        inputProps={{ 'aria-label': 'Search by Template Name' }}
                                    />
                                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                        <SearchIcon />
                                    </IconButton>
                                </Paper>
                            </div>
                        </div>

                    </Box>
                    <Box className="Shift-box">
                        <div style={{ width: '100%' }}>
                            <DataGrid
                                rows={rows}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: { page: 0, pageSize: 5 },
                                    },
                                }}
                                pageSizeOptions={[5, 10]}
                                checkboxSelection
                            />
                        </div>

                    </Box>
                </div>
            </Box >

        </>
    );
}

export default PayrollSalaryTemplates;