

import { Box } from "@mui/material"
import Header from "../Common/Header/Header";
import { MdAccountBalance, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { Button } from 'primereact/button';
import { Link } from "react-router-dom";
import '../Styles/payrollCreateSalaryTemplatesStyle.css'
import { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";



const PayrollCreateSalaryTemplates = () => {
    const [selectedCity, setSelectedCity] = useState(null);
    const cities = [
        { name: 'New York', code: 'NY' },
        { name: 'Rome', code: 'RM' },
        { name: 'London', code: 'LDN' },
        { name: 'Istanbul', code: 'IST' },
        { name: 'Paris', code: 'PRS' }
    ];


    return (
        <>
            <Box className='home-mainbox'>
                <div className="rightbox">
                    <Header />
                    <Box className="createform-head">
                        <div className="createform">
                            <div><MdAccountBalance className=" fs-5" /></div>
                            <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                            <div>Payroll </div>
                            <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                            <div>Salary Templates </div>
                            <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                            <div>Add New </div>
                        </div>
                        <div className='Formsbtnbox'>
                            <Link to={'/payroll-configuration'}> <Button size="small">Back</Button></Link>
                        </div>
                    </Box>

                    <Box className="full-box">
                        <p className="heading-p">Create Salary Template</p>
                        <div className="selectionBox">
                            <div className="card ">
                                <InputText placeholder="Name" />
                            </div>
                            <div className="card ">
                                <InputText placeholder="Display ID" />
                            </div>
                            <div className="card">
                                <Dropdown
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    editable placeholder="Salary Structure Name"
                                    className="" />
                            </div>

                        </div>
                        <div className="tabletypediv">
                            <div>Salary Components</div>
                            <div>Type</div>
                            <div>Percentage or Fixed</div>
                            <div>Percentage or Amount</div>
                            <div>Taxable</div>

                        </div>
                        <p className="heading-p">Eligibility</p>

                        <div className="selectionBox">
                            <div className="card">
                                <MultiSelect
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    filter placeholder="Location"
                                    maxSelectedLabels={3}
                                    className="" />
                            </div>
                            <div className="card">
                                <MultiSelect
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    filter placeholder="Company"
                                    maxSelectedLabels={3}
                                    className="" />
                            </div>

                            <div className="card">
                                <MultiSelect
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    filter placeholder="Department"
                                    maxSelectedLabels={3}
                                    className="" />
                            </div>
                            <div className="card">
                                <MultiSelect
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    filter placeholder="Designation"
                                    maxSelectedLabels={3}
                                    className="" />
                            </div>

                        </div>

                        <div className="buttons">
                            <Button className="button2" size="small" label="Cancel" outlined />
                            <Button className="button1" size="small" label="Submit " severity="info" />
                        </div>

                    </Box>
                </div>
            </Box >

        </>
    )
}

export default PayrollCreateSalaryTemplates

