import { Box } from "@mui/material"
import Header from "../Common/Header/Header";
import { MdOutlineKeyboardArrowRight, MdAccountBalance } from "react-icons/md";
import '../Styles/globalDeviceSettingStyle.css'
import { useState } from "react";
import { SelectButton } from "primereact/selectbutton";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';

const GlobalDeviceSettings = () => {
    const options = ['Continuous', 'In/Out'];
    const [switchBtn, setSwitchBtn] = useState(options[0]);

    const Separator = styled('div')(
        ({ theme }) => `
        height: ${theme.spacing(3)};
      `,
      );
      
      const marks = [
        {
          value: 0,
          label: '10 minute',
        },
        {
          value: 50,
          label: '20 minute',
        },
        {
          value: 100,
          label: '30 minute',
        }
      ];
      
      function valuetext(value) {
        return `${value}`;
      }

      const DaySeparator = styled('div')(
        ({ theme }) => `
        height: ${theme.spacing(3)};
      `,
      );
      
      const Daymarks = [
        {
          value: 0,
          label: '10 minute',
        },
        {
          value: 50,
          label: '20 minute',
        },
        {
          value: 100,
          label: '30 minute',
        }
      ];
      
      function Dayvaluetext(value) {
        return `${value}`;
      }

    return (
        <>
            <Box className='home-mainbox'>
                <div className="rightbox">
                    <Header />
                    <Box className="second-head justify-content-between">
                        <div className=" d-flex align-items-center">
                            <div><MdAccountBalance className=" fs-5" /></div>
                            <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                            <div>Devices </div>
                            <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                            <div>Global Device Settings</div>
                        </div>
                    </Box>

                    <Box className="full-box">
                        <p className="heading-p">Global Device Settings</p>

                        <div className="selectionBox">
                            <div className="cardmode">
                                <div className="mode">
                                    <p className="p1">Mode</p>
                                    <p className="p2">Continuous or Alternate In/Out</p>
                                </div>
                                <div>
                                    <SelectButton
                                        value={switchBtn}
                                        onChange={(e) => setSwitchBtn(e.value)}
                                        options={options}
                                    />
                                </div>
                            </div>
                            <div className="cardmode">
                                <div className="">
                                    Mask Mandatory
                                </div>
                                <div>
                                    <FormGroup>
                                        <FormControlLabel control={<Switch />} label="" />
                                    </FormGroup>
                                </div>
                            </div>
                            <div className="cardmode d-block">
                                <Typography id="track-false-slider" gutterBottom>
                                Master Data Sync Interval
                                </Typography>
                                <Slider
                                    track={false}
                                    aria-labelledby="track-false-slider"
                                    getAriaValueText={valuetext}
                                    defaultValue={50}
                                    step={50}
                                    marks={marks}
                                />
                                <Separator />
                            </div>
                            <div className="cardmode d-block">
                                <Typography id="track-false-slider" gutterBottom>
                                Data Retention On Device
                                </Typography>
                                <Slider
                                    track={false}
                                    aria-labelledby="track-false-slider"
                                    getAriaValueText={Dayvaluetext}
                                    defaultValue={0}
                                    step={50}
                                    marks={Daymarks}
                                />
                                <Separator />
                            </div>

                        </div>

                    </Box>
                </div>
            </Box >

        </>
    )
}

export default GlobalDeviceSettings

