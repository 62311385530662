
import { DataGrid } from '@mui/x-data-grid';
import Header from '../Common/Header/Header';
import { Box, Button, TextField } from '@mui/material';
import { MdAccountBalance, MdOutlineKeyboardArrowRight,MdClose } from "react-icons/md";
import '../Styles/RecognitionsUnknowns.css'
import filterImg from '../Assets/filter.png'
import { useState } from 'react';
import { Tab, TabPanel, Tabs, TabsList } from "@mui/base";
import { MultiSelect } from 'primereact/multiselect';
import { saveAs } from 'file-saver';


const columns = [
    {
        field: 'image',
        headerName: 'Image',
        width: 120
    },
    {
        field: 'device',
        headerName: 'Device',
        width: 120
    },
    {
        field: 'location',
        headerName: 'Location',
        width: 120
    },
    {
        field: 'type',
        headerName: 'Type',
        width: 120,
    },
    {
        field: 'time',
        headerName: 'Time',
        width: 120,
    },
    {
        field: 'date',
        headerName: 'Date',
        width: 120,
    },
    {
        field: 'actions',
        headerName: 'Actions',
        width: 120,
    }
];

const rows = [
    { id: 1, name: 'Snow ', updateOn: 'Jon' },
    { id: 2, name: 'Lannister', updateOn: 'Cersei' },
    { id: 3, name: 'Lannister', updateOn: 'Jaime' },
    { id: 4, name: 'Stark', updateOn: 'Arya' },
    { id: 5, name: 'Targaryen', updateOn: 'Daenerys' },
    { id: 6, name: 'Melisandre', updateOn: null },
    { id: 7, name: 'Clifford', updateOn: 'Ferrara' },
    { id: 8, name: 'Frances', updateOn: 'Rossini' },
    { id: 9, name: 'Roxie', updateOn: 'Harvey' },
];

const RecognitionsUnknowns = () => {
    const [openFilter, setOpenFilter] = useState(false);
    const [value, setValue] = useState(1);
    const [selectedCities, setSelectedCities] = useState(null);

    const onFilterClick = () => {
        setOpenFilter(!openFilter)
    }

    const onFilterHandleclose = () => {
        setOpenFilter(false)

    };
    const cities = [
        { name: 'New York', code: 'NY' },
        { name: 'Rome', code: 'RM' },
        { name: 'London', code: 'LDN' },
        { name: 'Istanbul', code: 'IST' },
        { name: 'Paris', code: 'PRS' }
    ];

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

     // Function to handle export
     const handleExportCSV = () => {
        // Convert table data to CSV format
        const csvContent = [
            columns.map(column => column.headerName).join(','), // Header row
            ...rows.map(row => columns.map(column => row[column.field]).join(',')) // Data rows
        ].join('\n');

        // Create a Blob containing the CSV data
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

        // Trigger download
        saveAs(blob, 'table_data.csv');
    };

    return (
        <>
            <Box className='Shifthome-mainbox'>

                <div className="Shift-rightbox">
                    <Header />
                    <Box className="Shift-second-head">
                        <div className='hamburg'>
                            <div><MdAccountBalance className=" fs-5" /></div>
                            <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                            <div>Recognitions</div>
                            <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                            <div>Unknowns</div>
                        </div>
                        <div className='btnbox'>
                            <Button onClick={handleExportCSV}>Export CSV</Button>
                        </div>
                    </Box>
                    <Box className='filterDiv'>
                        <div className='fielddate'>
                            <div>
                                <TextField type='date' size='small' />
                            </div>
                        </div>
                        <div className='searchbox'>
                            <div className='img-div'>
                                <img onClick={onFilterClick} src={filterImg} alt="img" />
                                <Box className={openFilter ? "openfilterdiv" : "hiddenfilterdiv"}>
                                    <b onClick={onFilterHandleclose}><MdClose className=' fs-4' /></b>
                                    <p>Filters</p>
                                    <Box>
                                        <Tabs className='tabs-box' value={value} onChange={handleChange}>
                                            <div>
                                                <TabsList className="tabslistdiv">
                                                    <Tab className="mangetab" style={value === 0 ? { backgroundColor: '#629cdf', color: 'white', borderRadius: '0 30px 30px 0' } : {}} value={0}>Device </Tab>
                                                    <Tab className="mangetab" style={value === 1 ? { backgroundColor: '#629cdf', color: 'white', borderRadius: '0 30px 30px 0' } : {}} value={1}>Locations </Tab>
                                                </TabsList>
                                            </div>
                                            <div className='pnalbox'>
                                                <TabPanel value={0}>
                                                    <div className="tabpanel-filter">
                                                        <MultiSelect
                                                            value={selectedCities}
                                                            onChange={(e) => setSelectedCities(e.value)}
                                                            options={cities}
                                                            optionLabel="name"
                                                            filter placeholder="Device"
                                                            maxSelectedLabels={3}
                                                            className="cccccc" />
                                                    </div>

                                                </TabPanel>
                                                <TabPanel value={1}>
                                                    <div className="tabpanel-filter">
                                                        <MultiSelect
                                                            value={selectedCities}
                                                            onChange={(e) => setSelectedCities(e.value)}
                                                            options={cities}
                                                            optionLabel="name"
                                                            filter placeholder="Locations"
                                                            maxSelectedLabels={3}
                                                            className="" />
                                                    </div>

                                                </TabPanel>

                                            </div>
                                        </Tabs>
                                    </Box>
                                    <div className='buttones'>
                                        <Button variant='outlined'>Clear All</Button>
                                        <Button variant='contained'>Apply</Button>
                                    </div>
                                </Box>
                            </div>
                        </div>
                    </Box>
                    <Box className="Shift-box">
                        <div style={{ width: '100%' }}>
                            <DataGrid
                                rows={rows}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: { page: 0, pageSize: 5 },
                                    },
                                }}
                                pageSizeOptions={[5, 10]}
                                checkboxSelection
                            />
                        </div>

                    </Box>
                </div>
            </Box >

        </>
    );
}

export default RecognitionsUnknowns;