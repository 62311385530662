import { Box } from "@mui/material"
import Header from "../Common/Header/Header";
import { MdOutlineKeyboardArrowRight, MdAccountBalance } from "react-icons/md";
import '../Styles/cloudAPIIntegrationStyle.css'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useState } from "react";
import { MdOutlineContentCopy } from "react-icons/md";
import { InputText } from "primereact/inputtext";


const CloudAPIIntegration = () => {
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        setCopied(true);
    };
    
    return (
        <>
            <Box className='home-mainbox'>
                <div className="rightbox">
                    <Header />
                    <Box className="second-head">
                        <div><MdAccountBalance className=" fs-5" /></div>
                        <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                        <div>Cloud Integration </div>
                        <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                        <div>API Integration</div>
                    </Box>

                    <Box className="full-box">
                        <div className="selectionBox d-block p-4">
                            <div className="">
                                <p className="m-0">Account ID</p>
                                <p className="m-0 text-black fw-bold">2209100344565</p>
                            </div>
                            <div className="inputdivcopy">
                                <p>Key</p>
                                <div className="inputarea">
                                    <InputText
                                        value={'Ix7jKksKGsW4zAK'}
                                        disabled
                                        placeholder="Ix7jKksKGsW4zAK"
                                    />

                                    <CopyToClipboard
                                        text={'Ix7jKksKGsW4zAK'}
                                        onCopy={handleCopy}
                                    >
                                        <button><MdOutlineContentCopy className="iconbutn" /></button>
                                    </CopyToClipboard>
                                </div>
                            </div>

                            <p className="my-5">Generate token with Account ID, key and current UTC using above code.</p>
                        </div>
                    </Box>
                </div>
            </Box >

        </>
    )
}

export default CloudAPIIntegration

