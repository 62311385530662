
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
// import { Button } from '@mui/material';
import { Button } from 'primereact/button';
import './addTaskPlanModelStyle.css'
import { MdClose } from "react-icons/md";
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { useState } from 'react';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { InputTextarea } from 'primereact/inputtextarea';

const AddTaskPlanModel = ({ open, handleClose }) => {

    const [selectedCity, setSelectedCity] = useState(null);
    const [date, setDate] = useState(null);
    const [value, setValue] = useState('');

    const cities = [
        { name: 'New York', code: 'NY' },
        { name: 'Rome', code: 'RM' },
        { name: 'London', code: 'LDN' },
        { name: 'Istanbul', code: 'IST' },
        { name: 'Paris', code: 'PRS' }
    ];

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className='addmodalboxx'>
                    <b onClick={handleClose}><MdClose className='fs-4' /></b>
                    <Box className="addplanfullbox">
                        <p className="Task-p">Add Task</p>
                        <div className="CreateJourneyDiv">
                            <div>
                                <div className="Employeeid">
                                    <Dropdown
                                        value={selectedCity}
                                        onChange={(e) => setSelectedCity(e.value)}
                                        options={cities}
                                        optionLabel="name"
                                        editable placeholder="Check-in Location"
                                        className="" />
                                </div>

                                <div className="">
                                    <div className="DateDiv">
                                        <Calendar
                                            id="buttondisplay"
                                            onChange={(e) => setDate(e.value)}
                                            filter placeholder="Date"
                                            showIcon />
                                    </div>
                                </div>
                                <div className="AddressDiv">
                                    <p>Address</p>
                                </div>

                                <div className="calenderbox">
                                    <div className="flex-auto">
                                        <label htmlFor="buttondisplay" className="font-bold block">
                                            Start Time
                                        </label>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer
                                                components={[
                                                    'MobileTimePicker',
                                                ]}
                                            >
                                                <DemoItem>
                                                    <MobileTimePicker defaultValue={dayjs('2022-04-17T15:30')} />
                                                </DemoItem>
                                            </DemoContainer>
                                        </LocalizationProvider>

                                    </div>
                                    <div className="flex-auto">
                                        <label htmlFor="buttondisplay" className="font-bold block">
                                            End Time
                                        </label>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer
                                                components={[
                                                    'MobileTimePicker',
                                                ]}
                                            >
                                                <DemoItem>
                                                    <MobileTimePicker
                                                        defaultValue={dayjs('2022-04-17T15:30')} />
                                                </DemoItem>
                                            </DemoContainer>
                                        </LocalizationProvider>

                                    </div>

                                </div>

                                <div className="card">
                                    <InputTextarea
                                        value={value}
                                        onChange={(e) => setValue(e.target.value)}
                                        filter placeholder="Remarks"
                                        rows={3} cols={30}
                                    />
                                </div>


                                <div className="butns">
                                    <Button className="buttn1" size="small" label="Submit" severity="info" />
                                    <Button className="buttn2" size="small" label="Cancel" />
                                </div>
                            </div>
                            <div>
                                <div>
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d112059.37471475975!2d77.1698044!3d28.6340938!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1711042999153!5m2!1sen!2sin"
                                        width="400px" height="460"
                                        style={{ border: 'none', borderRadius: '6px' }}
                                        // allowfullscreen=""
                                        loading="lazy"
                                        referrerpolicy="no-referrer-when-downgrade"
                                    >
                                    </iframe>
                                </div>
                            </div>
                        </div>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}
export default AddTaskPlanModel