import { Box } from "@mui/material"
import '../Styles/createNewTaskJourneyTaskStyle.css'
import Header from "../Common/Header/Header";
import { MdAccountBalance, MdOutlineKeyboardArrowRight, MdAdd } from "react-icons/md";
import { useState } from "react";
import 'primereact/resources/themes/lara-light-cyan/theme.css';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { InputTextarea } from 'primereact/inputtextarea';
import { FaArrowLeftLong } from "react-icons/fa6";
import { Link } from "react-router-dom";


const columns = [
    {
        field: 'locationCategory',
        headerName: 'Location Category',
        width: 120,
    },
    {
        field: 'startTime',
        headerName: 'Start Time',
        width: 120
    }, {
        field: 'endTime',
        headerName: 'End Time',
        width: 120
    },
    {
        field: 'address',
        headerName: 'Address',
        width: 120,
    },
    {
        field: 'remarks',
        headerName: 'Remarks',
        width: 120
    },
    {
        field: 'action',
        headerName: 'Action',
        width: 120,
    }
];

const rows = [
    { id: 1, name: 'Snow ', updateOn: 'Jon' },
    { id: 2, name: 'Lannister', updateOn: 'Cersei' },
    { id: 3, name: 'Lannister', updateOn: 'Jaime' },
    { id: 4, name: 'Stark', updateOn: 'Arya' },
    { id: 5, name: 'Targaryen', updateOn: 'Daenerys' },
    { id: 6, name: 'Melisandre', updateOn: null },
    { id: 7, name: 'Clifford', updateOn: 'Ferrara' },
    { id: 8, name: 'Frances', updateOn: 'Rossini' },
    { id: 9, name: 'Roxie', updateOn: 'Harvey' },
];

const CreateNewTaskJourneyTask = () => {
    const [selectedCities, setSelectedCities] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [date, setDate] = useState(null);
    const [value, setValue] = useState(new Date('2018-01-01T00:00:00.000Z'));
    const [val, setVal] = useState('');

    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    }

    const cities = [
        { name: 'New York', code: 'NY' },
        { name: 'Rome', code: 'RM' },
        { name: 'London', code: 'LDN' },
        { name: 'Istanbul', code: 'IST' },
        { name: 'Paris', code: 'PRS' }
    ];

    const Allcities = [
        { name: 'Employee ID', code: 'NY' },
        { name: 'Employee Name', code: 'RM' },
    ];


    return (
        <>
            <Box className='home-mainbox'>
                <div className="rightbox">
                    <Header />
                    <Box className="second-head">
                        <div><MdAccountBalance className=" fs-5" /></div>
                        <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                        <div>Journey Task List</div>
                        <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                        <div>Create New Task</div>
                    </Box>

                    <Box className="full-box">
                        <div className="backdiv">
                            <Link to={'/journey-tasks'}><p className="icon-p"><FaArrowLeftLong/></p></Link>
                            <p className="heading-p">Add Task</p>
                        </div>

                        <div className="CreateJourneyDiv">
                            <div>
                                <div className="Employeeid">
                                    <Dropdown
                                        value={selectedCity}
                                        onChange={(e) => setSelectedCity(e.value)}
                                        options={cities}
                                        optionLabel="name"
                                        editable placeholder="Employee ID"
                                        className="" />
                                </div>

                                <div className="">
                                    <div className="DateDiv">
                                        <Calendar
                                            id="buttondisplay"
                                            onChange={(e) => setDate(e.value)}
                                            filter placeholder="Date"
                                            showIcon />
                                    </div>
                                </div>

                                <div className="Employeeid">
                                    <Dropdown
                                        value={selectedCity}
                                        onChange={(e) => setSelectedCity(e.value)}
                                        options={cities}
                                        optionLabel="name"
                                        editable placeholder="Check-in Location"
                                        className="" />
                                </div>
                                <div className="AddressDiv">
                                    <p>Address</p>
                                </div>

                                <div className="calenderbox">
                                    <div className="flex-auto">
                                        <label htmlFor="buttondisplay" className="font-bold block">
                                            Start Time
                                        </label>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer
                                                components={[
                                                    'MobileTimePicker',
                                                ]}
                                            >
                                                <DemoItem>
                                                    <MobileTimePicker defaultValue={dayjs('2022-04-17T15:30')} />
                                                </DemoItem>
                                            </DemoContainer>
                                        </LocalizationProvider>

                                    </div>
                                    <div className="flex-auto">
                                        <label htmlFor="buttondisplay" className="font-bold block">
                                            End Time
                                        </label>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer
                                                components={[
                                                    'MobileTimePicker',
                                                ]}
                                            >
                                                <DemoItem>
                                                    <MobileTimePicker
                                                        defaultValue={dayjs('2022-04-17T15:30')} />
                                                </DemoItem>
                                            </DemoContainer>
                                        </LocalizationProvider>

                                    </div>

                                </div>

                                <div className="card">
                                    <InputTextarea
                                        value={val}
                                        onChange={(e) => setVal(e.target.value)}
                                        filter placeholder="Remarks"
                                        rows={3} cols={30}
                                    />
                                </div>


                                <div className="butns">
                                    <Button className="buttn1" size="small" label="Submit" severity="info" />
                                    <Button className="buttn2" size="small" label="Cancel" />
                                </div>
                            </div>
                            <div>
                                <div>
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d112059.37471475975!2d77.1698044!3d28.6340938!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1711042999153!5m2!1sen!2sin"
                                        width="400px" height="460"
                                        style={{ border: 'none', borderRadius: '6px' }}
                                        // allowfullscreen=""
                                        loading="lazy"
                                        referrerpolicy="no-referrer-when-downgrade"
                                    >
                                    </iframe>
                                </div>
                            </div>
                        </div>



                    </Box>
                </div>
            </Box >

        </>
    )
}

export default CreateNewTaskJourneyTask

