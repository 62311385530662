import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./Page/Home";
import ShiftSchedule from "./Page/ShiftSchedule";
import RecognitionsEmployees from "./Page/RecognitionsEmployees";
import RecognitionsUnknowns from "./Page/RecognitionsUnknowns";
import RecognitionsVisitors from "./Page/RecognitionsVisitors";
import RecognitionsEntryLogs from "./Page/RecognitionsEntryLogs";
import JourneyPlans from "./Page/JourneyPlans";
import JourneyTasks from "./Page/JourneyTasks";
import ExceptionsList from "./Page/ExceptionsList";
import ExceptionsRegularizationsRequests from "./Page/ExceptionsRegularizationsRequests";
import ExceptionsAuditsLogs from "./Page/ExceptionsAuditsLogs";
import LeaveTransactions from "./Page/LeaveTransactions";
import LeaveRequests from "./Page/LeaveRequests";
import LeaveEncashment from "./Page/LeaveEncashment";
import HomeprofileProfile from "./Page/HomeprofileProfile";
import DeviceEmployeeRelationship from "./Page/DeviceEmployeeRelationship";
import MasterDataEmployees from "./Page/MasterDataEmployees";
import MasterDataRecognitionsForms from "./Page/MasterDataRecognitionsForms";
import MasterDataVisitors from "./Page/MasterDataVisitors";
import PayrollSalaryComponents from "./Page/PayrollSalaryComponents";
import PayrollSalaryStructures from "./Page/PayrollSalaryStructures";
import PayrollSalaryTemplates from "./Page/PayrollSalaryTemplates";
import PayrollSalaryBonusTemplates from "./Page/PayrollSalaryBonusTemplates";
import PayrollConfiguration from "./Page/PayrollConfiguration";
import OvertimeRules from "./Page/OvertimeRules";
import PayrollLoans from "./Page/PayrollLoans";
import PayrollRun from "./Page/PayrollRun";
import PayrollHistory from "./Page/PayrollHistory";
import SettingsUsers from "./Page/SettingsUsers";
import HelpReportAnIssue from "./Page/HelpReportAnIssue";
import ReportMusterRoll from "./Page/Reports/ReportMusterRoll";
import ReportMonthlyPerformanceRegister from "./Page/Reports/ReportMonthlyPerformanceRegister";
import Login from "./Page/Login";
import ReportAttendanceDetailsByDate from "./Page/Reports/ReportAttendanceDetailsByDate";
import ReportAttendanceDetailsByEmployee from "./Page/Reports/ReportAttendanceDetailsByEmployee";
import ReportPresenceByDetection from "./Page/Reports/ReportPresenceByDetection";
import ReportAttendanceByDesignation from "./Page/Reports/ReportAttendanceByDesignation";
import ReportAttendanceByDepartment from "./Page/Reports/ReportAttendanceByDepartment";
import ReportMultiPunches from "./Page/Reports/ReportMultiPunches";
import ReportEmployeeWiseDetail from "./Page/Reports/ReportEmployeeWiseDetail";
import ReportSpot from "./Page/Reports/ReportSpot";
import ReportShift from "./Page/Reports/ReportShift";
import ReportMonthlyPerformanceWithLossHoursDetails from "./Page/Reports/ReportMonthlyPerformanceWithLossHoursDetails";
import ReportShortHours from "./Page/Reports/ReportShortHours";
import ReportMonthlyAttendance from "./Page/Reports/ReportMonthlyAttendance";
import ReportVisitorInOut from "./Page/Reports/ReportVisitorInOut";
import ReportDailyPerformance from "./Page/Reports/ReportDailyPerformance";
import ReportTimeLossSummary from "./Page/Reports/ReportTimeLossSummary";
import ReportTimeLoss from "./Page/Reports/ReportTimeLoss";
import ReportRecognitionLogs from "./Page/Reports/ReportRecognitionLogs";
import ReportCurrentPresence from "./Page/Reports/ReportCurrentPresence";
import ReportFormNo26 from "./Page/Reports/ReportFormNo26";
import ReportFormNo12 from "./Page/Reports/ReportFormNo12";
import ReportFormNo25 from "./Page/Reports/ReportFormNo25";
import ReportYearlyAttendanceDetails from "./Page/Reports/ReportYearlyAttendanceDetails";
import ReportYearly from "./Page/Reports/ReportYearly";
import ReportEmployeeMissingImages from "./Page/Reports/ReportEmployeeMissingImages";
import ReportUnlinkedEmployees from "./Page/Reports/ReportUnlinkedEmployees";
import ReportSystemUsageLogs from "./Page/Reports/ReportSystemUsageLogs";
import ReportAllExceptions from "./Page/Reports/ReportAllExceptions";
import ReportOpenExceptions from "./Page/Reports/ReportOpenExceptions";
import ReportOpenRegularizationRequests from "./Page/Reports/ReportOpenRegularizationRequests";
import ReportOpenLeaveRequests from "./Page/Reports/ReportOpenLeaveRequests";
import ReportMusterAbsentees from "./Page/Reports/ReportMusterAbsentees";
import ReportAbsentees from "./Page/Reports/ReportAbsentees";
import ReportEarlyDeparture from "./Page/Reports/ReportEarlyDeparture";
import ReportLateRegister from "./Page/Reports/ReportLateRegister";
import ReportContinuousEarlyDeparture from "./Page/Reports/ReportContinuousEarlyDeparture";
import ReportContinuousLateArrival from "./Page/Reports/ReportContinuousLateArrival";
import ReportContinuousAbsenteeism from "./Page/Reports/ReportContinuousAbsenteeism";
import ChangePassword from "./Page/ChangePassword";
import ReportLeaveBalance from "./Page/Reports/ReportLeaveBalance";
import ReportLeaveRegister from "./Page/Reports/ReportLeaveRegister";
import ReportLeaveTaken from "./Page/Reports/ReportLeaveTaken";
import Dashboard from "./Page/Dashboard";
import ReportOverTime from "./Page/Reports/ReportOverTime";
import ReportAttendanceOTStatus from "./Page/Reports/ReportAttendanceOTStatus";
import ReportSalaryRegister from "./Page/Reports/ReportSalaryRegister";
import ReportCTC from "./Page/Reports/ReportCTC";
import ReportSalaryHistory from "./Page/Reports/ReportSalaryHistory";
import ReportDepartmentSummary from "./Page/Reports/ReportDepartmentSummary";
import ReportDeductions from "./Page/Reports/ReportDeductions";
import ReportLoan from "./Page/Reports/ReportLoan";
import ReportPFStatement from "./Page/Reports/ReportPFStatement";
import ReportPFChallan from "./Page/Reports/ReportPFChallan";
import ReportPFUploadFormat from "./Page/Reports/ReportPFUploadFormat";
import ReportESIStatement from "./Page/Reports/ReportESIStatement";
import ReportESIChallan from "./Page/Reports/ReportESIChallan";
import ReportESIUploadFormat from "./Page/Reports/ReportESIUploadFormat";
import ReportSalaryTransferStatement from "./Page/Reports/ReportSalaryTransferStatement";
import ReportNewJoinees from "./Page/Reports/ReportNewJoinees";
import ReportResignees from "./Page/Reports/ReportResignees";
import Layout from "./Components/Layout";
import NotFound from "./Page/NotFound";
import MasterDataHolidayCalendar from "./Page/MasterDataHolidayCalendar";
import RecognitionsEmployeesAddManual from "./Page/RecognitionsEmployeesAddManual";
import CreateJourneyPlan from "./Page/CreateJourneyPlan";
import CreateNewTaskJourneyTask from "./Page/CreateNewTaskJourneyTask";
import LeaveRequestsApplyLeave from "./Page/LeaveRequestsApplyLeave";
import SettingAddUser from "./Page/SettingAddUser";
import PayrollApplyForLoan from "./Page/PayrollApplyForLoan";
import AddNewRuleOvertimeRules from "./Page/AddNewRuleOvertimeRules";
import MasterDataAddVisitors from "./Page/MasterDataAddVisitors";
import MasterDataRecognitionsFormsCreateForm from "./Page/MasterDataRecognitionsFormsCreateForm";
import PayrollAddConfiguration from "./Page/PayrollAddConfiguration";
import PayrollCreateSalaryTemplates from "./Page/PayrollCreateSalaryTemplates";
import PayrollAddNewSalaryStructures from "./Page/PayrollAddNewSalaryStructures";
import PayrollSalaryAddBonusTemplates from "./Page/PayrollSalaryAddBonusTemplates";
import MasterDataAddHolidayCalendar from "./Page/MasterDataAddHolidayCalendar";
import CloudIntegrationWebhooks from "./Page/CloudIntegrationWebhooks";
import CloudIntegrationCreateWebhooks from "./Page/CloudIntegrationCreateWebhooks";
import CloudIntegrationServerConfig from "./Page/CloudIntegrationServerConfig";
import CloudIntegrationScheduledJobs from "./Page/CloudIntegrationScheduledJobs";
import CloudIntegrationCreateScheduledJobs from "./Page/CloudIntegrationCreateScheduledJobs";
import CloudAPIIntegration from "./Page/CloudAPIIntegration";
import SettingAccount from "./Page/SettingAccount";
import DeviceList from "./Page/DeviceList";
import AddDevice from "./Page/AddDevice";
import DeviceConfigurations from "./Page/DeviceConfigurations";
import MasterDataLocations from "./Page/MasterDataLocations";
import MasterDataAddLocations from "./Page/MasterDataAddLocations";
import MasterDataCompany from "./Page/MasterDataCompany";
import MasterDataAddCompany from "./Page/MasterDataAddCompany";
import MasterDataDepartment from "./Page/MasterDataDepartment";
import MasterDataAddDepartment from "./Page/MasterDataAddDepartment";
import MasterDataDesignation from "./Page/MasterDataDesignation";
import MasterDataAddDesignation from "./Page/MasterDataAddDesignation";
import MasterDataShift from "./Page/MasterDataShift";
import MasterDataAddShift from "./Page/MasterDataAddShift";
import MasterDataShiftPlan from "./Page/MasterDataShiftPlan";
import MasterDataAddShiftPlan from "./Page/MasterDataAddShiftPlan";
import MasterDataLeaveType from "./Page/MasterDataLeaveType";
import MasterDataAddLeaveType from "./Page/MasterDataAddLeaveType";
import MasterDataCheckInLocations from "./Page/MasterDataCheckInLocations";
import MasterDataCheckInAddLocationsCategory from "./Page/MasterDataCheckInAddLocationsCategory";
import MasterDataCheckInAddCheckInLocations from "./Page/MasterDataCheckInAddCheckInLocations";
import PayrollSalaryStatutoryComponents from "./Page/PayrollSalaryStatutoryComponents";
import GlobalDeviceSettings from "./Page/GlobalDeviceSettings";
import MasterDataAddEmployees from "./Page/MasterDataAddEmployees";



function App() {

  return (
    <>
      <BrowserRouter>
        <Routes>
          {/* <Route path='/' element={<Home />} /> */}
          {/* <Route path='/login' element={<Login />} /> */}
          {/* <Route path='/dashboard' element={<Dashboard />} /> */}
          {/* <Route path='/homeprofile-profile' element={<HomeprofileProfile />} />
          <Route path='/homeprofile-change-password' element={<ChangePassword />} /> */}
          {/* <Route path='/shift-schedule' element={<ShiftSchedule />} /> */}
          {/* <Route path='/recognitions-employees' element={<RecognitionsEmployees />} />
          <Route path='/recognitions-unknowns' element={<RecognitionsUnknowns />} />
          <Route path='/recognitions-visitors' element={<RecognitionsVisitors />} />
          <Route path='/recognitions-entrylogs' element={<RecognitionsEntryLogs />} /> */}
          {/* <Route path='/journey-plans' element={<JourneyPlans />} />
          <Route path='/journey-tasks' element={<JourneyTasks />} /> */}
          {/* <Route path='/exceptions-list' element={<ExceptionsList />} />
          <Route path='/exceptions-regularizationsrequests' element={<ExceptionsRegularizationsRequests />} />
          <Route path='/exceptions-auditslogs' element={<ExceptionsAuditsLogs />} /> */}
          {/* <Route path='/leave-transactions' element={<LeaveTransactions />} />
          <Route path='/leave-requests' element={<LeaveRequests />} />
          <Route path='/leave-encashment' element={<LeaveEncashment />} /> */}
          {/* <Route path='/device-employee-relationship' element={<DeviceEmployeeRelationship />} />
          <Route path='/master-data-employees' element={<MasterDataEmployees />} />
          <Route path='/master-data-recognitions-forms' element={<MasterDataRecognitionsForms />} />
          <Route path='/master-data-visitors' element={<MasterDataVisitors />} />
          <Route path='/payroll-salary-components' element={<PayrollSalaryComponents />} />
          <Route path='/payroll-salary-structures' element={<PayrollSalaryStructures />} />
          <Route path='/payroll-salary-templates' element={<PayrollSalaryTemplates />} />
          <Route path='/payroll-salary-bonus-templates' element={<PayrollSalaryBonusTemplates />} />
          <Route path='/payroll-configuration' element={<PayrollConfiguration />} />
          <Route path='/overtime-rules' element={<OvertimeRules />} />
          <Route path='/payroll-loans' element={<PayrollLoans />} />
          <Route path='/payroll-run' element={<PayrollRun />} />
          <Route path='/payroll-history' element={<PayrollHistory />} />
          <Route path='/settings-users' element={<SettingsUsers />} />
          <Route path='/help-report-anissue' element={<HelpReportAnIssue />} /> */}
          {/* <Route path='/report-muster-roll' element={<ReportMusterRoll />} />
          <Route path='/report-monthly-performance-register' element={<ReportMonthlyPerformanceRegister />} />
          <Route path='/report-attendance-details-bydate' element={<ReportAttendanceDetailsByDate />} />
          <Route path='/report-attendance-details-byemployee' element={<ReportAttendanceDetailsByEmployee />} />
          <Route path='/report-presence-bydetection' element={<ReportPresenceByDetection />} />
          <Route path='/report-attendance-bydesignation' element={<ReportAttendanceByDesignation />} />
          <Route path='/report-attendance-bydepartment' element={<ReportAttendanceByDepartment />} />
          <Route path='/report-multi-punches' element={<ReportMultiPunches />} />
          <Route path='/report-employee-wisedetail' element={<ReportEmployeeWiseDetail />} />
          <Route path='/report-spot' element={<ReportSpot />} /> */}
          {/* <Route path='/report-shift' element={<ReportShift />} />
          <Route path='/report-monthly-performance-Wwithloss-hoursdetails' element={<ReportMonthlyPerformanceWithLossHoursDetails />} />
          <Route path='/report-shorthours' element={<ReportShortHours />} />
          <Route path='/report-monthly-attendance' element={<ReportMonthlyAttendance />} />
          <Route path='/report-visitor-inout' element={<ReportVisitorInOut />} />
          <Route path='/report-daily-performance' element={<ReportDailyPerformance />} />
          <Route path='/report-time-loss-summary' element={<ReportTimeLossSummary />} />
          <Route path='/report-time-loss' element={<ReportTimeLoss />} />
          <Route path='/report-recognition-logs' element={<ReportRecognitionLogs />} />
          <Route path='/report-current-presence' element={<ReportCurrentPresence />} />
          <Route path='/report-form-no26' element={<ReportFormNo26 />} />
          <Route path='/report-form-no12' element={<ReportFormNo12 />} />
          <Route path='/report-form-no25' element={<ReportFormNo25 />} /> */}
          {/* <Route path='/report-yearly-attendance-details' element={<ReportYearlyAttendanceDetails />} />
          <Route path='/report-yearly' element={<ReportYearly />} />
          <Route path='/report-employee-missing-images' element={<ReportEmployeeMissingImages />} />
          <Route path='/report-unlinked-employees' element={<ReportUnlinkedEmployees />} />
          <Route path='/report-system-usage-logs' element={<ReportSystemUsageLogs />} />
          <Route path='/report-all-exceptions' element={<ReportAllExceptions />} />
          <Route path='/report-open-exceptions' element={<ReportOpenExceptions />} />
          <Route path='/report-open-regularization-requests' element={<ReportOpenRegularizationRequests />} />
          <Route path='/report-open-leave-requests' element={<ReportOpenLeaveRequests />} />
          <Route path='/report-muster-absentees' element={<ReportMusterAbsentees />} />
          <Route path='/report-absentees' element={<ReportAbsentees />} />
          <Route path='/report-early-departure' element={<ReportEarlyDeparture />} />
          <Route path='/report-late-register' element={<ReportLateRegister />} />
          <Route path='/report-continuous-early-departure' element={<ReportContinuousEarlyDeparture />} />
          <Route path='/report-continuous-late-arrival' element={<ReportContinuousLateArrival />} />
          <Route path='/report-continuous-absenteeism' element={<ReportContinuousAbsenteeism />} />
          <Route path='/report-leave-balance' element={<ReportLeaveBalance />} />
          <Route path='/report-leave-register' element={<ReportLeaveRegister />} />
          <Route path='/report-leave-taken' element={<ReportLeaveTaken />} /> */}
          {/* <Route path='/report-overtime' element={<ReportOverTime />} />
          <Route path='/report-attendance-otstatus' element={<ReportAttendanceOTStatus />} />
          <Route path='/report-salary-register' element={<ReportSalaryRegister />} />
          <Route path='/report-ctc' element={<ReportCTC />} />
          <Route path='/report-salary-history' element={<ReportSalaryHistory />} />
          <Route path='/report-department-summary' element={<ReportDepartmentSummary />} />
          <Route path='/report-deductions' element={<ReportDeductions />} />
          <Route path='/report-loan' element={<ReportLoan />} />
          <Route path='/report-pf-statement' element={<ReportPFStatement />} />
          <Route path='/report-pf-challan' element={<ReportPFChallan />} />
          <Route path='/report-pf-uploadformat' element={<ReportPFUploadFormat />} />
          <Route path='/report-esi-statement' element={<ReportESIStatement />} />
          <Route path='/report-esi-challan' element={<ReportESIChallan />} />
          <Route path='/report-esi-uploadformat' element={<ReportESIUploadFormat />} />
          <Route path='/report-salary-transfer-statement' element={<ReportSalaryTransferStatement />} />
          <Route path='/report-new-joinees' element={<ReportNewJoinees />} />
          <Route path='/report-resignees' element={<ReportResignees />} /> */}

          <Route path='/login' element={<Login />} />
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="shift-schedule" element={<ShiftSchedule />} />
            <Route path='homeprofile-profile' element={<HomeprofileProfile />} />
            <Route path='homeprofile-change-password' element={<ChangePassword />} />
            <Route path='recognitions-employees' element={<RecognitionsEmployees />} />
            <Route path='recognitions-employees/addmanual' element={<RecognitionsEmployeesAddManual />} />
            <Route path='recognitions-unknowns' element={<RecognitionsUnknowns />} />
            <Route path='recognitions-visitors' element={<RecognitionsVisitors />} />
            <Route path='recognitions-entrylogs' element={<RecognitionsEntryLogs />} />
            <Route path='journey-plans' element={<JourneyPlans />} />
            <Route path='journey-plans/create-journeyplan' element={<CreateJourneyPlan />} />
            <Route path='journey-tasks' element={<JourneyTasks />} />
            <Route path='journey-tasks/add-task' element={<CreateNewTaskJourneyTask />} />
            <Route path='exceptions-list' element={<ExceptionsList />} />
            <Route path='exceptions-regularizationsrequests' element={<ExceptionsRegularizationsRequests />} />
            <Route path='exceptions-auditslogs' element={<ExceptionsAuditsLogs />} />
            <Route path='leave-transactions' element={<LeaveTransactions />} />
            <Route path='leave-requests' element={<LeaveRequests />} />
            <Route path='leave-requests/leave-apply' element={<LeaveRequestsApplyLeave />} />
            <Route path='leave-encashment' element={<LeaveEncashment />} />
            <Route path='device-list' element={<DeviceList />} />
            <Route path='device-list/addDevice' element={<AddDevice />} />
            <Route path='device-employee-relationship' element={<DeviceEmployeeRelationship />} />
            <Route path='device-configurations' element={<DeviceConfigurations />} />
            <Route path='global-device-settings' element={<GlobalDeviceSettings />} />

            <Route path='master-data-employees' element={<MasterDataEmployees />} />
            <Route path='master-data-employees/addEmployee' element={<MasterDataAddEmployees />} />

            <Route path='master-data-locations' element={<MasterDataLocations />} />
            <Route path='master-data-locations/addlocation' element={<MasterDataAddLocations />} />
            <Route path='master-data-company' element={<MasterDataCompany/>} />
            <Route path='master-data-company/addcompany' element={<MasterDataAddCompany/>} />
            <Route path='master-data-department' element={<MasterDataDepartment />} />
            <Route path='master-data-department/adddepartment' element={<MasterDataAddDepartment />} />
            <Route path='master-data-designation' element={<MasterDataDesignation />} />
            <Route path='master-data-designation/addDesignation' element={<MasterDataAddDesignation />} />
            <Route path='master-data-shift' element={<MasterDataShift />} />
            <Route path='master-data-shift/addShift' element={<MasterDataAddShift />} />
            <Route path='master-data-shift-plan' element={<MasterDataShiftPlan />} />
            <Route path='master-data-shift-plan/addShiftPlan' element={<MasterDataAddShiftPlan />} />
            <Route path='master-data-leavetypes' element={<MasterDataLeaveType />} />
            <Route path='master-data-leavetypes/addLeaveType' element={<MasterDataAddLeaveType />} />
            <Route path='master-data-checkin-locations' element={<MasterDataCheckInLocations />} />
            <Route path='master-data-checkin-locations/addLocationCategory' element={<MasterDataCheckInAddLocationsCategory />} />
            <Route path='master-data-checkin-locations/addCheckinLocation' element={<MasterDataCheckInAddCheckInLocations />} />

            <Route path='master-data-holidaycalendar' element={<MasterDataHolidayCalendar />} />
            <Route path='master-data-holidaycalendar/add' element={<MasterDataAddHolidayCalendar />} />
            <Route path='master-data-recognitions-forms' element={<MasterDataRecognitionsForms />} />
            <Route path='master-data-recognitions-forms/create-form' element={<MasterDataRecognitionsFormsCreateForm />} />
            <Route path='master-data-visitors' element={<MasterDataVisitors />} />
            <Route path='master-data-visitors/add-visitor' element={<MasterDataAddVisitors />} />
            <Route path='payroll-salary-components' element={<PayrollSalaryComponents />} />
            <Route path='payroll-salary-structures' element={<PayrollSalaryStructures />} />
            <Route path='payroll-salary-structures/addNew' element={<PayrollAddNewSalaryStructures />} />
            <Route path='payroll-salary-templates' element={<PayrollSalaryTemplates />} />
            <Route path='payroll-salary-templates/addnew' element={<PayrollCreateSalaryTemplates />} />

            <Route path='payroll-salary-statutory-components' element={<PayrollSalaryStatutoryComponents />} />

            <Route path='payroll-salary-bonus-templates' element={<PayrollSalaryBonusTemplates />} />
            <Route path='payroll-salary-bonus-templates/addTemplete' element={<PayrollSalaryAddBonusTemplates/>} />
            <Route path='payroll-configuration' element={<PayrollConfiguration />} />
            <Route path='payroll-configuration/add-configuration' element={<PayrollAddConfiguration />} />
            <Route path='overtime-rules' element={<OvertimeRules />} />
            <Route path='overtime-rules/addnew-rule' element={<AddNewRuleOvertimeRules />} />
            <Route path='payroll-loans' element={<PayrollLoans />} />
            <Route path='payroll-loans/apply-forloan' element={<PayrollApplyForLoan />} />
            <Route path='payroll-run' element={<PayrollRun />} />
            <Route path='payroll-history' element={<PayrollHistory />} />
            <Route path='settings-users' element={<SettingsUsers />} />
            <Route path='settings-users/add-user' element={<SettingAddUser />} />
            <Route path='setting-account' element={<SettingAccount />} />

            <Route path='cloud-integration-webhooks' element={<CloudIntegrationWebhooks />} />
            <Route path='cloud-integration-webhooks/addNew' element={<CloudIntegrationCreateWebhooks />} />
            <Route path='cloud-integration-server-config' element={<CloudIntegrationServerConfig />} />
            <Route path='cloud-integration-scheduled-jobs' element={<CloudIntegrationScheduledJobs />} />
            <Route path='cloud-integration-scheduled-jobs/add' element={<CloudIntegrationCreateScheduledJobs />} />
            <Route path='cloud-api-integration' element={<CloudAPIIntegration />} />
            <Route path='help-report-anissue' element={<HelpReportAnIssue />} />

            <Route path='report-muster-roll' element={<ReportMusterRoll />} />
            <Route path='report-monthly-performance-register' element={<ReportMonthlyPerformanceRegister />} />
            <Route path='report-attendance-details-bydate' element={<ReportAttendanceDetailsByDate />} />
            <Route path='report-attendance-details-byemployee' element={<ReportAttendanceDetailsByEmployee />} />
            <Route path='report-presence-bydetection' element={<ReportPresenceByDetection />} />
            <Route path='report-attendance-bydesignation' element={<ReportAttendanceByDesignation />} />
            <Route path='report-attendance-bydepartment' element={<ReportAttendanceByDepartment />} />
            <Route path='report-multi-punches' element={<ReportMultiPunches />} />
            <Route path='report-employee-wisedetail' element={<ReportEmployeeWiseDetail />} />
            <Route path='report-spot' element={<ReportSpot />} />
            <Route path='report-shift' element={<ReportShift />} />
            <Route path='report-monthly-performance-withloss-hoursdetails' element={<ReportMonthlyPerformanceWithLossHoursDetails />} />
            <Route path='report-shorthours' element={<ReportShortHours />} />
            <Route path='report-monthly-attendance' element={<ReportMonthlyAttendance />} />
            <Route path='report-visitor-inout' element={<ReportVisitorInOut />} />
            <Route path='report-daily-performance' element={<ReportDailyPerformance />} />
            <Route path='report-time-loss-summary' element={<ReportTimeLossSummary />} />
            <Route path='report-time-loss' element={<ReportTimeLoss />} />
            <Route path='report-recognition-logs' element={<ReportRecognitionLogs />} />
            <Route path='report-current-presence' element={<ReportCurrentPresence />} />
            <Route path='report-form-no26' element={<ReportFormNo26 />} />
            <Route path='report-form-no12' element={<ReportFormNo12 />} />
            <Route path='report-form-no25' element={<ReportFormNo25 />} />
            <Route path='report-yearly-attendance-details' element={<ReportYearlyAttendanceDetails />} />
            <Route path='report-yearly' element={<ReportYearly />} />
            <Route path='report-employee-missing-images' element={<ReportEmployeeMissingImages />} />
            <Route path='report-unlinked-employees' element={<ReportUnlinkedEmployees />} />
            <Route path='report-system-usage-logs' element={<ReportSystemUsageLogs />} />
            <Route path='report-all-exceptions' element={<ReportAllExceptions />} />
            <Route path='report-open-exceptions' element={<ReportOpenExceptions />} />
            <Route path='report-open-regularization-requests' element={<ReportOpenRegularizationRequests />} />
            <Route path='report-open-leave-requests' element={<ReportOpenLeaveRequests />} />
            <Route path='report-muster-absentees' element={<ReportMusterAbsentees />} />
            <Route path='report-absentees' element={<ReportAbsentees />} />
            <Route path='report-early-departure' element={<ReportEarlyDeparture />} />
            <Route path='report-late-register' element={<ReportLateRegister />} />
            <Route path='report-continuous-early-departure' element={<ReportContinuousEarlyDeparture />} />
            <Route path='report-continuous-late-arrival' element={<ReportContinuousLateArrival />} />
            <Route path='report-continuous-absenteeism' element={<ReportContinuousAbsenteeism />} />
            <Route path='report-leave-balance' element={<ReportLeaveBalance />} />
            <Route path='report-leave-register' element={<ReportLeaveRegister />} />
            <Route path='report-leave-taken' element={<ReportLeaveTaken />} />
            <Route path='report-overtime' element={<ReportOverTime />} />
            <Route path='report-attendance-otstatus' element={<ReportAttendanceOTStatus />} />
            <Route path='report-salary-register' element={<ReportSalaryRegister />} />
            <Route path='report-ctc' element={<ReportCTC />} />
            <Route path='report-salary-history' element={<ReportSalaryHistory />} />
            <Route path='report-department-summary' element={<ReportDepartmentSummary />} />
            <Route path='report-deductions' element={<ReportDeductions />} />
            <Route path='report-loan' element={<ReportLoan />} />
            <Route path='report-pf-statement' element={<ReportPFStatement />} />
            <Route path='report-pf-challan' element={<ReportPFChallan />} />
            <Route path='report-pf-uploadformat' element={<ReportPFUploadFormat />} />
            <Route path='report-esi-statement' element={<ReportESIStatement />} />
            <Route path='report-esi-challan' element={<ReportESIChallan />} />
            <Route path='report-esi-uploadformat' element={<ReportESIUploadFormat />} />
            <Route path='report-salary-transfer-statement' element={<ReportSalaryTransferStatement />} />
            <Route path='report-new-joinees' element={<ReportNewJoinees />} />
            <Route path='report-resignees' element={<ReportResignees />} />
          </Route>
          <Route path="*" element={<NotFound />} />




        </Routes>
      </BrowserRouter>

    </>
  );
}

export default App;
