import { Box } from "@mui/material"
import Header from "../Common/Header/Header";
import { MdAccountBalance, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { Button } from 'primereact/button';
import { Link } from "react-router-dom";
import '../Styles/masterDataCheckInAddLocationsCategoryStyle.css'
import { useState } from "react";
import { Dropdown } from "primereact/dropdown";
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { InputText } from "primereact/inputtext";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';


const MasterDataCheckInAddLocationsCategory = () => {
    const [selectedCity, setSelectedCity] = useState(null);
    const cities = [
        { name: 'New York', code: 'NY' },
        { name: 'Rome', code: 'RM' },
        { name: 'London', code: 'LDN' },
        { name: 'Istanbul', code: 'IST' },
        { name: 'Paris', code: 'PRS' }
    ];


    return (
        <>
            <Box className='home-mainbox'>
                <div className="rightbox">
                    <Header />
                    <Box className="createform-head">
                        <div className="createform">
                            <div><MdAccountBalance className=" fs-5" /></div>
                            <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                            <div>Master Data </div>
                            <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                            <div>Check-in Locations </div>
                            <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                            <div>Location Categories </div>
                            <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                            <div>Add New </div>
                        </div>
                    </Box>

                    <Box className="full-box">
                        <p className="heading-p">Add Location Category</p>
                        <div className=' d-flex m-2'>
                            <div className="m-1">
                                <InputText placeholder="Category" />
                            </div>
                            <div className="m-1">
                                <Dropdown
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    placeholder="Access Level"
                                    className="" />
                            </div>

                        </div>

                        <div className="selectionBox">
                            <div className="addLocationBox">
                                <div className=" d-flex justify-content-between align-items-center">
                                    <p className="pt1">Dynamic Location</p>
                                    <div className="">
                                        <FormGroup>
                                            <FormControlLabel control={<Switch />} label="" />
                                        </FormGroup>
                                    </div>
                                </div>
                                <p className="pt2">Employees can add new location in this Category</p>
                            </div>

                            <div className="addLocationBox">
                                <div className=" d-flex justify-content-between align-items-center">
                                    <p className="pt1">Check-in Form</p>
                                    <div className="">
                                        <FormGroup>
                                            <FormControlLabel control={<Switch />} label="" />
                                        </FormGroup>
                                    </div>
                                </div>
                                <p className="pt2">The Form needs to be filled when an employee checks in at any location under this category</p>
                            </div>
                            <div className="addLocationBox">
                                <div className=" d-flex justify-content-between align-items-center">
                                    <p className="pt1">Check-out Form</p>
                                    <div className="">
                                        <FormGroup>
                                            <FormControlLabel control={<Switch />} label="" />
                                        </FormGroup>
                                    </div>
                                </div>
                                <p className="pt2">The Form needs to be filled when an employee checks out at any location under this category</p>
                            </div>


                        </div>

                        <div className="buttons">
                            <Button className="button2" size="small" label="Cancel" outlined />
                            <Button className="button1" size="small" label="Save " severity="info" />
                        </div>

                    </Box>
                </div>
            </Box >

        </>
    )
}

export default MasterDataCheckInAddLocationsCategory

