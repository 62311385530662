import { Box } from "@mui/material"
import '../Styles/reportMonthlyPerformanceRegister.css'
import Header from "../Common/Header/Header";
import { MdAccountBalance, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { useState } from "react";
import { MultiSelect } from 'primereact/multiselect';
import 'primereact/resources/themes/lara-light-cyan/theme.css';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";



const MasterDataCheckInAddCheckInLocations = () => {
    const [selectedCities, setSelectedCities] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [date, setDate] = useState(null);
    const [val, setVal] = useState('');

    const cities = [
        { name: 'New York', code: 'NY' },
        { name: 'Rome', code: 'RM' },
        { name: 'London', code: 'LDN' },
        { name: 'Istanbul', code: 'IST' },
        { name: 'Paris', code: 'PRS' }
    ];

    const Allcities = [
        { name: 'Employee ID', code: 'NY' },
        { name: 'Employee Name', code: 'RM' },
    ];


    return (
        <>
            <Box className='home-mainbox'>
                <div className="rightbox">
                    <Header />
                    <Box className="second-head">
                        <div><MdAccountBalance className=" fs-5" /></div>
                        <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                        <div>Master Data </div>
                        <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                        <div>Check-in Locations</div>
                        <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                        <div>Location</div>
                        <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                        <div>Add Check-In</div>
                    </Box>

                    <Box className="full-box">
                        <p className="heading-p">Add Check-In Location</p>

                        <div className="selectionBox">
                        <div className="card">
                                <Dropdown
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    editable placeholder="Category"
                                    className="" />
                            </div>
                            <div className="card">
                                <Dropdown
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    editable placeholder="Access Level"
                                    className="" />
                            </div>
                            <div className="card">
                                <MultiSelect
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    filter placeholder="Company"
                                    maxSelectedLabels={3}
                                    className="" />
                            </div>
                         
                            <div className="card ">
                                <InputText placeholder="Location Name" />
                            </div>
                            <div className="card">
                                <InputTextarea
                                    value={val}
                                    onChange={(e) => setVal(e.target.value)}
                                    filter placeholder="Address Line 1"
                                    rows={2} cols={30}
                                />
                            </div>
                            <div className="card">
                                <InputTextarea
                                    value={val}
                                    onChange={(e) => setVal(e.target.value)}
                                    filter placeholder="Address Line 2"
                                    rows={2} cols={30}
                                />
                            </div>
                            <div className="card ">
                                <InputText placeholder="Postal Code" />
                            </div>
                            <div className="card ">
                                <InputText placeholder="City" />
                            </div>
                            <div className="card ">
                                <InputText placeholder="State" />
                            </div>
                            <div className="card">
                                <Dropdown
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    editable placeholder="Country"
                                    className="" />
                            </div>

                            <div className="card ">
                                <InputText placeholder="Latitude" />
                            </div>
                            <div className="card ">
                                <InputText placeholder="Longitude" />
                            </div>

                            <div className="card "></div>
                            <div className=" mx-2 my-4">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d112059.37471475975!2d77.1698044!3d28.6340938!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1711042999153!5m2!1sen!2sin"
                                    width="900px" height="460"
                                    style={{ border: 'none', borderRadius: '6px' }}
                                    // allowfullscreen=""
                                    loading="lazy"
                                    referrerpolicy="no-referrer-when-downgrade"
                                >
                                </iframe>
                            </div>
                        </div>

                        <div className="buttons">
                            <Button className="button1" size="small" label="Submit" severity="info" />
                            <Button className="button2" size="small" label="Cancel" severity="info" outlined />
                        </div>

                    </Box>
                </div>
            </Box >

        </>
    )
}

export default MasterDataCheckInAddCheckInLocations

