import React from "react";
//import react pro sidebar components
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarContent,
  SubMenu,
} from "react-pro-sidebar";

//import sidebar css from react-pro-sidebar module and our custom css 
import "react-pro-sidebar/dist/css/styles.css";
import "../Styles/sidebarStyle.css"

//import icons from react icons
import { MdOutlineStorefront, MdEventNote, MdAppSettingsAlt, MdOutlineSettings, MdReportProblem } from "react-icons/md";
import { CgNotes } from "react-icons/cg";
import { CiLocationArrow1 } from "react-icons/ci";
import { Link, NavLink } from "react-router-dom";
import { useState } from "react";
import imglogo from '../Assets/mainlogo.png'
import { IoIosPeople } from "react-icons/io";
import { IoPeople } from "react-icons/io5";
import { GiSharpedTeethSkull } from "react-icons/gi";
import { LuCalendarClock } from "react-icons/lu";
import { FaPersonCircleQuestion, FaFileCircleQuestion, FaLayerGroup, FaBusinessTime } from "react-icons/fa6";
import { SlSettings } from "react-icons/sl";
import { HiBuildingOffice2, HiMiniDevicePhoneMobile } from "react-icons/hi2";
import { HiOutlineCurrencyRupee, HiLocationMarker } from "react-icons/hi";
import { AiFillAlert } from "react-icons/ai";
import { PiWebhooksLogoBold } from "react-icons/pi";
import { TbCloudComputing, TbCalendarCog, TbDeviceRemote } from "react-icons/tb";
import { RiGuideLine } from "react-icons/ri";
import { BsTextIndentRight, BsPersonFillExclamation, BsDatabaseFill } from "react-icons/bs";
import { FaRegArrowAltCircleRight, FaGooglePlay, FaClipboardList, FaMoneyCheck, FaCubes, FaCalendarCheck } from "react-icons/fa";

const DashboardSidebar = () => {

  const [collapsed, setCollapsed] = useState(false);

  const [toggled, setToggled] = useState(false);

  const handleCollapsedChange = () => {
    setCollapsed(!collapsed);

  };

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };
  return (
    <>
      <div id="main-div">
        <ProSidebar
          // className={`app ${toggled ? "toggled" : ""}`}
          style={{ height: "100%", position: "absolute" }}
          // collapsed={collapsed}
          // toggled={toggled}
          // handleToggleSidebar={handleToggleSidebar}
          // handleCollapsedChange={handleCollapsedChange}
        >

          {/* <Menu style={{ padding: '0' }}>
            {collapsed ? (
              <MenuItem
                icon={<FaRegArrowAltCircleRight className=" fs-3" />}
                onClick={handleCollapsedChange}
              ></MenuItem>
            ) : (
              <MenuItem
                suffix={<BsTextIndentRight className=" fs-3 ms-3" />}
                onClick={handleCollapsedChange}
              >
                <div
                  style={{
                    padding: "2px",
                    // textTransform: "uppercase",
                    fontWeight: "bold",
                    fontSize: 14,
                    letterSpacing: "1px"
                  }}
                >
                  <MenuItem className='imgbox'>
                    <NavLink to={'/'}> <img className="imglogo" src={imglogo} alt="img" /> </NavLink>
                  </MenuItem>
                </div>
              </MenuItem>
            )}
            <hr />
          </Menu> */}
          <MenuItem className='imgbox'>
            <NavLink to={'/'}> <img className="imglogo" src={imglogo} alt="img" /> </NavLink>
          </MenuItem>
          <hr className="m-0" />

          <div className="sidebar-body-div">
            <SidebarContent className="main-div">
              <Menu className="sidebar-content-div"
                iconShape="circle"
              >
                <MenuItem icon={<CgNotes />}>
                  Dashboard
                  <Link to={'/dashboard'}></Link>
                </MenuItem>

                <MenuItem icon={<CgNotes />}>
                  Reports
                  <Link to={'/'}></Link>
                </MenuItem>

                <SubMenu title="Recognitions" icon={<IoPeople />}>  {/* defaultOpen */}
                  <MenuItem icon={<IoPeople />}> Employees  <Link to="/recognitions-employees" /></MenuItem>
                  <MenuItem icon={<GiSharpedTeethSkull />}>Unknowns <Link to="/recognitions-unknowns" /></MenuItem>
                  <MenuItem icon={<GiSharpedTeethSkull />}>Visitors <Link to="/recognitions-visitors" /></MenuItem>
                  <MenuItem icon={<IoPeople />}>Entry Logs <Link to="/recognitions-entrylogs" /></MenuItem>
                </SubMenu>

                <MenuItem icon={<LuCalendarClock />}>
                  Shifts Schedulers <Link to={'/shift-schedule'}></Link>
                </MenuItem>

                <SubMenu title="Journey" icon={<FaCalendarCheck />}>
                  <MenuItem icon={<MdEventNote />}>Plans  <Link to="/journey-plans" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>Tasks <Link to="/journey-tasks" /></MenuItem>
                </SubMenu>

                <SubMenu title="Exceptions" icon={<BsPersonFillExclamation />}>
                  <MenuItem icon={<FaFileCircleQuestion />}>Exceptions List  <Link to="/exceptions-list" /></MenuItem>
                  <MenuItem icon={<FaPersonCircleQuestion />}>Regularizations Requests <Link to="/exceptions-regularizationsrequests" /></MenuItem>
                  <MenuItem icon={<FaPersonCircleQuestion />}>Audits Logs <Link to="/exceptions-auditslogs" /></MenuItem>
                </SubMenu>

                <SubMenu title="Leaves" icon={<FaCalendarCheck />}>
                  <MenuItem icon={<MdEventNote />}>Leave Transactions  <Link to="/leave-transactions" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>Leaves Requests <Link to="/leave-requests" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>Leaves Encashment <Link to="/leave-encashment" /></MenuItem>
                </SubMenu>


                <SubMenu title="Devices" icon={<HiMiniDevicePhoneMobile />}>
                  <MenuItem icon={<TbDeviceRemote />}>Device List  <Link to="/device-list" /></MenuItem>
                  <MenuItem icon={<BsPersonFillExclamation />}>Employee Relationship <Link to="/device-employee-relationship" /></MenuItem>
                  <MenuItem icon={<SlSettings />}>Global Device Settings <Link to="/global-device-settings" /></MenuItem>
                  <MenuItem icon={<MdAppSettingsAlt />}>Device Configurations <Link to="/device-configurations" /></MenuItem>
                </SubMenu>

                <SubMenu title="Master Data" icon={<BsDatabaseFill />}>
                  <MenuItem icon={<HiLocationMarker />}>Locations<Link to="/master-data-locations" /></MenuItem>
                  <MenuItem icon={<HiBuildingOffice2 />}>Companies <Link to="/master-data-company" /></MenuItem>
                  <MenuItem icon={<FaLayerGroup />}>Departments<Link to="/master-data-department" /></MenuItem>
                  <MenuItem icon={<FaCubes />}>Designations <Link to="/master-data-designation" /></MenuItem>
                  <MenuItem icon={<FaBusinessTime />}>Shifts <Link to="/master-data-shift" /></MenuItem>
                  <MenuItem icon={<FaBusinessTime />}>Shift Plans <Link to="/master-data-shift-plan" /></MenuItem>
                  <MenuItem icon={<LuCalendarClock />}>Holiday Calendars <Link to="/master-data-holidaycalendar" /></MenuItem>
                  <MenuItem icon={<TbCalendarCog />}>Leave Types <Link to="/master-data-leavetypes" /></MenuItem>
                  <SubMenu title="Payroll Settings" icon={<HiOutlineCurrencyRupee />}>
                    <MenuItem>Salary Components<Link to="/payroll-salary-components" /></MenuItem>
                    <MenuItem>Salary Structures <Link to="/payroll-salary-structures" /></MenuItem>
                    <MenuItem>Salary Templates<Link to="/payroll-salary-templates" /></MenuItem>
                    <MenuItem>Bonus Templates  <Link to="/payroll-salary-bonus-templates" /></MenuItem>
                    <MenuItem>Statutory Components <Link to="/payroll-salary-statutory-components" /></MenuItem>
                    <MenuItem>Configuration <Link to="/payroll-configuration" /></MenuItem>
                  </SubMenu>
                  <MenuItem icon={<LuCalendarClock />}>Check-in Locations <Link to="/master-data-checkin-locations" /></MenuItem>
                  <MenuItem icon={<FaCubes />}>Visitors <Link to="/master-data-visitors" /></MenuItem>
                  <MenuItem icon={<BsPersonFillExclamation />}>Recognitions Forms <Link to="/master-data-recognitions-forms" /></MenuItem>
                  <MenuItem icon={<IoIosPeople />}>Employees <Link to="/master-data-employees" /></MenuItem>
                </SubMenu>

                <MenuItem icon={<BsDatabaseFill />}>
                  Overtime Rules <Link to={'/overtime-rules'}> </Link>
                </MenuItem>

                <SubMenu title="Payroll" icon={<MdOutlineStorefront />}>
                  <MenuItem icon={<TbDeviceRemote />}>Run Payroll  <Link to="/payroll-run" /></MenuItem>
                  <MenuItem icon={<BsPersonFillExclamation />}>Payroll History  <Link to="/payroll-history" /></MenuItem>
                  <MenuItem icon={<SlSettings />}>Loans <Link to="/payroll-loans" /></MenuItem>
                </SubMenu>

                <SubMenu title="Settings" icon={<MdOutlineSettings />}>
                  <MenuItem icon={<FaMoneyCheck />}>Account  <Link to="/setting-account" /></MenuItem>
                  <MenuItem icon={<AiFillAlert />}>Attendance <Link to="/setting-attendance" /></MenuItem>
                  <MenuItem icon={<AiFillAlert />}>Mobile Apps <Link to="/" /></MenuItem>
                  <MenuItem icon={<IoIosPeople />}>Users <Link to="/settings-users" /></MenuItem>
                </SubMenu>

                <SubMenu title="Cloud Integration" icon={<TbCloudComputing />}>
                  <MenuItem icon={<PiWebhooksLogoBold />}>Webhooks  <Link to="/cloud-integration-webhooks" /></MenuItem>
                  <MenuItem icon={<FaLayerGroup />}>FTP Server Config <Link to="/cloud-integration-server-config" /></MenuItem>
                  <MenuItem icon={<FaClipboardList />}>Scheduled Jobs <Link to="/cloud-integration-scheduled-jobs" /></MenuItem>
                  <MenuItem icon={<CiLocationArrow1 />}>API Integration <Link to="/cloud-api-integration" /></MenuItem>
                </SubMenu>

                <SubMenu title="Help" icon={<AiFillAlert />}>
                  <MenuItem icon={<RiGuideLine />}>Guide  <Link to="/" /></MenuItem>
                  <MenuItem icon={<MdReportProblem />}>Report an Issue <Link to="/help-report-anissue" /></MenuItem>
                  <MenuItem icon={<FaGooglePlay />}>Admin App<Link to="/" /></MenuItem>
                  <MenuItem icon={<FaGooglePlay />}>Employee App <Link to="/" /></MenuItem>
                </SubMenu>

              </Menu>
            </SidebarContent>
          </div>
        </ProSidebar>
      </div>
    </>
  );
};

export default DashboardSidebar;

