import { DataGrid } from '@mui/x-data-grid';
import Header from '../Common/Header/Header';
import { Box, Button, TextField } from '@mui/material';
import { MdAccountBalance, MdOutlineKeyboardArrowRight, MdClose } from "react-icons/md";
import '../Styles/RecognitionsUnknowns.css'
import filterImg from '../Assets/filter.png'
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { useState } from 'react';
import { Tab, TabPanel, Tabs, TabsList } from "@mui/base";
import { MultiSelect } from 'primereact/multiselect';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

const columns = [
    {
        field: 'date',
        headerName: 'Date',
        width: 120,
    },
    {
        field: 'time',
        headerName: 'Time',
        width: 120,
    },
    {
        field: 'id',
        headerName: 'Emp ID',
        width: 120
    }, {
        field: 'EmpName',
        headerName: 'Emp Name',
        width: 120
    },





    {
        field: 'company',
        headerName: 'Company',
        width: 120
    },
    {
        field: 'device',
        headerName: 'Device',
        width: 120
    },
    {
        field: 'location',
        headerName: 'Location',
        width: 120,
    }
];

const rows = [
    { id: 1, name: 'Snow ', company: 'abcds', location: 'Jon' },
    { id: 2, name: 'Lannister', company: 'abcds', location: 'Cersei' },
    { id: 3, name: 'Lannister', company: 'abcds', location: 'Jaime' },
    { id: 4, name: 'Stark', company: 'abcds', location: 'Arya' },
    { id: 5, name: 'Targaryen', company: 'abcds', location: 'Daenerys' },
    { id: 6, name: 'Melisandre', company: 'abcds', location: null },
    { id: 7, name: 'Clifford', company: 'abcds', location: 'Ferrara' },
    { id: 8, name: 'Frances', company: 'abcds', location: 'Rossini' },
    { id: 9, name: 'Roxie', company: 'abcds', location: 'Harvey' },
];

const RecognitionsEntryLogs = () => {
    const [openFilter, setOpenFilter] = useState(false);
    const [value, setValue] = useState(1);
    const [selectedCities, setSelectedCities] = useState(null);
    const cities = [
        { name: 'New York', code: 'NY' },
        { name: 'Rome', code: 'RM' },
        { name: 'London', code: 'LDN' },
        { name: 'Istanbul', code: 'IST' },
        { name: 'Paris', code: 'PRS' }
    ];


    const onFilterClick = () => {
        setOpenFilter(!openFilter)
    }

    const onFilterHandleclose = () => {
        setOpenFilter(false)

    };
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleExportExcel = () => {
        // Convert table data to Excel format
        const worksheet = XLSX.utils.json_to_sheet(rows);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        // Generate buffer and create Blob
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Trigger download
        saveAs(blob, 'table_data.xlsx');
    };

    return (
        <>
            <Box className='Shifthome-mainbox'>
                <div className="Shift-rightbox">
                    <Header />
                    <Box className="Shift-second-head">
                        <div className='hamburg'>
                            <div><MdAccountBalance className=" fs-5" /></div>
                            <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                            <div>Recognitions</div>
                            <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                            <div>Entry Logs</div>
                        </div>
                        <div className='btnbox'>
                            <Button onClick={handleExportExcel}>Export Data</Button>
                        </div>
                    </Box>
                    <Box className='filterDiv'>
                        <div className='fielddate'>
                            <div>
                                <TextField type='date' size='small' />
                            </div>
                        </div>
                        <div className='searchbox'>
                            <div className='search-inputdiv'>
                                <Paper
                                    component="form"
                                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400, borderRadius: '20px' }}
                                >
                                    <InputBase
                                        size='small'
                                        sx={{ ml: 1, flex: 1 }}
                                        placeholder="Search by ID / Name"
                                        inputProps={{ 'aria-label': 'Search by ID / Name' }}
                                    />
                                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                        <SearchIcon />
                                    </IconButton>
                                </Paper>
                            </div>
                            <div className='img-div'>
                                <img onClick={onFilterClick} src={filterImg} alt="img" />

                                <Box className={openFilter ? "openfilterdiv" : "hiddenfilterdiv"}>
                                    <b onClick={onFilterHandleclose}><MdClose className=' fs-4' /></b>
                                    <p>Filters</p>
                                    <Box>
                                        <Tabs className='tabs-box' value={value} onChange={handleChange}>
                                            <div>
                                                <TabsList className="tabslistdiv">
                                                    <Tab className="mangetab" style={value === 0 ? { backgroundColor: '#629cdf', color: 'white', borderRadius: '0 30px 30px 0' } : {}} value={0}>Company </Tab>
                                                    <Tab className="mangetab" style={value === 1 ? { backgroundColor: '#629cdf', color: 'white', borderRadius: '0 30px 30px 0' } : {}} value={1}>Department </Tab>
                                                    <Tab className="mangetab" style={value === 2 ? { backgroundColor: '#629cdf', color: 'white', borderRadius: '0 30px 30px 0' } : {}} value={2}>Device </Tab>
                                                    <Tab className="mangetab" style={value === 3 ? { backgroundColor: '#629cdf', color: 'white', borderRadius: '0 30px 30px 0' } : {}} value={3}>Locations </Tab>
                                                    <Tab className="mangetab" style={value === 4 ? { backgroundColor: '#629cdf', color: 'white', borderRadius: '0 30px 30px 0' } : {}} value={4}>Source </Tab>
                                                </TabsList>
                                            </div>
                                            <div className='pnalbox'>
                                                <TabPanel value={0}>
                                                    <div className="tabpanel-filter">
                                                        <MultiSelect
                                                            value={selectedCities}
                                                            onChange={(e) => setSelectedCities(e.value)}
                                                            options={cities}
                                                            optionLabel="name"
                                                            filter placeholder="Company"
                                                            maxSelectedLabels={3}
                                                            className="cccccc" />
                                                    </div>

                                                </TabPanel>
                                                <TabPanel value={1}>
                                                    <div className="tabpanel-filter">
                                                        <MultiSelect
                                                            value={selectedCities}
                                                            onChange={(e) => setSelectedCities(e.value)}
                                                            options={cities}
                                                            optionLabel="name"
                                                            filter placeholder="Department"
                                                            maxSelectedLabels={3}
                                                            className="" />
                                                    </div>

                                                </TabPanel>
                                                <TabPanel value={2}>
                                                    <div className="tabpanel-filter">
                                                        <MultiSelect
                                                            value={selectedCities}
                                                            onChange={(e) => setSelectedCities(e.value)}
                                                            options={cities}
                                                            optionLabel="name"
                                                            filter placeholder="Device"
                                                            maxSelectedLabels={3}
                                                            className="" />
                                                    </div>

                                                </TabPanel>
                                                <TabPanel value={3}>
                                                    <div className="tabpanel-filter">
                                                        <MultiSelect
                                                            value={selectedCities}
                                                            onChange={(e) => setSelectedCities(e.value)}
                                                            options={cities}
                                                            optionLabel="name"
                                                            filter placeholder="Locations"
                                                            maxSelectedLabels={3}
                                                            className="" />
                                                    </div>

                                                </TabPanel>
                                                <TabPanel value={4}>
                                                    <div className="tabpanel-filter">
                                                        <MultiSelect
                                                            value={selectedCities}
                                                            onChange={(e) => setSelectedCities(e.value)}
                                                            options={cities}
                                                            optionLabel="name"
                                                            filter placeholder="Source"
                                                            maxSelectedLabels={3}
                                                            className="" />
                                                    </div>

                                                </TabPanel>
                                            </div>
                                        </Tabs>
                                    </Box>
                                    <div className='buttones'>
                                        <Button variant='outlined'>Clear All</Button>
                                        <Button variant='contained'>Apply</Button>
                                    </div>
                                </Box>
                            </div>
                        </div>

                    </Box>
                    <Box className="Shift-box">
                        <div style={{ width: '100%' }}>
                            <DataGrid
                                rows={rows}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: { page: 0, pageSize: 5 },
                                    },
                                }}
                                pageSizeOptions={[5, 10]}
                                checkboxSelection
                            />
                        </div>

                    </Box>
                </div>
            </Box >

        </>
    );
}

export default RecognitionsEntryLogs;