
import { DataGrid } from '@mui/x-data-grid';
import Header from '../Common/Header/Header';
import { Box, Button, TextField } from '@mui/material';
import { MdAccountBalance, MdOutlineKeyboardArrowRight,MdClose } from "react-icons/md";
import '../Styles/exceptionsListStyle.css'
import filterImg from '../Assets/filter.png'
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { useState } from 'react';
import { Tab, TabPanel, Tabs, TabsList } from "@mui/base";
import { MultiSelect } from 'primereact/multiselect';


const columns = [

    {
        field: 'id',
        headerName: 'Emp ID',
        width: 120
    },
    {
        field: 'name',
        headerName: 'Emp Name',
        width: 120
    },
    {
        field: 'exceptiondate',
        headerName: 'Exception Date',
        width: 120,
    },
    {
        field: 'exceptionType',
        headerName: 'Exception Type',
        width: 120
    },
    {
        field: 'status',
        headerName: 'Status',
        width: 120,
    },
    {
        field: 'creationDate',
        headerName: 'Creation Date',
        width: 120,
    },
    {
        field: 'remarks',
        headerName: 'Remarks',
        width: 120,
    },
    {
        field: 'actions',
        headerName: 'Actions',
        width: 120,
    }
];

const rows = [
    { id: 1, name: 'Snow ', updateOn: 'Jon' },
    { id: 2, name: 'Lannister', updateOn: 'Cersei' },
    { id: 3, name: 'Lannister', updateOn: 'Jaime' },
    { id: 4, name: 'Stark', updateOn: 'Arya' },
    { id: 5, name: 'Targaryen', updateOn: 'Daenerys' },
    { id: 6, name: 'Melisandre', updateOn: null },
    { id: 7, name: 'Clifford', updateOn: 'Ferrara' },
    { id: 8, name: 'Frances', updateOn: 'Rossini' },
    { id: 9, name: 'Roxie', updateOn: 'Harvey' },
];

const ExceptionsRegularizationsRequests = () => {
    const [openFilter, setOpenFilter] = useState(false);
    const [value, setValue] = useState(1);
    const [selectedCities, setSelectedCities] = useState(null);

    const onFilterClick = () => {
        setOpenFilter(!openFilter)
    }

    const onFilterHandleclose = () => {
        setOpenFilter(false)

    };
    const cities = [
        { name: 'New York', code: 'NY' },
        { name: 'Rome', code: 'RM' },
        { name: 'London', code: 'LDN' },
        { name: 'Istanbul', code: 'IST' },
        { name: 'Paris', code: 'PRS' }
    ];

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <Box className='Shifthome-mainbox'>

                <div className="Shift-rightbox">
                    <Header />
                    <Box className="Shift-second-head">
                        <div className='hamburg'>
                            <div><MdAccountBalance className=" fs-5" /></div>
                            <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                            <div>Exceptions</div>
                            <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                            <div>Regularization Requests</div>
                        </div>
                    </Box>
                    <Box className='filterDiv'>
                        <div className='fielddate'>
                            <div className='datediv'>
                                <TextField type='date' size='small' />
                            </div>

                        </div>
                        <div className='searchbox'>

                            <div className='search-inputdiv'>
                                <Paper
                                    component="form"
                                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400, borderRadius: '20px' }}
                                >
                                    <InputBase
                                        size='small'
                                        sx={{ ml: 1, flex: 1 }}
                                        placeholder="Search by Employee ID / Name"
                                        inputProps={{ 'aria-label': 'Search by Employee ID / Name' }}
                                    />
                                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                        <SearchIcon />
                                    </IconButton>
                                </Paper>
                            </div>
                            <div className='img-div'>
                                <img onClick={onFilterClick} src={filterImg} alt="img" />
                                <Box className={openFilter ? "openfilterdiv" : "hiddenfilterdiv"}>
                                    <b onClick={onFilterHandleclose}><MdClose className=' fs-4' /></b>
                                    <p>Filters</p>
                                    <Box>
                                        <Tabs className='tabs-box' value={value} onChange={handleChange}>
                                            <div>
                                                <TabsList className="tabslistdiv">
                                                    <Tab className="mangetab" style={value === 0 ? { backgroundColor: '#629cdf', color: 'white', borderRadius: '0 30px 30px 0' } : {}} value={0}>Type </Tab>
                                                    <Tab className="mangetab" style={value === 1 ? { backgroundColor: '#629cdf', color: 'white', borderRadius: '0 30px 30px 0' } : {}} value={1}>Status </Tab>
                                                </TabsList>
                                            </div>
                                            <div className='pnalbox'>
                                                <TabPanel value={0}>
                                                    <div className="tabpanel-filter">
                                                        <MultiSelect
                                                            value={selectedCities}
                                                            onChange={(e) => setSelectedCities(e.value)}
                                                            options={cities}
                                                            optionLabel="name"
                                                            filter placeholder="Type"
                                                            maxSelectedLabels={3}
                                                            className="cccccc" />
                                                    </div>

                                                </TabPanel>
                                                <TabPanel value={1}>
                                                    <div className="tabpanel-filter">
                                                        <MultiSelect
                                                            value={selectedCities}
                                                            onChange={(e) => setSelectedCities(e.value)}
                                                            options={cities}
                                                            optionLabel="name"
                                                            filter placeholder="Status"
                                                            maxSelectedLabels={3}
                                                            className="" />
                                                    </div>

                                                </TabPanel>

                                            </div>
                                        </Tabs>
                                    </Box>
                                    <div className='buttones'>
                                        <Button variant='outlined'>Clear All</Button>
                                        <Button variant='contained'>Apply</Button>
                                    </div>
                                </Box>
                            </div>
                        </div>
                    </Box>
                    <Box className="Shift-box">
                        <div style={{ width: '100%' }}>
                            <DataGrid
                                rows={rows}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: { page: 0, pageSize: 5 },
                                    },
                                }}
                                pageSizeOptions={[5, 10]}
                                checkboxSelection
                            />
                        </div>

                    </Box>
                </div>
            </Box >

        </>
    );
}

export default ExceptionsRegularizationsRequests;