import { Box } from "@mui/material"
import Header from "../Common/Header/Header";
import { MdOutlineKeyboardArrowRight, MdAccountBalance } from "react-icons/md";
import '../Styles/settingAddUserStyle.css'
import { InputText } from "primereact/inputtext";
import { Dropdown } from 'primereact/dropdown';
import { useState } from "react";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import imgLogo from '../Assets/profile.png'
import { IoAddCircle } from "react-icons/io5";


const CloudIntegrationServerConfig = () => {
    const [selectedCity, setSelectedCity] = useState(null);
    const [ingredient, setIngredient] = useState('');

    const cities = [
        { name: 'Employee ID', code: 'NY' },
        { name: 'Employee Name', code: 'RM' },
    ];

    const openFileInput = () => {
        const fileInput = document.getElementById('fileInput');
        fileInput.click();
    };
    return (
        <>
            <Box className='home-mainbox'>
                <div className="rightbox">
                    <Header />
                    <Box className="second-head">
                        <div><MdAccountBalance className=" fs-5" /></div>
                        <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                        <div>Cloud Integration </div>
                        <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                        <div>FTP Server Configuration</div>
                        <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                        <div>Add FTP Server Configuration</div>
                    </Box>

                    <Box className="full-box">
                        <p className="heading-p">Add FTP Server Configuration</p>

                        <div className="selectionBox">

                            <div className="card ">
                                <InputText placeholder="Server IP" />
                            </div>
                            <div className="card ">
                                <InputText placeholder="Port" />
                            </div>
                            <div className="card ">
                                <InputText placeholder="User Name" />
                            </div>
                            <div className="card ">
                                <InputText placeholder="Password" />
                            </div>
                            <div className="card ">
                                <InputText placeholder="Confirm Password" />
                            </div>
                            <div className="card ">
                                <InputText placeholder="Path" />
                            </div>
                            <div className="card">
                                <Dropdown
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    placeholder="Server Type"
                                    className="" />
                            </div>
                        
                        </div>

                        <div className="buttons">
                            <Button className="button1" size="small" label="Submit" severity="info" />
                            <Button className="button2" size="small" label="Cancel" severity="info" outlined />
                        </div>

                    </Box>
                </div>
            </Box >

        </>
    )
}

export default CloudIntegrationServerConfig

