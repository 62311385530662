

import Header from '../Common/Header/Header';
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { MdAccountBalance, MdOutlineKeyboardArrowRight } from "react-icons/md";
import '../Styles/payrollSalaryComponentsStyle.css'
import { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from "primereact/inputswitch";
import { MdDelete } from "react-icons/md";
import { Calendar } from 'primereact/calendar';

// import { Button } from 'primereact/button';

const InputBox = ({ index, onDelete,num }) => {
    const [date, setDate] = useState(null);


    const cities = [
        { name: 'New York', code: 'NY' },
        { name: 'Rome', code: 'RM' },
        { name: 'London', code: 'LDN' },
        { name: 'Istanbul', code: 'IST' },
        { name: 'Paris', code: 'PRS' }
    ];

    const handleDelete = () => {
        onDelete(index); 
    };
    

    return (
        <TableRow>
            <TableCell>
                <div className="StructureOpen">
                    {num}
                </div>
            </TableCell>

            <TableCell>
                <div className="StructureOpen">
                    <InputText placeholder="" />
                </div>
            </TableCell>

            <TableCell>
                <div className="StructureOpen">
                    <Calendar
                        id="buttondisplay"
                        value={date}
                        onChange={(e) => setDate(e.value)}
                        filter placeholder=""
                        showIcon />
                </div>
            </TableCell>


            <TableCell>
                <div className="" onClick={handleDelete}>
                    <MdDelete className=' fs-3 text-danger' />
                </div>
            </TableCell>
        </TableRow>
    );
};

const MasterDataAddHolidayCalendar = () => {
    const [selectedLevel, setSelectedLevel] = useState(null);
    const [date, setDate] = useState(null);
    const [num, setNum] = useState(1); // Initialize num state


    const levels = [
        { name: 'Account' },
        { name: 'Location' },
        { name: 'Company' },
    ];

    const [inputList, setInputList] = useState([]);

    const deleteRow = (index) => {
        setInputList((prevInputList) => {
            const clonedInputList = [...prevInputList];
            clonedInputList.splice(index, 1);
            return clonedInputList;
        });
        setNum((prevNum) => prevNum - 1);
    };

    const onAddBtnClick = () => {
        const newIndex = inputList.length; // Calculate the new index
        setInputList(prevInputList => [
            ...prevInputList,
            <InputBox key={newIndex} index={newIndex} onDelete={deleteRow} num={num + 1} />
        ]);
        setNum(prevNum => prevNum + 1);
    };



    return (
        <>
            <Box className='home-mainbox'>

                <div className="rightbox">
                    <Header />
                    <Box className="second-head">
                        <div className='hamburg'>
                            <div><MdAccountBalance className=" fs-5" /></div>
                            <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                            <div>Master Data</div>
                            <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                            <div>Holiday Calendar</div>
                            <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                            <div>Add Holiday Calendar</div>
                        </div>

                    </Box>

                    <Box className="full-box">
                        <p className="heading-p">Holiday Calendar</p>
                        <div className='selectionBox'>
                            <div className="card">
                                <Dropdown
                                    value={selectedLevel}
                                    onChange={(e) => setSelectedLevel(e.value)}
                                    options={levels}
                                    optionLabel="name"
                                    placeholder="Access Level"
                                    className="" />
                            </div>
                            <div className="card">
                                <InputText placeholder="Holiday Calendar ID" />
                            </div>
                            <div className="card">
                                <InputText placeholder="Holiday Calendar Name" />
                            </div>
                            <div className="card">
                                <div className="DateDiv">
                                    <Calendar
                                        id="buttondisplay"
                                        value={date}
                                        onChange={(e) => setDate(e.value)}
                                        filter placeholder="Effective Date"
                                        showIcon />
                                </div>
                            </div>



                        </div>
                        <TableContainer>
                            <Table style={{width:'600px'}}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell> S.No</TableCell>
                                        <TableCell>Holiday Name</TableCell>
                                        <TableCell>Date</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {inputList.map((input, index) => (
                                        <InputBox key={input.keyVal} index={index} onDelete={deleteRow} num={index + 1} />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className='addmorebtn'>
                            <Button variant='contained' className=' text-white' onClick={onAddBtnClick} fullWidth>Add More </Button>
                        </div>

                        <div className='cancel-submitbtn'>
                            <Button variant='outlined'>Cancel</Button>
                            <Button variant='contained'>Submit</Button>
                        </div>
                    </Box>
                </div>
            </Box >

        </>
    );
}

export default MasterDataAddHolidayCalendar;