import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button } from '@mui/material';
import './deviceEmployeeCopyLinkageModelStyle.css'
import { MdClose } from "react-icons/md";
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { useState } from 'react';



const DeviceEmployeeCopyLinkageModel = ({ openLinkage, linkageHandleClose }) => {
    const [selectedCity, setSelectedCity] = useState(null);

    const cities = [
        { name: 'New York', code: 'NY' },
        { name: 'Rome', code: 'RM' },
        { name: 'London', code: 'LDN' },
        { name: 'Istanbul', code: 'IST' },
        { name: 'Paris', code: 'PRS' }
    ];

    return (
        <div>
            <Modal
                open={openLinkage}
                onClose={linkageHandleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className='Copymodalboxx'>
                    <b onClick={linkageHandleClose}><MdClose className=' fs-4' /></b>
                    <Typography id="Copy-title" variant="h6" component="h2">
                        Copy Employee Relationships
                    </Typography>
                    <div className='copyLinkageBox'>
                        <div className="Copycard">
                            <Dropdown
                                value={selectedCity}
                                onChange={(e) => setSelectedCity(e.value)}
                                options={cities}
                                optionLabel="name"
                                editable placeholder="Select Source Device"
                                className="" />
                        </div>
                        <div className="Copycard">
                            <MultiSelect
                                value={selectedCity}
                                onChange={(e) => setSelectedCity(e.value)}
                                options={cities}
                                optionLabel="name"
                                filter placeholder="Select Destination Device"
                                maxSelectedLabels={3}
                                className="" />
                        </div>
                    </div>

                    <div className='copybtnbox'>
                        <Button variant='contained'>Copy</Button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}
export default DeviceEmployeeCopyLinkageModel