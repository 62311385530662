import { DataGrid } from '@mui/x-data-grid';
import Header from '../Common/Header/Header';
import { Box, Button } from '@mui/material';
import { MdAccountBalance, MdOutlineKeyboardArrowRight, MdClose } from "react-icons/md";
import '../Styles/masterDataEmployeesStyle.css'
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import RecognitionEmployeeImportDataModel from '../Components/RecognitionEmployeeImportDataModel';


const columns = [
    {
        field: 'departmentid',
        headerName: 'Department ID',
        width: 180
    },
    {
        field: 'departmentname',
        headerName: 'Department Name',
        width: 180
    },
    {
        field: 'companyName',
        headerName: 'Company Name',
        width: 180,
    },
    {
        field: 'status',
        headerName: 'Status',
        width: 180,
    },
    {
        field: 'actions',
        headerName: 'Actions',
        width: 180,
    }
];

const rows = [
    { id: 1, name: 'Snow ', updateOn: 'Jon' },
    { id: 2, name: 'Lannister', updateOn: 'Cersei' },
    { id: 3, name: 'Lannister', updateOn: 'Jaime' },
    { id: 4, name: 'Stark', updateOn: 'Arya' },
    { id: 5, name: 'Targaryen', updateOn: 'Daenerys' },
    { id: 6, name: 'Melisandre', updateOn: null },
    { id: 7, name: 'Clifford', updateOn: 'Ferrara' },
    { id: 8, name: 'Frances', updateOn: 'Rossini' },
    { id: 9, name: 'Roxie', updateOn: 'Harvey' },
];

const MasterDataDepartment = () => {
    const [openOptions, setOpenOptions] = useState(false);
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    }
    const onOptionShow = () => {
        setOpenOptions(!openOptions)
    }

    const handleExportExcel = () => {
        // Convert table data to Excel format
        const worksheet = XLSX.utils.json_to_sheet(rows);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        // Generate buffer and create Blob
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Trigger download
        saveAs(blob, 'table_data.xlsx');
    };
    return (
        <>
            <Box className='Shifthome-mainbox'>
                <div className="Shift-rightbox">
                    <Header />
                    <Box className="Shift-second-head">
                        <div className='hamburg'>
                            <div><MdAccountBalance className=" fs-5" /></div>
                            <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                            <div>Master Data</div>
                            <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                            <div>Departments</div>
                        </div>
                        <div className='btnbox'>
                            <Link to={'/master-data-department/adddepartment'}><Button>Add New</Button></Link>

                            <Button onClick={onOptionShow}>Options</Button>
                            <Box className={openOptions ? "visible-options" : "hidden-options"}>
                                <div onClick={handleOpen}>Import Data</div>
                                <div onClick={handleExportExcel}>Export Data</div>
                            </Box>
                            <RecognitionEmployeeImportDataModel open={open} handleClose={handleClose} />
                        </div>
                    </Box>
                    <Box className='filterDiv'>
                        <div className='fielddate'>
                        </div>
                        <div className='searchbox'>
                            <div className='search-inputdiv'>
                                <Paper
                                    component="form"
                                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400, borderRadius: '20px' }}
                                >
                                    <InputBase
                                        size='small'
                                        sx={{ ml: 1, flex: 1 }}
                                        placeholder="Search by Department ID / Name"
                                        inputProps={{ 'aria-label': 'Search by Department ID / Name' }}
                                    />
                                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                        <SearchIcon />
                                    </IconButton>
                                </Paper>
                            </div>
                        </div>
                    </Box>
                    <Box className="Shift-box">
                        <div style={{ width: '100%' }}>
                            <DataGrid
                                rows={rows}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: { page: 0, pageSize: 5 },
                                    },
                                }}
                                pageSizeOptions={[5, 10]}
                                checkboxSelection
                            />
                        </div>
                    </Box>
                </div>
            </Box >

        </>
    );
}

export default MasterDataDepartment;