import { Box } from "@mui/material"
import '../Styles/masterDataRecognitionsFormsCreateFormStyle.css'
import Header from "../Common/Header/Header";
import { MdAccountBalance, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { useState } from "react";
import { MultiSelect } from 'primereact/multiselect';
import 'primereact/resources/themes/lara-light-cyan/theme.css';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import { Link } from "react-router-dom";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import { ReactFormBuilder, ReactFormGenerator } from "react-form-builder2";
import { FormBuilder as FormBuilderIo, Formio, FormEdit } from "react-formio";
import { formIoData } from "../Data/FormData";
import "react-form-builder2/dist/app.css";
import "formiojs/dist/formio.full.css";
// require("formBuilder");
// "formBuilder": "3.4.2",

// const url = `https://safe-springs-353/06.herokuapp.com/api/formdata?cid=`;
// const saveUrl = `https://safe-springs-35306.herokuapp.com/api/formdata?cid`;




const MasterDataRecognitionsFormsCreateForm = () => {
    const [selectedCities, setSelectedCities] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [date, setDate] = useState(null);
    const [val, setVal] = useState('');

    const cities = [
        { name: 'New York', code: 'NY' },
        { name: 'Rome', code: 'RM' },
        { name: 'London', code: 'LDN' },
        { name: 'Istanbul', code: 'IST' },
        { name: 'Paris', code: 'PRS' }
    ];

    const Allcities = [
        { name: 'Employee ID', code: 'NY' },
        { name: 'Employee Name', code: 'RM' },
    ];

    // const [data, setData] = useState([]);
    // const [preview, togglePreview] = useState(false);
    const [formData, setFormData] = useState(formIoData);
    // const [selectedSection, setSelectedSection] = useState("reactFormBuilder");
    // const onPost = (d) => setData(d.task_data);
    // const onToggle = () => togglePreview(!preview);
    const printResult = () => {
        // if (selectedSection === "formIo") {

        Formio.createForm(document.getElementById("formio-result"), {
            components: formData.components
        }).then((form) => {
            console.log(form.component.components);
            form.on("submit", (data) => console.log("submit", data));
            // console.log(document.getElementById("formio-result"));
        });
        // }
    };


    return (
        <>
            <Box className='home-mainbox'>
                <div className="rightbox">
                    <Header />
                    <Box className="createform-head">
                        <div className="createform">
                            <div><MdAccountBalance className=" fs-5" /></div>
                            <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                            <div>Create Form </div>
                        </div>
                        <div className='Formsbtnbox'>
                            <Link to={'/master-data-recognitions-forms'}> <Button size="small">View Forms</Button></Link>
                        </div>
                    </Box>

                    <Box className="full-box">
                        <p className="heading-p">Add Recognition Form </p>

                        <div className="selectionBox">

                            <div className="card ">
                                <InputText placeholder="Form Name" />
                            </div>

                            <div className="card Mandatorydiv">
                                <p>Mandatory</p>
                                <div>
                                    <FormGroup>
                                        <FormControlLabel control={<Switch />} label="" />
                                    </FormGroup>
                                </div>
                            </div>

                        </div>
                        <div>
                            <div className="App">
                                {/* <div className="btn-toolbar">
                                    <button
                                    className={selectedSection === "reactFormBuilder" ? "blue" : "gray"}
                                    onClick={() => setSelectedSection("reactFormBuilder")}
                                    >
                                    React Form Builder
                                    </button>
                                    <button
                                    className={selectedSection === "formIo" ? "blue" : "gray"}
                                    onClick={() => setSelectedSection("formIo")}
                                    >
                                    FormIo
                                    </button>
                                    <button
                                    className={selectedSection === "formBuilderDev" ? "blue" : "gray"}
                                    onClick={() => setSelectedSection("formBuilderDev")}
                                    >
                                    FormBuilder.dev
                                    </button>
                                    <button
                                    className={selectedSection === "formBuilderOnline" ? "blue" : "gray"}
                                    onClick={() => setSelectedSection("formBuilderOnline")}
                                    >
                                    FormBuilder.online
                                    </button>
                                </div> */}
                                {/* {selectedSection === "reactFormBuilder" && (
                                    <div>
                                    <div className="align-left">
                                        <button onClick={onToggle}>Show/hide preview</button>
                                    </div>
                                    {!preview && (
                                        <ReactFormBuilder
                                        url={url}
                                        onPost={onPost}
                                        // editElement={btn}
                                        editMode={true}
                                        // saveUrl={saveUrl}
                                        // showDescription={false}
                                        // toolbarItems={items}
                                        />
                                    )}

                                    {preview && <ReactFormGenerator data={data} answer_data={{}} />}
                                    </div>
                                )} */}
                                {/* {selectedSection === "formIo" && ( */}
                                <div className="FieldTypes">
                                        <p className="p1">Field Types </p>
                                        <p className="p2">Recognition Form Preview </p>
                                    </div>
                                <div>
                                   

                                    <FormBuilderIo
                                        form={formIoData}
                                        // onChange={schema => setFormData(schema)}
                                        onSubmit={(data) => {
                                            console.log(data);
                                        }}
                                        saveForm={(data) => setFormData(data)}
                                        saveText="Save Form"
                                        onSubmitDone={(data) => console.log(data)}
                                    />
                                    <div style={{ display: "none" }}>
                                        <div id="formio-result" />
                                    </div>
                                </div>
                                {/* )} */}
                                {/* {selectedSection === "formBuilderDev" && <div />}
                                {selectedSection === "formBuilderOnline" && <div />} */}
                            </div>
                        </div>

                        <div className="buttons">
                            <Button className="button1" size="small" label="Save Form " severity="info" />
                        </div>

                    </Box>
                </div>
            </Box >

        </>
    )
}

export default MasterDataRecognitionsFormsCreateForm

