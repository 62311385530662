
import { DataGrid } from '@mui/x-data-grid';
import DashboardSidebar from '../Components/Sidebar';
import Header from '../Common/Header/Header';
import { Box, Button, TextField } from '@mui/material';
import { MdAccountBalance, MdOutlineKeyboardArrowRight } from "react-icons/md";
import '../Styles/exceptionsListStyle.css'
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';


const columns = [

    {
        field: 'id',
        headerName: 'Emp ID',
        width: 120
    },
    {
        field: 'name',
        headerName: 'Emp Name',
        width: 120
    },
    {
        field: 'leaveType',
        headerName: 'Leave Type',
        width: 120,
    },
    {

        field: 'encashmentAvailable',
        headerName: 'Encashment Available',
        width: 120
    },
    {
        field: 'encashmentApplied',
        headerName: 'Encashment Applied',
        width: 120,
    },
    {
        field: 'count',
        headerName: 'Count',
        width: 120,
    },
    {
        field: 'appliedDate',
        headerName: 'Applied Date',
        width: 120,
    },
    {
        field: 'status',
        headerName: 'Status',
        width: 120,
    },
    {
        field: 'action',
        headerName: 'Action',
        width: 120,
    },
];

const rows = [
    { id: 1, name: 'Snow ', updateOn: 'Jon' },
    { id: 2, name: 'Lannister', updateOn: 'Cersei' },
    { id: 3, name: 'Lannister', updateOn: 'Jaime' },
    { id: 4, name: 'Stark', updateOn: 'Arya' },
    { id: 5, name: 'Targaryen', updateOn: 'Daenerys' },
    { id: 6, name: 'Melisandre', updateOn: null },
    { id: 7, name: 'Clifford', updateOn: 'Ferrara' },
    { id: 8, name: 'Frances', updateOn: 'Rossini' },
    { id: 9, name: 'Roxie', updateOn: 'Harvey' },
];

const handleExportExcel = () => {
    // Convert table data to Excel format
    const worksheet = XLSX.utils.json_to_sheet(rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Generate buffer and create Blob
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    // Trigger download
    saveAs(blob, 'table_data.xlsx');
};
const LeaveEncashment = () => {
    return (
        <>
            <Box className='Shifthome-mainbox'>
                {/* <div className="Shift-sidebarbox">
                    <DashboardSidebar />
                </div> */}

                <div className="Shift-rightbox">
                    <Header />
                    <Box className="Shift-second-head">
                        <div className='hamburg'>
                            <div><MdAccountBalance className=" fs-5" /></div>
                            <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                            <div>Leaves</div>
                            <div><MdOutlineKeyboardArrowRight /></div>
                            <div>Leave Encashment Requests</div>
                        </div>
                        <div className='btnbox'>
                            <Button onClick={handleExportExcel}  className='btn'>Export Data</Button>
                        </div>
                    </Box>
                    <Box className='filterDiv'>
                        <div className='fielddate'>
                        </div>
                        <div className='searchbox'>
                            <div className='search-inputdiv'>
                                <Paper
                                    component="form"
                                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400, borderRadius: '20px' }}
                                >
                                    <InputBase
                                        size='small'
                                        sx={{ ml: 1, flex: 1 }}
                                        placeholder="Search by Employee ID / Name"
                                        inputProps={{ 'aria-label': 'Search by Employee ID / Name' }}
                                    />
                                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                        <SearchIcon />
                                    </IconButton>
                                </Paper>
                            </div>
                        </div>
                    </Box>
                    <Box className="Shift-box">
                        <div style={{ width: '100%' }}>
                            <DataGrid
                                rows={rows}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: { page: 0, pageSize: 5 },
                                    },
                                }}
                                pageSizeOptions={[5, 10]}
                                checkboxSelection
                            />
                        </div>

                    </Box>
                </div>
            </Box >

        </>
    );
}

export default LeaveEncashment;