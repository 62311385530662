import './headerStyle.css'
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import imgProfile from '../../Assets/profile.png'
import { Box } from '@mui/material';
import { MdOutlineAccountCircle, MdOutlineSettings } from "react-icons/md";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useState } from 'react';
import { Link } from 'react-router-dom';

const Header = () => {

  const [open, setOpen] = useState(false);

  const onProfileToggleOpen = () => {
    setOpen(!open)
  }
  return (
    <>
      <div className="headerbox">
        <div className='heading-div'>
          <p>Attendance</p>
        </div>
        <div className='profilebox' onClick={onProfileToggleOpen}>
          <div>
            <img src={imgProfile} alt="img" />
          </div>
          <div>
            <p>Mohd Bilal</p>
          </div>
          <div>
            <p><MdOutlineKeyboardArrowDown className=' fs-4' /></p>
          </div>
          <Box className={open ? "visible-profile-popup" : "hidden-profile-popup"}>
            <Link to={'/homeprofile-profile'}>
              <div><MdOutlineAccountCircle className=' fs-4 me-3' />My Profile</div>
            </Link>
            <Link to={'/homeprofile-change-password'}>
              <div><MdOutlineSettings className=' fs-4 me-3' /> Change Password</div>
            </Link>
            <Link to={'/login'}>
              <div><ExitToAppIcon className=' fs-4 me-3' />Logout</div>
            </Link>
          </Box>
        </div>
      </div>

    </>
  )
}

export default Header
