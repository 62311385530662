
import { Box } from "@mui/material"
import Header from "../Common/Header/Header";
import { MdOutlineKeyboardArrowRight, MdAccountBalance } from "react-icons/md";
import '../Styles/settingAddUserStyle.css'
import { InputText } from "primereact/inputtext";
import { Dropdown } from 'primereact/dropdown';
import { useState } from "react";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import imgLogo from '../Assets/profile.png'
import { IoAddCircle } from "react-icons/io5";


const CloudIntegrationCreateWebhooks = () => {
    const [selectedCity, setSelectedCity] = useState(null);
    const [ingredient, setIngredient] = useState('');

    const cities = [
        { name: 'Employee ID', code: 'NY' },
        { name: 'Employee Name', code: 'RM' },
    ];

    const openFileInput = () => {
        const fileInput = document.getElementById('fileInput');
        fileInput.click();
    };
    return (
        <>
            <Box className='home-mainbox'>
                <div className="rightbox">
                    <Header />
                    <Box className="second-head">
                        <div><MdAccountBalance className=" fs-5" /></div>
                        <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                        <div>Cloud Integration </div>
                        <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                        <div>Webhooks</div>
                        <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                        <div>Add New Webhook</div>

                    </Box>

                    <Box className="full-box">
                        <p className="heading-p">Create Webhook</p>

                        <div className="selectionBox">

                            <div className="card ">
                                <InputText placeholder="Webhooks Name" />
                            </div>
                            <div className="card">
                                <Dropdown
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    placeholder="Type"
                                    className="" />
                            </div>

                            <div className="card ">
                                <InputText placeholder="Endpoint URL" />
                            </div>

                            <div className="card ">
                                <Button style={{borderRadius:'4px',width:'150px',color:'white'}} className="" size="small" label="Validate" severity="info" />
                            </div>

                        </div>

                    </Box>
                </div>
            </Box >

        </>
    )
}

export default CloudIntegrationCreateWebhooks

