
import Header from '../Common/Header/Header';
import { Box } from '@mui/material';
import { MdAccountBalance, MdOutlineKeyboardArrowRight } from "react-icons/md";
import '../Styles/journeyPlansStyle.css'
import { Form } from 'react-bootstrap';


const PayrollRun = () => {
    return (
        <>
            <Box className='Shifthome-mainbox'>
                <div className="Shift-rightbox">
                    <Header />
                    <Box className="Shift-second-head">
                        <div className='hamburg'>
                            <div><MdAccountBalance className=" fs-5" /></div>
                            <div><MdOutlineKeyboardArrowRight className=" fs-5"/></div>
                            <div>Payroll</div>
                            <div><MdOutlineKeyboardArrowRight className=" fs-5"/></div>
                            <div>Run Payroll</div>
                        </div>

                    </Box>
                    <Box className='filterDiv'>
                        <div className='selectbox'>
                            <Form.Select aria-label="Default select example">
                                <option>Company Name</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </Form.Select>

                        </div>
                        <div className='selectbox'>
                            <Form.Select aria-label="Default select example">
                                <option>Year</option>
                                <option value="1">2024</option>
                                <option value="2">2023</option>
                                <option value="3">2022</option>
                                <option value="3">2021</option>
                                <option value="3">2020</option>
                            </Form.Select>
                        </div>
                        <div className='selectbox'>
                            <Form.Select aria-label="Default select example">
                                <option>Month</option>
                                <option value="1">April</option>
                                <option value="2">March</option>
                                <option value="3">Febuary</option>
                                <option value="3">January</option>
                            </Form.Select>
                        </div>

                    </Box>

                </div>
            </Box >

        </>
    );
}

export default PayrollRun;