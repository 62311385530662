import Header from '../Common/Header/Header';
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { MdAccountBalance, MdOutlineKeyboardArrowRight } from "react-icons/md";
import '../Styles/payrollSalaryComponentsStyle.css'
import { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from "primereact/inputswitch";
import { MdDelete } from "react-icons/md";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
// import { Button } from 'primereact/button';

const InputBox = ({ keyVal, index, onDelete }) => {
    const [selectedCity, setSelectedCity] = useState(null);
    const [checked, setChecked] = useState(false);

    const cities = [
        { name: 'New York', code: 'NY' },
        { name: 'Rome', code: 'RM' },
        { name: 'London', code: 'LDN' },
        { name: 'Istanbul', code: 'IST' },
        { name: 'Paris', code: 'PRS' }
    ];

    const handleDelete = () => {
        onDelete(index);
    };


    return (
        <TableRow>
            <TableCell>
                <div className="StructureOpen">
                    <Dropdown
                        value={selectedCity}
                        onChange={(e) => setSelectedCity(e.value)}
                        options={cities}
                        optionLabel="name"
                        editable placeholder=""
                        className="" />
                </div>
            </TableCell>

            <TableCell>
                <div className="StructureOpen">
                    <Dropdown
                        value={selectedCity}
                        onChange={(e) => setSelectedCity(e.value)}
                        options={cities}
                        optionLabel="name"
                        editable placeholder=""
                        className="" />
                </div>
            </TableCell>
            <TableCell>
                <div className="StructureOpen">
                    <Dropdown
                        value={selectedCity}
                        onChange={(e) => setSelectedCity(e.value)}
                        options={cities}
                        optionLabel="name"
                        editable placeholder=""
                        className="" />
                </div>
            </TableCell>
            <TableCell>
                <div className="">
                    <InputSwitch checked={checked} onChange={(e) => setChecked(e.value)} />
                </div>
            </TableCell>
            <TableCell>
                <div className="StructureOpen">
                    <Dropdown
                        value={selectedCity}
                        onChange={(e) => setSelectedCity(e.value)}
                        options={cities}
                        optionLabel="name"
                        editable placeholder=""
                        className="" />
                </div>
            </TableCell>
            <TableCell>
                <div className="StructureOpen">
                    <Dropdown
                        value={selectedCity}
                        onChange={(e) => setSelectedCity(e.value)}
                        options={cities}
                        optionLabel="name"
                        editable placeholder=""
                        className="" />
                </div>
            </TableCell>

            <TableCell>
                <div className="" onClick={handleDelete}>
                    <MdDelete className=' fs-3 text-danger' />
                </div>
            </TableCell>
        </TableRow>
    );
};

const PayrollSalaryAddBonusTemplates = () => {

    const [inputList, setInputList] = useState([]);

    const deleteRow = (index) => {
        setInputList((prevInputList) => prevInputList.filter((_, i) => i !== index));
    };

    const onAddBtnClick = (event) => {
        setInputList((prevInputList) => [...prevInputList, <InputBox key={prevInputList.length} index={prevInputList.length} onDelete={deleteRow} />]);
    };



    return (
        <>
            <Box className='Shifthome-mainbox'>

                <div className="Shift-rightbox">
                    <Header />
                    <Box className="Shift-second-head">
                        <div className='hamburg'>
                            <div><MdAccountBalance className=" fs-5" /></div>
                            <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                            <div>Payroll</div>
                            <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                            <div>Bonus Templates</div>
                            <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                            <div>Add Templates</div>
                        </div>

                    </Box>

                    <Box className="Shift-box">
                        <p className="heading-p">Bonus Templates</p>
                        <div className=' d-flex align-items-center m-2'>
                            <div className="m-1">
                                <InputText placeholder="Name" />
                            </div>
                            <div className="m-1">
                                <InputText placeholder="Display ID" />
                            </div>
                            <div className="card Mandatorydiv">
                                <p>Status</p>
                                <div>
                                    <FormGroup>
                                        <FormControlLabel control={<Switch />} label="" />
                                    </FormGroup>
                                </div>
                            </div>
                        </div>
                        <TableContainer>
                            <Table>
                                <TableHead style={{backgroundColor:'lightblue'}}>
                                    <TableRow>
                                        <TableCell> Bonus Components</TableCell>
                                        <TableCell>Frequency</TableCell>
                                        <TableCell>Payable Month</TableCell>
                                        <TableCell>Part Of CTC</TableCell>
                                        <TableCell>Percentage or Fixed</TableCell>
                                        <TableCell>Percentage/Amount</TableCell>
                                        <TableCell>Action</TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {inputList.map((input, index) => (
                                        <InputBox key={input.keyVal} index={index} onDelete={deleteRow} />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className='addmorebtn'>
                            <Button onClick={onAddBtnClick} fullWidth>Add More </Button>
                        </div>

                        <div className='cancel-submitbtn'>
                            <Button variant='outlined'>Cancel</Button>
                            <Button variant='contained'>Submit</Button>
                        </div>
                    </Box>
                </div>
            </Box >

        </>
    );
}

export default PayrollSalaryAddBonusTemplates;