import { Box } from "@mui/material"
import Header from "../Common/Header/Header";
import { MdOutlineKeyboardArrowRight, MdAccountBalance } from "react-icons/md";
import '../Styles/settingAddUserStyle.css'
import { InputText } from "primereact/inputtext";
import { Dropdown } from 'primereact/dropdown';
import { useState } from "react";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import imgLogo from '../Assets/profile.png' 
import { IoAddCircle } from "react-icons/io5";


const SettingAddUser = () => {
    const [selectedCity, setSelectedCity] = useState(null);
    const [ingredient, setIngredient] = useState('');

    const cities = [
        { name: 'Employee ID', code: 'NY' },
        { name: 'Employee Name', code: 'RM' },
    ];

    const openFileInput = () => {
        const fileInput = document.getElementById('fileInput');
        fileInput.click();
    };
    return (
        <>
            <Box className='home-mainbox'>
                <div className="rightbox">
                    <Header />
                    <Box className="second-head">
                        <div><MdAccountBalance className=" fs-5" /></div>
                        <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                        <div>Settings </div>
                        <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                        <div>Users</div>
                        <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                        <div>Add User</div>

                    </Box>

                    <Box className="full-box">
                        <p className="heading-p">Personal Details</p>

                        <div className="selectionBox">

                            <div className='card'>
                                <input
                                    type="file"
                                    id="fileInput"
                                    style={{ display: 'none' }}
                                />
                                <p className="iconplus"><IoAddCircle className=" fs-4 text-primary"/></p>
                                <img style={{width:"80px" ,borderRadius:'50%',cursor: 'pointer'}} src={imgLogo} onClick={openFileInput} />

                            </div>
                            <div className='card'></div>

                            <div className="card ">
                                <InputText placeholder="First Name" />
                            </div>
                            <div className="card ">
                                <InputText placeholder="Last Name" />
                            </div>
                            <div className="card ">
                                <InputText placeholder="Email" />
                            </div>
                            <div className="card ">
                                <InputText placeholder="Phone Number" />
                            </div>

                            <div className="card">
                                <div className="d-flex flex-wrap gap-3 align-items-center">
                                    <label>Role : </label>
                                    <div className="d-flex align-items-center">
                                        <RadioButton
                                            inputId="ingredient1"
                                            name="pizza" value="Admin"
                                            onChange={(e) => setIngredient(e.value)}
                                            checked={ingredient === 'Admin'}
                                        />
                                        <label htmlFor="ingredient1" className="ms-2">Admin</label>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <RadioButton
                                            inputId="ingredient2"
                                            name="pizza" value="Normal"
                                            onChange={(e) => setIngredient(e.value)}
                                            checked={ingredient === 'Normal'}
                                        />
                                        <label htmlFor="ingredient2" className="ms-2">Normal</label>
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <Dropdown
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    placeholder="User Type"
                                    className="" />
                            </div>
                            <div className="card">
                                <Dropdown
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    editable placeholder="Choose Company"
                                    className="" />
                            </div>
                            <div className="card">
                                <Dropdown
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    editable placeholder="Time Zone"
                                    className="" />
                            </div>

                        </div>

                        <div className="buttons">
                            <Button className="button1" size="small" label="Submit" severity="info" />
                            <Button className="button2" size="small" label="Cancel" severity="info" outlined />
                        </div>

                    </Box>
                </div>
            </Box >

        </>
    )
}

export default SettingAddUser

