
import { DataGrid } from '@mui/x-data-grid';
import Header from '../Common/Header/Header';
import { Box, Button, TextField } from '@mui/material';
import { MdAccountBalance, MdOutlineKeyboardArrowRight,MdSearch } from "react-icons/md";
import '../Styles/journeyPlansStyle.css'
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { Link } from 'react-router-dom';


const columns = [
    
    {
        field: 'jobname',
        headerName: 'Job Name',
        width: 170,
    },
    {
        field: 'reportName',
        headerName: 'Report Name',
        width: 170
    },
    {
        field: 'frequency',
        headerName: 'Frequency',
        width: 170
    },
    {
        field: 'scheduledTime',
        headerName: 'Scheduled Time',
        width: 170
    },
    {
        field: 'emailto',
        headerName: 'Email to',
        width: 170
    },
    {
        field: 'FTP',
        headerName: 'FTP',
        width: 170
    },
    {
        field: 'Status',
        headerName: 'Status',
        width: 170,
    },  {
        field: 'action',
        headerName: 'Action',
        width: 170,
    }
];

const rows = [
    { id: 1, jobname: 'Muster Roll ', reportName: 'Muster Report',frequency:'Daily',scheduledTime:'06:00',emailto:'abcd@gmail.com',FTP:'NO',Status:'Active' },
    { id: 2, jobname: 'Test ', reportName: 'Recognitions Logs',frequency:'Daily presentday',scheduledTime:'00:00',emailto:'asafebcd@gmail.com',FTP:'NO',Status:'Active' },
    { id: 3, jobname: 'Muster Roll ', reportName: 'Muster Report',frequency:'Daily',scheduledTime:'06:00',emailto:'abcd@gmail.com',FTP:'NO',Status:'Active' },
    { id: 4, jobname: 'Muster Roll ', reportName: 'Muster Report',frequency:'Daily',scheduledTime:'06:00',emailto:'abcd@gmail.com',FTP:'NO',Status:'Active' },
 
];

const CloudIntegrationScheduledJobs = () => {
    return (
        <>
            <Box className='Shifthome-mainbox'>

                <div className="Shift-rightbox">
                    <Header />
                    <Box className="Shift-second-head">
                        <div className='hamburg'>
                            <div><MdAccountBalance className=" fs-5" /></div>
                            <div><MdOutlineKeyboardArrowRight className=" fs-5"/></div>
                            <div>Cloud Integration </div>
                            <div><MdOutlineKeyboardArrowRight className=" fs-5"/></div>
                            <div>Scheduled Jobs</div>
                        </div>
                        <div className='btnbox'>
                        <Link to={'/cloud-integration-scheduled-jobs/add'}> <Button className='btn'>Add New</Button></Link>
                        </div>
                    </Box>
                    <Box className='filterDiv'>
                        <div className='fielddate'>
                        </div>
                        <div className='searchbox'>
                            <div className='search-inputdiv'>
                                <Paper
                                    component="form"
                                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400, borderRadius: '20px' }}
                                >
                                    <InputBase
                                        size='small'
                                        sx={{ ml: 1, flex: 1 }}
                                        placeholder="Search by Job Name"
                                        inputProps={{ 'aria-label': 'Search by Job Name' }}
                                    />
                                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                        <SearchIcon />
                                    </IconButton>
                                </Paper>
                            </div>
                        </div>

                    </Box>
                    <Box className="Shift-box">
                        <div style={{ width: '100%' }}>
                            <DataGrid
                                rows={rows}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: { page: 0, pageSize: 5 },
                                    },
                                }}
                                pageSizeOptions={[5, 10]}
                                checkboxSelection
                            />
                        </div>

                    </Box>
                </div>
            </Box >

        </>
    );
}

export default CloudIntegrationScheduledJobs;