
import { DataGrid } from '@mui/x-data-grid';
import DashboardSidebar from '../../Components/Sidebar';
import Header from '../../Common/Header/Header';
import { Box, Button, TextField } from '@mui/material';
import { MdAccountBalance, MdOutlineKeyboardArrowRight,MdSearch } from "react-icons/md";
import '../../Styles/journeyPlansStyle.css'
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';


const columns = [
    {
        field: 'entity',
        headerName: 'Entity',
        width: 170
    },
    {
        field: 'entityName',
        headerName: 'Entity Name',
        width: 170,
    },
    {
        field: 'method',
        headerName: 'RuleType',
        width: 170
    },
    {
        field: 'userID',
        headerName: 'User ID',
        width: 170
    },
    {
        field: 'userType',
        headerName: 'User Type',
        width: 170,
    },
      {
        field: 'accessOn',
        headerName: 'Access On',
        width: 170,
    },
    {
      field: 'ip',
      headerName: 'IP',
      width: 170,
  }
];

const rows = [
    { id: 1, name: 'Snow ', updateOn: 'Jon' },
    { id: 2, name: 'Lannister', updateOn: 'Cersei' },
    { id: 3, name: 'Lannister', updateOn: 'Jaime' },
    { id: 4, name: 'Stark', updateOn: 'Arya' },
    { id: 5, name: 'Targaryen', updateOn: 'Daenerys' },
    { id: 6, name: 'Melisandre', updateOn: null },
    { id: 7, name: 'Clifford', updateOn: 'Ferrara' },
    { id: 8, name: 'Frances', updateOn: 'Rossini' },
    { id: 9, name: 'Roxie', updateOn: 'Harvey' },
];

const ReportSystemUsageLogs = () => {
    return (
        <>
            <Box className='Shifthome-mainbox'>
                {/* <div className='sidebarbox'>
                    <DashboardSidebar />
                </div> */}

                <div className="Shift-rightbox">
                    <Header />
                    <Box className="Shift-second-head">
                        <div className='hamburg'>
                            <div><MdAccountBalance className=" fs-5" /></div>
                            <div><MdOutlineKeyboardArrowRight className=" fs-5"/></div>
                            <div>Reports</div>
                            <div><MdOutlineKeyboardArrowRight className=" fs-5"/></div>
                            <div>System Usage Logs</div>
                        </div>
                        <div className='btnbox'>
                            <Button className='btn'>Export Data</Button>
                        </div>
                    </Box>
                    <Box className='filterDiv'>
                        <div className='fielddate'>
                        </div>
                        <div className='searchbox'>
                            <div className='search-inputdiv'>
                                <Paper
                                    component="form"
                                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400, borderRadius: '20px' }}
                                >
                                    <InputBase
                                        size='small'
                                        sx={{ ml: 1, flex: 1 }}
                                        placeholder="Search by Entity User ID / User Type"
                                        inputProps={{ 'aria-label': 'Search by Entity User ID / User Type' }}
                                    />
                                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                        <SearchIcon />
                                    </IconButton>
                                </Paper>
                            </div>
                        </div>

                    </Box>
                    <Box className="Shift-box">
                        <div style={{ width: '100%' }}>
                            <DataGrid
                                rows={rows}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: { page: 0, pageSize: 5 },
                                    },
                                }}
                                pageSizeOptions={[5, 10]}
                                checkboxSelection
                            />
                        </div>

                    </Box>
                </div>
            </Box >

        </>
    );
}

export default ReportSystemUsageLogs;