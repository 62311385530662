import { Box } from "@mui/material"
import DashboardSidebar from "../../Components/Sidebar";
import '../../Styles/reportMonthlyPerformanceRegister.css'
import Header from "../../Common/Header/Header";
import { MdAccountBalance, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { useState } from "react";
import { MultiSelect } from 'primereact/multiselect';
import 'primereact/resources/themes/lara-light-cyan/theme.css';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';



const ReportAbsentees = () => {
    const [selectedCities, setSelectedCities] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [date, setDate] = useState(null);

    const cities = [
        { name: 'New York', code: 'NY' },
        { name: 'Rome', code: 'RM' },
        { name: 'London', code: 'LDN' },
        { name: 'Istanbul', code: 'IST' },
        { name: 'Paris', code: 'PRS' }
    ];

    const Allcities = [
        { name: 'Employee ID', code: 'NY' },
        { name: 'Employee Name', code: 'RM' },
    ];


    return (
        <>
            <Box className='home-mainbox'>
                {/* <div className='sidebarbox'>
                    <DashboardSidebar />
                </div> */}

                <div className="rightbox">
                    <Header />
                    <Box className="second-head">
                        <div><MdAccountBalance className=" fs-5" /></div>
                        <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                        <div>Reports</div>
                        <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                        <div>Absentees Report</div>
                    </Box>

                    <Box className="full-box">
                        <p className="heading-p">Absentees Report</p>

                        <div className="selectionBox">
                            <div className="card">
                                <MultiSelect
                                    value={selectedCities}
                                    onChange={(e) => setSelectedCities(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    filter placeholder="Location"
                                    maxSelectedLabels={3}
                                    className="" />
                            </div>
                            <div className="card">
                                <MultiSelect
                                    value={selectedCities}
                                    onChange={(e) => setSelectedCities(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    filter placeholder="Company"
                                    maxSelectedLabels={3}
                                    className="" />
                            </div>
                            <div className="card">
                                <MultiSelect
                                    value={selectedCities}
                                    onChange={(e) => setSelectedCities(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    filter placeholder="Department"
                                    maxSelectedLabels={3}
                                    className="" />
                            </div>
                            <div className="card">
                                <MultiSelect
                                    value={selectedCities}
                                    onChange={(e) => setSelectedCities(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    filter placeholder="Designation"
                                    maxSelectedLabels={3}
                                    className="" />
                            </div>
                            <div className="card">
                                <Dropdown
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={Allcities}
                                    optionLabel="name"
                                    placeholder="Sort By"
                                    className="" />
                            </div>

                        </div>

                        <div className="buttons">
                            <Button className="button1" size="small" label="Download Report" severity="info" />
                            <Button className="button2" size="small" label="Cancel" severity="info" outlined />
                        </div>

                    </Box>
                </div>
            </Box >

        </>
    )
}

export default ReportAbsentees

