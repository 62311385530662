
import { Box, Button, TextField } from "@mui/material"
import DashboardSidebar from "../Components/Sidebar";
import Header from "../Common/Header/Header";
import { MdOutlineKeyboardArrowRight, MdAccountBalance } from "react-icons/md";
import '../Styles/helpReportAnIssueStyle.css'



const HelpReportAnIssue = () => {

    return (
        <>
            <Box className='home-mainbox'>
                {/* <div className="sidebarbox" >
                    <DashboardSidebar />
                </div> */}

                <div className="rightbox">
                    <Header />
                    <Box className="second-head">
                        <div className='hamburg'>
                            <div><MdAccountBalance className=" fs-5" /></div>
                            <div><MdOutlineKeyboardArrowRight className=" fs-5"/></div>
                            <div>Report</div>
                            <div><MdOutlineKeyboardArrowRight className=" fs-5"/></div>
                            <div>Help An Issue</div>
                        </div>
                    </Box>

                    <Box className='reportbox'>
                        <div className="tagline">Report An Issue</div>
                        <div>
                            <TextField fullWidth placeholder="Subject" />
                        </div>
                        <div>
                            <TextField fullWidth placeholder="CC" />
                        </div>
                        <div>
                            <TextField fullWidth placeholder="Message" />
                        </div>
                        <div className="btnboxs">
                            <Button variant="contained">Submit</Button>
                            <Button variant="outlined">Cancel</Button>
                        </div>
                    </Box>

                </div>
            </Box >

        </>
    )
}

export default HelpReportAnIssue

