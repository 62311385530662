
import { Box } from "@mui/material"
import '../Styles/dashboardStyle.css'
import Header from "../Common/Header/Header";
import { MdAccountBalance, MdOutlineKeyboardArrowRight, MdLogout } from "react-icons/md";
import { MultiSelect } from "primereact/multiselect";
import { useState } from "react";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { GoSearch, GoGift } from "react-icons/go";
import { HiOutlineUserGroup } from "react-icons/hi";
import { ImCheckboxChecked } from "react-icons/im";
import { TbDeviceDesktopAnalytics } from "react-icons/tb";
import { BiError } from "react-icons/bi";
import { FcAlarmClock } from "react-icons/fc";
import { FaUmbrellaBeach } from "react-icons/fa6";
import { IoMdCalendar } from "react-icons/io";
import CanvasJSReact from '@canvasjs/react-charts';
import { Link, useNavigate } from "react-router-dom";

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const Dashboard = () => {
    const [selectedCities, setSelectedCities] = useState(null);
    const [date, setDate] = useState(null);
    const navigate = useNavigate();

    const cities = [
        { name: 'New York', code: 'NY' },
        { name: 'Rome', code: 'RM' },
        { name: 'London', code: 'LDN' },
        { name: 'Istanbul', code: 'IST' },
        { name: 'Paris', code: 'PRS' }
    ];

    const options = {
        animationEnabled: true,
        title: {
            text: ""
        },
        axisX: {
            valueFormatString: "DD-MMM"
        },
        axisY: {
            title: "Employees",
            // prefix: "$"
        },
        data: [{
            // yValueFormatString: "$#,###",
            // xValueFormatString: "MMMM",
            // type: "spline",
            dataPoints: [
                // { x: new Date(2024, 0), y: 0 },
                { x: new Date(2024, 1), y: 1 },
            ]
        }]
    };

    const onTotalEmployees = () => {
        navigate('/device-employee-relationship')
    }

    return (
        <>
            <Box className='home-mainbox'>

                <div className="rightbox">
                    <Header />
                    <Box className="second-head">
                        <div><MdAccountBalance className=" fs-5" /></div>
                        <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                        <div>Dashboard</div>
                    </Box>

                    <Box className="fullbox">
                        <div className="dashboardboxdiv">
                            <div className="dashboard-card">
                                <Calendar
                                    id="buttondisplay"
                                    value={date}
                                    onChange={(e) => setDate(e.value)}
                                    filter placeholder="Date"
                                    showIcon />
                            </div>
                            <div className="dashboard-card">
                                <MultiSelect
                                    value={selectedCities}
                                    onChange={(e) => setSelectedCities(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    filter placeholder="Location"
                                    maxSelectedLabels={3}
                                    className="" />
                            </div>
                            <div className="dashboard-card">
                                <MultiSelect
                                    value={selectedCities}
                                    onChange={(e) => setSelectedCities(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    filter placeholder="Company"
                                    maxSelectedLabels={3}
                                    className="" />
                            </div>
                            <div className="dashboard-card">
                                <MultiSelect
                                    value={selectedCities}
                                    onChange={(e) => setSelectedCities(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    filter placeholder="Department"
                                    maxSelectedLabels={3}
                                    className="" />
                            </div>
                            <div className="dashboard-card">
                                <MultiSelect
                                    value={selectedCities}
                                    onChange={(e) => setSelectedCities(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    filter placeholder="Shifts"
                                    maxSelectedLabels={3}
                                    className="" />
                                <Button icon={<GoSearch className=" fs-5" />} className="btnsearch" />
                            </div>
                            {/* <div className="p-inputgroup flex-1">
                                <InputText placeholder="Keyword" />
                               
                            </div> */}
                        </div>

                    </Box>

                    <Box className="dashboard-info">
                        <div className="dashboard-totalemployees">
                            <div onClick={onTotalEmployees}>
                                <p><HiOutlineUserGroup className="emp-icon fs-2" /></p>
                                <p className="ptags">Total Employees</p>
                                <p className="ptags-empnumber">107</p>
                            </div>
                        </div>
                        <div className="dashboard-attendance">
                            <p className="dashboard-heading">Attendance</p>
                            <div className="attendance-box">
                                <div>
                                    <Link to={'/recognitions-employees'}>
                                        <p><ImCheckboxChecked className="checkedin-icon fs-2" /></p>
                                        <p className="ptags">Checked In</p>
                                        <p className="ptags-checkedinnumber">0</p>
                                    </Link>
                                </div>
                                <div>
                                    <Link to={'/master-data-holidaycalendar'}>
                                        <p><GoGift className="holiday-icon fs-2" /></p>
                                        <p className="ptags">Holiday</p>
                                        <p className="ptags-holidaynumber">0</p>
                                    </Link>
                                </div>
                                <div>
                                    <Link to={'/report-all-exceptions'}>
                                        <p><TbDeviceDesktopAnalytics className="miscount-icon fs-2" /></p>
                                        <p className="ptags">Mis Count</p>
                                        <p className="ptags-miscountnumber">0</p>
                                    </Link>
                                </div>
                                <div>
                                    <Link to={'/report-absentees'}>
                                        <p><BiError className="notchecked-icon fs-2" /></p>
                                        <p className="ptags">Not Checked In</p>
                                        <p className="ptags-notcheckednumber">107</p>
                                    </Link>
                                </div>

                                <div>
                                    <Link to={'/report-shorthours'}>
                                        <p><FcAlarmClock className="shorthours-icon fs-2" /></p>
                                        <p className="ptags">Short Hours Count</p>
                                        <p className="ptags-shorthoursnumber">0</p>
                                    </Link>
                                </div>
                                <div>
                                    <Link to={'/leave-transactions'}>
                                        <p><FaUmbrellaBeach className="onleave-icon fs-2" /></p>
                                        <p className="ptags">On Leave</p>
                                        <p className="ptags-onleavenumber">0</p>
                                    </Link>
                                </div>

                                <div>
                                    <Link to={'/shift-schedule'}>
                                        <p><IoMdCalendar className="weeklyoff-icon fs-2" /></p>
                                        <p className="ptags">Weekly Off</p>
                                        <p className="ptags-weeklyoffnumber">0</p>
                                    </Link>
                                </div>
                                <div>
                                    <Link to={'/recognitions-employees'}>
                                        <p><MdLogout className="checked-icon fs-2" /></p>
                                        <p className="ptags">Checked</p>
                                        <p className="ptags-checkednumber">0</p>
                                    </Link>
                                </div>
                            </div>


                        </div>
                    </Box>

                    <Box className="dashboard-more">
                        <div className="canvas-source">
                            <p className="canvas-source-heading">On Time Check In</p>
                            <div className="canvas-jschart">
                                <CanvasJSChart options={options} />
                            </div>

                        </div>
                        <div className="canvas-source">
                            <p className="canvas-source-heading">Overtime</p>
                            <div className="canvas-jschart">
                                <CanvasJSChart options={options} />
                            </div>
                        </div>

                        <div className="attendance-source">
                            <p className="attendance-source-heading">Attendance Source</p>
                            <div className="attendancesource-doublediv">
                                <div>
                                    <p className="doubleptag">Device Check Ins</p>
                                    <p className="doubleumptag">0</p>
                                </div>
                                <div>
                                    <p className="doubleptag">App Check Ins</p>
                                    <p className="doubleumptag">0</p>
                                </div>
                                <div>
                                    <p className="doubleptag">Active Devices</p>
                                    <p className="doubleumptag">2</p>
                                </div>
                                <div>
                                    <p className="doubleptag">Inactive Devices</p>
                                    <p className="doubleumptag">0</p>
                                </div>
                            </div>
                        </div>
                    </Box>

                    <Box className="dashboard-more">
                        <div className="exceptions-pending">
                            <p className="exceptions-pending-heading">Exceptions</p>
                            <div className="doublediv">
                                <div>
                                    <p className="double-ptag">Late Coming</p>
                                    <p className="doubleum-ptag">0</p>
                                </div>
                                <div>
                                    <p className="double-ptag">Early Going</p>
                                    <p className="doubleum-ptag">0</p>
                                </div>
                            </div>
                        </div>
                        <div className="exceptions-pending">
                            <p className="exceptions-pending-heading">Pending Requests</p>
                            <div className="doublediv">
                                <div>
                                    <p className="double-ptag">Regularization Requests</p>
                                    <p className="doubleum-ptag">0</p>
                                </div>
                                <div>
                                    <p className="double-ptag">Leave Requests</p>
                                    <p className="doubleum-ptag">0</p>
                                </div>
                            </div>
                        </div>
                    </Box>
                </div>
            </Box >

        </>
    )
}

export default Dashboard

