import { DataGrid } from '@mui/x-data-grid';
import Header from '../Common/Header/Header';
import { Box, Button, TextField } from '@mui/material';
import { MdAccountBalance, MdOutlineKeyboardArrowRight } from "react-icons/md";
import '../Styles/journeyPlansStyle.css'
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { Link } from 'react-router-dom';


const columns = [
    {
        field: 'checkInLocation',
        headerName: 'Check-In Location',
        width: 120,
    },
    {
        field: 'assignTo',
        headerName: 'Assign To',
        width: 120,
    },
    {
        field: 'planid',
        headerName: 'Plan ID',
        width: 120
    },
    {
        field: 'date',
        headerName: 'Date',
        width: 120,
    },
    {
        field: 'startTime',
        headerName: 'Start Time',
        width: 120
    },
    {
        field: 'endTime',
        headerName: 'End Time',
        width: 120
    },
    {
        field: 'action',
        headerName: 'Action',
        width: 120,
    }
];

const rows = [
    { id: 1, checkInLocation: 'office site 1', assignTo: 'Snow ', planid: 'Jon', date: '08/09/2023', startTime: '05:00', endTime: '16:00', action: '' },
    { id: 2, checkInLocation: 'Noida Sector 64', assignTo: 'Lannister', planid: 'Cersei', date: '08/09/2023', startTime: '05:00', endTime: '16:00', action: '' },
    { id: 3, checkInLocation: 'Noida Sector 64', assignTo: 'Lannister', planid: 'Jaime', date: '08/09/2023', startTime: '05:00', endTime: '16:00', action: '' },
    { id: 4, checkInLocation: 'Noida Sector 64', assignTo: 'Stark', planid: 'Arya', date: '08/09/2023', startTime: '05:00', endTime: '16:00', action: '' },
    { id: 5, checkInLocation: 'Noida Sector 64', assignTo: 'Targaryen', planid: 'Daenerys', date: '08/09/2023', startTime: '05:00', endTime: '16:00', action: '' },
    { id: 6, checkInLocation: 'Noida Sector 64', assignTo: 'Melisandre', planid: null, date: '08/09/2023', startTime: '05:00', endTime: '16:00', action: '' },
    { id: 7, checkInLocation: 'Noida Sector 64', assignTo: 'Clifford', planid: 'Ferrara', date: '08/09/2023', startTime: '05:00', endTime: '16:00', action: '' },
    { id: 8, checkInLocation: 'Noida Sector 64', assignTo: 'Frances', planid: 'Rossini', date: '08/09/2023', startTime: '05:00', endTime: '16:00', action: '' },
    { id: 9, checkInLocation: 'Noida Sector 64', assignTo: 'Roxie', planid: 'Harvey', date: '08/09/2023', startTime: '05:00', endTime: '16:00', action: '' },
];

const JourneyTasks = () => {
    return (
        <>
            <Box className='Shifthome-mainbox'>
                <div className="Shift-rightbox">
                    <Header />
                    <Box className="Shift-second-head">
                        <div className='hamburg'>
                            <div><MdAccountBalance className=" fs-5" /></div>
                            <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                            <div>Journey Task List</div>
                        </div>
                        <div className='btnbox'>
                            <Link to={'/journey-tasks/add-task'}><Button className='btn'>Add Task</Button></Link>
                        </div>
                    </Box>
                    <Box className='filterDiv'>
                        <div className='fielddate'>
                        </div>
                        <div className='searchbox'>
                            <div className='search-inputdiv'>
                                <Paper
                                    component="form"
                                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400, borderRadius: '20px' }}
                                >
                                    <InputBase
                                        size='small'
                                        sx={{ ml: 1, flex: 1 }}
                                        placeholder="Search by ID / Name"
                                        inputProps={{ 'aria-label': 'Search by ID / Name' }}
                                    />
                                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                        <SearchIcon />
                                    </IconButton>
                                </Paper>
                            </div>
                        </div>

                    </Box>
                    <Box className="Shift-box">
                        <div style={{ width: '100%' }}>
                            <DataGrid
                                rows={rows}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: { page: 0, pageSize: 5 },
                                    },
                                }}
                                pageSizeOptions={[5, 10]}
                                checkboxSelection
                            />
                        </div>

                    </Box>
                </div>
            </Box >

        </>
    );
}

export default JourneyTasks;