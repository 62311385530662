import { Outlet } from "react-router-dom";
import DashboardSidebar from "./Sidebar";
import './layoutStyle.css'

const Layout = () => {
    return (
        <>
            <div className="">
                <div className="sidebar">
                    <DashboardSidebar  />
                </div>
                <div>
                    <Outlet />
                </div>
            </div>
        </>
    );
};

export default Layout;
