
import { Box } from "@mui/material"
import Header from "../Common/Header/Header";
import { MdAccountBalance, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { Button } from 'primereact/button';
import { Link } from "react-router-dom";
import '../Styles/payrollAddConfigurationStyle.css'
import { useState } from "react";
import { Dropdown } from "primereact/dropdown";
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';


const PayrollAddConfiguration = () => {
    const [selectedCity, setSelectedCity] = useState(null);
    const cities = [
        { name: 'New York', code: 'NY' },
        { name: 'Rome', code: 'RM' },
        { name: 'London', code: 'LDN' },
        { name: 'Istanbul', code: 'IST' },
        { name: 'Paris', code: 'PRS' }
    ];


    return (
        <>
            <Box className='home-mainbox'>
                <div className="rightbox">
                    <Header />
                    <Box className="createform-head">
                        <div className="createform">
                            <div><MdAccountBalance className=" fs-5" /></div>
                            <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                            <div>Payroll </div>
                            <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                            <div>Configuration Settings </div>
                            <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                            <div>Add Configuration </div>
                        </div>
                        <div className='Formsbtnbox'>
                            <Link to={'/payroll-configuration'}> <Button size="small">Back</Button></Link>
                        </div>
                    </Box>

                    <Box className="full-box">
                        <div className="selectionBox">
                            <div className="addConfigBox">
                                <p className="pt1">Select Company</p>
                                <p className="pt2">Select company on this pay rule has to be applied.</p>
                                <div className="addconfig-selectbox">
                                    <Dropdown
                                        value={selectedCity}
                                        onChange={(e) => setSelectedCity(e.value)}
                                        options={cities}
                                        optionLabel="name"
                                        placeholder=""
                                        className="" />
                                </div>
                            </div>

                            <div className="addConfigBox">
                                <p className="pt1">Pay Cycle Start Day</p>
                                <p className="pt2">Start day of pay period from from salary has to be calculated.</p>
                                <div className="addconfig-selectbox">
                                    <Dropdown
                                        value={selectedCity}
                                        onChange={(e) => setSelectedCity(e.value)}
                                        options={cities}
                                        optionLabel="name"
                                        placeholder=""
                                        className="" />
                                </div>
                            </div>
                            <div className="addConfigBox">
                                <p className="pt1">Pay Cycle End Day</p>
                                <p className="pt2">End day of pay period till salary has to be calculated.</p>
                                <div className="addconfig-selectbox">
                                    <Dropdown
                                        value={selectedCity}
                                        onChange={(e) => setSelectedCity(e.value)}
                                        options={cities}
                                        optionLabel="name"
                                        placeholder=""
                                        className="" />
                                </div>
                            </div>
                            <div className="addConfigBox">
                                <p className="pt1">Pay Slip Disbursement Day</p>
                                <p className="pt2">The day that employees receive their pay slip. Select the day on which pay slip has to be disbursed after salary has been disbursed.</p>
                                <div className="addconfig-selectbox">
                                    <Dropdown
                                        value={selectedCity}
                                        onChange={(e) => setSelectedCity(e.value)}
                                        options={cities}
                                        optionLabel="name"
                                        placeholder=""
                                        className="" />
                                </div>
                            </div>
                            <div className="addConfigBox">
                                <p className="pt1">OT Hours To Consider A Day</p>
                                <p className="pt2">Minimum Hours to Consider a Payable day for overtime.</p>
                                <div className="addconfig-selectbox">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer
                                            components={[
                                                'MobileTimePicker',
                                            ]}
                                        >
                                            <DemoItem>
                                                <MobileTimePicker defaultValue={dayjs('2022-04-17T00:00')} />
                                            </DemoItem>
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </div>
                            </div>
                            <div className="addConfigBox">
                                <p className="pt1">Varied salary benefits as per employee category</p>
                                <p className="pt2">Employees will be divided in different categories and bonus/some salary benefits will be given as per the category.</p>
                                <div className="addconfig-selectbox">
                                    <Dropdown
                                        value={selectedCity}
                                        onChange={(e) => setSelectedCity(e.value)}
                                        options={cities}
                                        optionLabel="name"
                                        placeholder=""
                                        className="" />
                                </div>
                            </div>

                        </div>

                        <div className="buttons">
                            <Button className="button2" size="small" label="Cancel" outlined />
                            <Button className="button1" size="small" label="Save " severity="info" />
                        </div>

                    </Box>
                </div>
            </Box >

        </>
    )
}

export default PayrollAddConfiguration

