
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button } from '@mui/material';
import './recognitionEmployeeImportDataModelStyle.css'
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { MdClose } from "react-icons/md";
import { FaDownload } from "react-icons/fa";

const rows = [
    { id: 1, name: 'Sam', company: 'ABCD' },
    { id: 2, name: 'Riya', company: 'dell' },

];
const MasterEmployeeDataModel = ({ openData, DatahandleClose }) => {

    const openFileInput = () => {
        const fileInput = document.getElementById('fileInput');
        fileInput.click();
    };

    const handleExportExcel = () => {
        // Convert table data to Excel format
        const worksheet = XLSX.utils.json_to_sheet(rows);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        // Generate buffer and create Blob
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Trigger download
        saveAs(blob, 'table_data.xlsx');
    };
    return (
        <div>
            <Modal
                open={openData}
                onClose={DatahandleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className='modalboxx'>
                    <b onClick={DatahandleClose}><MdClose className=' fs-4' /></b>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Import Data
                    </Typography>
                    <div className='divfilebox'>
                        <input
                            type="file"
                            id="fileInput"
                            style={{ display: 'none' }}
                        />
                        <p className='fileptg' onClick={openFileInput}><FaDownload className=' fs-3' style={{color:'gray'}}/></p>
                        <p className=''>Choose the file to be imported</p>
                        <p className='lightgreytag'>[only xls,xlsx and csv formats are supported] Maximum upload file size is 5 MB.</p>
                        <p className='fileptg' onClick={handleExportExcel}>Download Sample File</p>
                    </div>
                    <div className='filebtnbox'>
                        <Button variant='contained'>upload File</Button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}
export default MasterEmployeeDataModel