import { Box, Button, TextField } from "@mui/material"
import Header from "../Common/Header/Header";
import { MdOutlineKeyboardArrowRight, MdOutlinePersonOutline, MdOutlineEdit } from "react-icons/md";
import { Tab, TabPanel, Tabs, TabsList } from "@mui/base";
import imgProfile from '../Assets/profile.png'
import '../Styles/homeprofileProfileStyle.css'
import { useState } from "react";



const HomeprofileProfile = () => {

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Box className='home-mainbox'>
                <div className="rightbox">
                    <Header />
                    <Box className="second-head">
                        <div><MdOutlinePersonOutline className=" fs-5" /></div>
                        <div><MdOutlineKeyboardArrowRight className=" fs-5"/></div>
                        <div>My Profile</div>
                    </Box>

                    <Box>
                        <Tabs value={value} onChange={handleChange}>
                            <TabsList className="tabslist-div">
                                <Tab className="mange-tab" style={value === 0 ? { borderBottom: '2px solid black',color:'black' } : {}} value={0}>Profile Details </Tab>
                                <Tab className="mange-tab" style={value === 1 ? { borderBottom: '2px solid black',color:'black' } : {}} value={1}>Change Password </Tab>
                            </TabsList>
                            <TabPanel value={0}>
                                <div className="tabpanel0-div">
                                    <div className="profiletop-div">
                                        <div>
                                            <img src={imgProfile} alt="img" />
                                            <p>Mohd Bilal</p>
                                        </div>
                                        <div className="iconbox"><MdOutlineEdit /></div>
                                    </div>
                                    <div className="panelprofile-detailbox">
                                        <div>
                                            <p className="heading-p">User Type</p>
                                            <p>Account</p>
                                        </div>
                                        <div>
                                            <p className="heading-p">Email</p>
                                            <p>bilal@kent.com</p>
                                        </div>
                                        <div>
                                            <p className="heading-p">Phone Number</p>
                                            <p>+91 9987471223</p>
                                        </div>
                                        <div>
                                            <p className="heading-p">Is Admin</p>
                                            <p>Yes</p>
                                        </div>
                                        <div>
                                            <p className="heading-p">Account</p>
                                            <p>2209100344</p>
                                        </div>
                                    </div>
                                    <div className="btndiv-panel">
                                        <Button variant="contained">Back</Button>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel value={1}>
                                <Box className='passwordbox'>
                                    <div className="tagline">Update Password</div>
                                    <div>
                                        <TextField fullWidth type="password" placeholder="Old Password" />
                                    </div>
                                    <div>
                                        <TextField fullWidth type="password" placeholder="New Password" />
                                    </div>
                                    <div>
                                        <TextField fullWidth type="password" placeholder="Confirm Password" />
                                    </div>
                                    <div>
                                        <Button variant="contained">Update Password</Button>
                                    </div>
                                </Box>
                            </TabPanel>
                        </Tabs>
                    </Box>

                </div>
            </Box >

        </>
    )
}

export default HomeprofileProfile

