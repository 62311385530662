import { Box } from "@mui/material"
import DashboardSidebar from "../Components/Sidebar"
import Header from "../Common/Header/Header"
import { MdAccountBalance, MdOutlineKeyboardArrowRight } from "react-icons/md";
import '../Styles/leaveTransactionsStyle.css'
import { useState } from "react";
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';
import 'primereact/resources/themes/lara-light-cyan/theme.css';
import { Dropdown } from "primereact/dropdown";

const LeaveTransactions = () => {
  const [selectedCities, setSelectedCities] = useState(null);
  const [date, setDate] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  const cities = [
    { name: 'New York', code: 'NY' },
    { name: 'Rome', code: 'RM' },
    { name: 'London', code: 'LDN' },
    { name: 'Istanbul', code: 'IST' },
    { name: 'Paris', code: 'PRS' }
  ];
  return (
    <>
      <Box className='Shifthome-mainbox'>
        {/* <div className="Shift-sidebarbox">
          <DashboardSidebar />
        </div> */}

        <div className="Shift-rightbox">
          <Header />
          <Box className="Shift-second-head">
            <div className='hamburg'>
              <div><MdAccountBalance className=" fs-5" /></div>
              <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
              <div>Leaves</div>
              <div><MdOutlineKeyboardArrowRight /></div>
              <div>Leave Transactions</div>
            </div>
          </Box>

          <Box className="full-box">
            <div className="selectionBox">
              <div className="calenderbox">
                <div className="flex-auto">
                  <Calendar
                    id="buttondisplay"
                    value={date}
                    onChange={(e) => setDate(e.value)}
                    filter placeholder="From Date"
                    showIcon />
                </div>
                <div className="flex-auto">
                  <Calendar
                    value={date}
                    onChange={(e) => setDate(e.value)}
                    filter placeholder="To Date"
                    showIcon />
                </div>
              </div>
              <div className="card">
                <MultiSelect
                  value={selectedCities}
                  onChange={(e) => setSelectedCities(e.value)}
                  options={cities}
                  optionLabel="name"
                  filter placeholder="Leave Type"
                  maxSelectedLabels={3}
                  className="" />
              </div>
              <div className="card">
                <MultiSelect
                  value={selectedCities}
                  onChange={(e) => setSelectedCities(e.value)}
                  options={cities}
                  optionLabel="name"
                  filter placeholder="Transaction Type"
                  maxSelectedLabels={3}
                  className="" />
              </div>
              <div className="card">
                <Dropdown
                  value={selectedCity}
                  onChange={(e) => setSelectedCity(e.value)}
                  options={cities}
                  optionLabel="name"
                  editable placeholder="Search Employee"
                  className="" />
              </div>


            </div>

            <div className="buttons">
              <Button className="button1" size="small" label="View Transaction" severity="info" />
              <Button className="button2" size="small" label="Cancel" severity="info" outlined />
            </div>

          </Box>

        </div>
      </Box >

    </>
  )
}

export default LeaveTransactions





