
import { Box } from "@mui/material"
import Header from "../Common/Header/Header";
import { MdOutlineKeyboardArrowRight, MdAccountBalance } from "react-icons/md";
import '../Styles/settingAddUserStyle.css'
import { InputText } from "primereact/inputtext";
import { Dropdown } from 'primereact/dropdown';
import { useState } from "react";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import { MultiSelect } from "primereact/multiselect";
import { Checkbox } from "primereact/checkbox";
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';


const CloudIntegrationCreateScheduledJobs = () => {
    const [selectedCity, setSelectedCity] = useState(null);
    const [ingredient, setIngredient] = useState('');

    const cities = [
        { name: 'Employee ID', code: 'NY' },
        { name: 'Employee Name', code: 'RM' },
    ];

    const openFileInput = () => {
        const fileInput = document.getElementById('fileInput');
        fileInput.click();
    };
    return (
        <>
            <Box className='home-mainbox'>
                <div className="rightbox">
                    <Header />
                    <Box className="second-head">
                        <div><MdAccountBalance className=" fs-5" /></div>
                        <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                        <div>Cloud Integration </div>
                        <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                        <div>Scheduled Jobs</div>
                        <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                        <div>Add Scheduled Job</div>

                    </Box>

                    <Box className="full-box">
                        <p className="heading-p">Create Scheduled Job</p>

                        <div className="selectionBox">

                            <div className="card ">
                                <InputText placeholder="Scheduled Job Name" />
                            </div>
                            <div className="card">
                                <Dropdown
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    placeholder="Frequency"
                                    className="" />
                            </div>
                            <div className="card">
                                <div className="cardflexauto">
                                    <label htmlFor="buttondisplay" className="font-bold block">
                                        Recognition Time
                                    </label>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer
                                            components={[
                                                'MobileTimePicker',
                                            ]}

                                        >
                                            <DemoItem>
                                                <MobileTimePicker defaultValue={dayjs('2022-04-17T15:30')} />
                                            </DemoItem>
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </div>
                            </div>
                            <div className="card">
                                <Dropdown
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    placeholder="Report Name"
                                    className="" />
                            </div>
                            <div className="card">
                                <MultiSelect
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    filter placeholder="Filter"
                                    maxSelectedLabels={3}
                                    className="" />
                            </div>
                            <div className="card">
                                <div className="d-flex flex-wrap gap-3 align-items-center">
                                    <label>Send Report on Email : </label>
                                    <div className="d-flex align-items-center">
                                        <RadioButton
                                            inputId="ingredient1"
                                            name="pizza" value="Yes"
                                            onChange={(e) => setIngredient(e.value)}
                                            checked={ingredient === 'Yes'}
                                        />
                                        <label htmlFor="ingredient1" className="ms-2">Yes</label>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <RadioButton
                                            inputId="ingredient2"
                                            name="pizza" value="No"
                                            onChange={(e) => setIngredient(e.value)}
                                            checked={ingredient === 'No'}
                                        />
                                        <label htmlFor="ingredient2" className="ms-2">No</label>
                                    </div>
                                </div>
                            </div>


                            <div className="card ">
                                <InputText placeholder="Email ID" />
                            </div>
                            <div className=" card">
                                <div className=" d-flex align-items-center">
                                    <Checkbox inputId="ingredient2" name="pizza" value="Push Report on FTP/SFTP Server" />
                                    <label htmlFor="ingredient2" className="ms-2">Push Report on FTP/SFTP Server</label>
                                </div>
                            </div>
                        </div>

                        <div className="buttons">
                            <Button className="button1" size="small" label="Submit" severity="info" />
                            <Button className="button2" size="small" label="Cancel" severity="info" outlined />
                        </div>

                    </Box>
                </div>
            </Box >

        </>
    )
}

export default CloudIntegrationCreateScheduledJobs

