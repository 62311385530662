import { DataGrid } from '@mui/x-data-grid';
import Header from '../Common/Header/Header';
import { Box, Button } from '@mui/material';
import { MdAccountBalance, MdOutlineKeyboardArrowRight, MdClose } from "react-icons/md";
import filterImg from '../Assets/filter.png'
import '../Styles/settingsUsersStyle.css'
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { Tab, TabPanel, Tabs, TabsList } from "@mui/base";
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { MultiSelect } from 'primereact/multiselect';



const columns = [
    {
        field: 'CategoryID',
        headerName: 'Category ID',
        width: 160,
    },
    {
        field: 'Category',
        headerName: 'Category',
        width: 160,
    },
    {
        field: 'NoOfLocations',
        headerName: 'No. Of Locations',
        width: 160
    },
    {
        field: 'Addonthefly',
        headerName: 'Add on-the-fly',
        width: 160
    },
    {
        field: 'actions',
        headerName: 'Actions',
        width: 160,
    }
];

const rows = [
    { id: 1, name: 'Snow ', updateOn: 'Jon' },
    { id: 2, name: 'Lannister', updateOn: 'Cersei' },
    { id: 3, name: 'Lannister', updateOn: 'Jaime' },
    { id: 4, name: 'Stark', updateOn: 'Arya' },
    { id: 5, name: 'Targaryen', updateOn: 'Daenerys' },
    { id: 6, name: 'Melisandre', updateOn: null },
    { id: 7, name: 'Clifford', updateOn: 'Ferrara' },
    { id: 8, name: 'Frances', updateOn: 'Rossini' },
    { id: 9, name: 'Roxie', updateOn: 'Harvey' },
];

const MobileAppColumn = [
    {
        field: 'Category',
        headerName: 'Category',
        width: 160,
    },
    {
        field: 'LocationName',
        headerName: 'Location Name',
        width: 160,
    },
    {
        field: 'AccessLevel',
        headerName: 'Access Level',
        width: 160
    },
    {
        field: 'CreatedBy',
        headerName: 'Created By',
        width: 160
    },
    {
        field: 'CreatedOn',
        headerName: 'Created On',
        width: 160
    },
    {
        field: 'City',
        headerName: 'City',
        width: 160,
    },
    {
        field: 'State',
        headerName: 'State',
        width: 160,
    },
    {
        field: 'Country',
        headerName: 'Country',
        width: 160,
    },
    {
        field: 'actions',
        headerName: 'Actions',
        width: 160,
    }
];

const MobileAppRows = [
    { id: 1, empName: 'Ankur Goel ', department: 'Jon' },
    { id: 2, empName: 'Ankur Goel', department: 'Cersei' },
    { id: 3, empName: 'Ankur Goel', department: 'Jaime' },
    { id: 4, empName: 'Stark', department: 'Arya' },
    { id: 5, empName: 'Targaryen', department: 'Daenerys' },
    { id: 6, empName: 'Melisandre', department: null },
    { id: 7, empName: 'Clifford', department: 'Ferrara' },
    { id: 8, empName: 'Frances', department: 'Rossini' },
    { id: 9, empName: 'Roxie', department: 'Harvey' },
];

const MasterDataCheckInLocations = () => {
    const [value, setValue] = useState(0);
    const [openFilter, setOpenFilter] = useState(false);
    const [selectedCities, setSelectedCities] = useState(null);
    const [numValue, setNumValue] = useState(0);


    const cities = [
        { name: 'New York', code: 'NY' },
        { name: 'Rome', code: 'RM' },
        { name: 'London', code: 'LDN' },
        { name: 'Istanbul', code: 'IST' },
        { name: 'Paris', code: 'PRS' }
    ];

    const onFilterClick = () => {
        setOpenFilter(!openFilter)
    }

    const onFilterHandleclose = () => {
        setOpenFilter(false)

    };
    const handleChange = (event, newValue) => {
        setNumValue(newValue);
    };

    const onHandleChange = (event, newValue) => {
        setValue(newValue)
    }



    return (
        <>
            <Box className='Shifthome-mainbox'>
                <div className="Shift-rightbox">
                    <Header />
                    <Box className="Shift-second-head">
                        <div className='hamburg'>
                            <div><MdAccountBalance className=" fs-5" /></div>
                            <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                            <div>Master Data</div>
                            <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                            <div>Check-in Locations</div>
                            <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                            <div>{value ? "Locations" : "Location Categories"}</div>
                        


                        </div>
                        <div className='btnbox'>
                            {value ?
                               <Link to={'/master-data-checkin-locations/addCheckinLocation'}> <Button>Add New</Button></Link>
                                :
                                <>
                                    <Link to={'/master-data-checkin-locations/addLocationCategory'}> <Button>Add New</Button></Link>
                                </>
                            }

                        </div>
                    </Box>

                    <Box>
                        <Tabs value={value} onChange={onHandleChange}>
                            <TabsList className="tabslist-div">
                                <Tab className="mange-tab" style={value == 0 ? { borderBottom: '2px solid black', color: 'black' } : {}} value={0}>Location Categories </Tab>
                                <Tab className="mange-tab" style={value == 1 ? { borderBottom: '2px solid black', color: 'black' } : {}} value={1}>Locations </Tab>
                            </TabsList>

                            {value ? <Box className='filterDiv'>
                                <div className='fielddate'>
                                </div>
                                <div className='searchbox'>
                                    <div className='search-inputdiv'>
                                        <Paper
                                            component="form"
                                            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400, borderRadius: '20px' }}
                                        >
                                            <InputBase
                                                size='small'
                                                sx={{ ml: 1, flex: 1 }}
                                                placeholder="Search by Location Name / City"
                                                inputProps={{ 'aria-label': 'Search by Location Name / City' }}
                                            />
                                            <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                                <SearchIcon />
                                            </IconButton>
                                        </Paper>
                                    </div>
                                </div>

                            </Box>
                                :
                                <Box className='filterDiv'>
                                    <div className='fielddate'>
                                    </div>
                                    <div className='searchbox'>
                                        <div className='search-inputdiv'>
                                            <Paper
                                                component="form"
                                                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400, borderRadius: '20px' }}
                                            >
                                                <InputBase
                                                    size='small'
                                                    sx={{ ml: 1, flex: 1 }}
                                                    placeholder="Search by Category ID / Name"
                                                    inputProps={{ 'aria-label': 'Search by Category ID / Name' }}
                                                />
                                                <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                                    <SearchIcon />
                                                </IconButton>
                                            </Paper>
                                        </div>
                                    </div>

                                </Box>}


                            <TabPanel value={0}>
                                <div className="tabpanel0-div">
                                    <div style={{ width: '100%' }}>
                                        <DataGrid
                                            rows={rows}
                                            columns={columns}
                                            initialState={{
                                                pagination: {
                                                    paginationModel: { page: 0, pageSize: 5 },
                                                },
                                            }}
                                            pageSizeOptions={[5, 10]}
                                            checkboxSelection
                                        />
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel value={1}>
                                <Box className='tabpanel0-div'>
                                    <div style={{ width: '100%' }}>
                                        <DataGrid
                                            rows={MobileAppRows}
                                            columns={MobileAppColumn}
                                            initialState={{
                                                pagination: {
                                                    paginationModel: { page: 0, pageSize: 5 },
                                                },
                                            }}
                                            pageSizeOptions={[5, 10]}
                                            checkboxSelection
                                        />
                                    </div>
                                </Box>
                            </TabPanel>
                        </Tabs>
                    </Box>

                </div>
            </Box >

        </>
    );
}

export default MasterDataCheckInLocations;