
import { Box } from "@mui/material"
import Header from "../Common/Header/Header";
import { MdOutlineKeyboardArrowRight, MdAccountBalance } from "react-icons/md";
import '../Styles/masterDataAddEmployeesStyle.css'
import { InputText } from "primereact/inputtext";
import { Dropdown } from 'primereact/dropdown';
import { useState } from "react";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import imgLogo from '../Assets/profile.png'
import { IoAddCircle } from "react-icons/io5";
import { Calendar } from "primereact/calendar";
import { MultiSelect } from "primereact/multiselect";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';


const MasterDataAddEmployees = () => {
    const [selectedCity, setSelectedCity] = useState(null);
    const [ingredient, setIngredient] = useState('');
    const [date, setDate] = useState(null);


    const cities = [
        { name: 'Employee ID', code: 'NY' },
        { name: 'Employee Name', code: 'RM' },
    ];

    const openFileInput = () => {
        const fileInput = document.getElementById('fileInput');
        fileInput.click();
    };
    return (
        <>
            <Box className='home-mainbox'>
                <div className="rightbox">
                    <Header />
                    <Box className="second-head">
                        <div><MdAccountBalance className=" fs-5" /></div>
                        <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                        <div>Master Data </div>
                        <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                        <div>Employees</div>
                        <div><MdOutlineKeyboardArrowRight className=" fs-5" /></div>
                        <div>Add New</div>
                    </Box>

                    <Box className="full-box">
                        <div className="selectionBox">

                            <div className='card'>
                                <input
                                    type="file"
                                    id="fileInput"
                                    style={{ display: 'none' }}
                                />
                                <p className="iconplus"><IoAddCircle className=" fs-4 text-primary" /></p>
                                <img style={{ width: "80px", borderRadius: '50%', cursor: 'pointer' }} src={imgLogo} onClick={openFileInput} />

                            </div>
                            <div className='card'></div>

                            <div className="cardwith2 ">
                                <InputText placeholder="Employee ID" />
                            </div>
                            <div className="cardwith2">
                                <Dropdown
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    placeholder="License Type"
                                    className="" />
                            </div>
                            <div className="cardwith2 ">
                                <InputText placeholder="First Name" />
                            </div>
                            <div className="cardwith2 ">
                                <InputText placeholder="Last Name" />
                            </div>
                            <div className="cardwith2 ">
                                <InputText placeholder="Email" />
                            </div>
                            <div className="cardwith2 ">
                                <InputText placeholder="Phone Number" />
                            </div>

                            <div className='card'></div>
                            <p className="heading-p my-2">Organization Information</p>
                            <div className='card'></div>


                            {/* <div className="cardwith2">
                                <Dropdown
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    placeholder="User Type"
                                    className="" />
                            </div> */}
                            <div className="card">
                                <Dropdown
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    editable placeholder="Location"
                                    className="" />
                            </div>
                            <div className="card">
                                <Dropdown
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    editable placeholder="Company"
                                    className="" />
                            </div>
                            <div className="card">
                                <Dropdown
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    editable placeholder="Department"
                                    className="" />
                            </div>
                            <div className="card">
                                <Dropdown
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    editable placeholder="Designation"
                                    className="" />
                            </div>
                            <div className="card">
                                <Dropdown
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    editable placeholder="Supervisor"
                                    className="" />
                            </div>
                            <div className="card">
                                <div className="DateDiv">
                                    <Calendar
                                        id="buttondisplay"
                                        value={date}
                                        onChange={(e) => setDate(e.value)}
                                        filter placeholder="Date Of Joining"
                                        showIcon />
                                </div>
                            </div>

                            <div className="card">
                                <MultiSelect
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    filter placeholder="Tags"
                                    maxSelectedLabels={3}
                                    className="" />
                            </div>

                            <div className='card'></div>
                            <p className="heading-p my-2">Statutory Details</p>
                            <div className='card'></div>

                            <div className=" d-flex">
                                <div className="cardwith2 ">
                                    <InputText placeholder="Aadhar Number" />
                                </div>
                                <div className="cardwith2 ">
                                    <InputText placeholder="EPF" />
                                </div>
                                <div className="cardwith2 ">
                                    <InputText placeholder="ESIC" />
                                </div>
                            </div>

                            <div className="card"></div>
                            <div className="card"></div>
                            <p className="heading-p my-2">Attendance Settings</p>
                            <div className="card"></div>

                            <div className="card">
                                <MultiSelect
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    filter placeholder="Select Device"
                                    maxSelectedLabels={3}
                                    className="" />
                            </div>
                            <div className="card">
                                <Dropdown
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    editable placeholder="Shift Plan"
                                    className="" />
                            </div>
                            <div className="card">
                                <Dropdown
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    editable placeholder="Holiday Calendar"
                                    className="" />
                            </div>
                            <div className="card">
                                <Dropdown
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    editable placeholder="Mobile Policy"
                                    className="" />
                            </div>
                            <div className="card Mandatorydiv">
                                <p>OT Applicable</p>
                                <div>
                                    <FormGroup>
                                        <FormControlLabel control={<Switch />} label="" />
                                    </FormGroup>
                                </div>
                            </div>

                            <div className="card"></div>
                            <p className="heading-p my-2">Other Information</p>
                            <div className="card"></div>
                            <div className="card">
                                <Dropdown
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    editable placeholder="Gender"
                                    className="" />
                            </div>
                            <div className="card">
                                <div className="DateDiv">
                                    <Calendar
                                        id="buttondisplay"
                                        value={date}
                                        onChange={(e) => setDate(e.value)}
                                        filter placeholder="Birthday"
                                        showIcon />
                                </div>
                            </div>
                            <div className="card">
                                <Dropdown
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    editable placeholder="Marital Status"
                                    className="" />
                            </div>
                            <div className="card">
                                <div className="DateDiv">
                                    <Calendar
                                        id="buttondisplay"
                                        value={date}
                                        onChange={(e) => setDate(e.value)}
                                        filter placeholder="Anniversary"
                                        showIcon />
                                </div>
                            </div>

                        </div>

                        <div className="buttons">
                            <Button className="button1" size="small" label="Save & Next" severity="info" />
                            <Button className="button2" size="small" label="Cancel" severity="info" outlined />
                        </div>

                    </Box>
                </div>
            </Box >

        </>
    )
}

export default MasterDataAddEmployees

