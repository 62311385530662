
import { DataGrid } from '@mui/x-data-grid';
import Header from '../Common/Header/Header';
import { Box, Button } from '@mui/material';
import { MdAccountBalance, MdOutlineKeyboardArrowRight } from "react-icons/md";
import '../Styles/journeyPlansStyle.css'
import { Link } from 'react-router-dom';


const columns = [
   
    {
        field: 'formName',
        headerName: 'Form Name',
        width: 170
    },
    {
        field: 'mandatoryField',
        headerName: 'Mandatory Field',
        width: 170,
    },
    {
        field: 'createdBy',
        headerName: 'Created By',
        width: 170
    },
    {
        field: 'createdOn',
        headerName: 'Created On',
        width: 170
    },
    {
        field: 'action',
        headerName: 'Action',
        width: 170,
    }
];

const rows = [
    { id: 1, name: 'Snow ', updateOn: 'Jon' },
    { id: 2, name: 'Lannister', updateOn: 'Cersei' },
    { id: 3, name: 'Lannister', updateOn: 'Jaime' },
    { id: 4, name: 'Stark', updateOn: 'Arya' },
    { id: 5, name: 'Targaryen', updateOn: 'Daenerys' },
    { id: 6, name: 'Melisandre', updateOn: null },
    { id: 7, name: 'Clifford', updateOn: 'Ferrara' },
    { id: 8, name: 'Frances', updateOn: 'Rossini' },
    { id: 9, name: 'Roxie', updateOn: 'Harvey' },
];

const MasterDataRecognitionsForms = () => {
    return (
        <>
            <Box className='Shifthome-mainbox'>
                <div className="Shift-rightbox">
                    <Header />
                    <Box className="Shift-second-head">
                        <div className='hamburg'>
                            <div><MdAccountBalance className=" fs-5" /></div>
                            <div><MdOutlineKeyboardArrowRight className=" fs-5"/></div>
                            <div>List Forms</div>
                        </div>
                        <div className='btnbox'>
                            <Link to={'/master-data-recognitions-forms/create-form'}><Button className='btn'>Create Form</Button></Link>
                        </div>
                    </Box>
                   
                    <Box className="Shift-box">
                        <div style={{ width: '100%' }}>
                            <DataGrid
                                rows={rows}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: { page: 0, pageSize: 5 },
                                    },
                                }}
                                pageSizeOptions={[5, 10]}
                                checkboxSelection
                            />
                        </div>

                    </Box>
                </div>
            </Box >

        </>
    );
}

export default MasterDataRecognitionsForms;