import { DataGrid } from '@mui/x-data-grid';
import Header from '../Common/Header/Header';
import { Box, Button, TextField } from '@mui/material';
import { MdAccountBalance, MdOutlineKeyboardArrowRight } from "react-icons/md";
import '../Styles/RecognitionsUnknowns.css'
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

const columns = [
    {
        field: 'image',
        headerName: 'Image',
        width: 120
    },
    {
        field: 'name',
        headerName: 'Name',
        width: 120,
    },
    {
        field: 'date',
        headerName: 'Date',
        width: 120,
    },
    {
        field: 'time',
        headerName: 'Time',
        width: 120,
    },
    {
        field: 'company',
        headerName: 'Company',
        width: 120
    },
    {
        field: 'phoneNumber',
        headerName: 'Phone Number',
        width: 120
    },
    {
        field: 'meetingPurpose',
        headerName: 'Meeting Purpose',
        width: 120,
    }, {
        field: 'contactPerson',
        headerName: 'Contact Person',
        width: 120,
    }, {
        field: 'remarks',
        headerName: 'Remarks',
        width: 120,
    },
    {
        field: 'actions',
        headerName: 'Actions',
        width: 120,
    }
];

const rows = [
    { id: 1, name: 'Snow ', company: 'Jon',phoneNumber:1234567 },
    { id: 2, name: 'Lannister', company: 'Cersei',phoneNumber:1234567 },
    { id: 3, name: 'Lannister', company: 'Jaime',phoneNumber:1234567 },
    { id: 4, name: 'Stark', company: 'Arya',phoneNumber:1234567 },
    { id: 5, name: 'Targaryen', company: 'Daenerys',phoneNumber:1234567 },
    { id: 6, name: 'Melisandre', company: null,phoneNumber:1234567 },
    { id: 7, name: 'Clifford', company: 'Ferrara',phoneNumber:1234567 },
    { id: 8, name: 'Frances', company: 'Rossini',phoneNumber:1234567 },
    { id: 9, name: 'Roxie', company: 'Harvey',phoneNumber:1234567 },
];

const RecognitionsVisitors = () => {

    const handleExportExcel = () => {
        // Convert table data to Excel format
        const worksheet = XLSX.utils.json_to_sheet(rows);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    
        // Generate buffer and create Blob
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    
        // Trigger download
        saveAs(blob, 'table_data.xlsx');
    };
    return (
        <>
            <Box className='Shifthome-mainbox'>
                <div className="Shift-rightbox">
                    <Header />
                    <Box className="Shift-second-head">
                    <div className='hamburg'>
                            <div><MdAccountBalance className=" fs-5" /></div>
                            <div><MdOutlineKeyboardArrowRight className=" fs-5"/></div>
                            <div>Recognitions</div>
                            <div><MdOutlineKeyboardArrowRight className=" fs-5"/></div>
                            <div>Visitors</div>
                        </div>
                        <div className='btnbox'>
                            <Button onClick={handleExportExcel}>Export XLS</Button>
                        </div>
                    </Box>
                    <Box className='filterDiv'>
                    <div className='fielddate'>
                            <div>
                                <TextField type='date' size='small' />
                            </div>
                        </div>
                        
                    </Box>
                    <Box className="Shift-box">
                        <div style={{ width: '100%' }}>
                            <DataGrid
                                rows={rows}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: { page: 0, pageSize: 5 },
                                    },
                                }}
                                pageSizeOptions={[5, 10]}
                                checkboxSelection
                            />
                        </div>

                    </Box>
                </div>
            </Box >

        </>
    );
}

export default RecognitionsVisitors;